import React, { useEffect, useState } from "react";
import { VictoryPie, VictoryTooltip, VictoryLegend } from "victory";
import styles from "./styles.module.scss";
import { ReactComponent as EquipamentIconOutline } from "../../../assets/equipamentIconOutline.svg";
import theme from "../../../styles/victoryTheme";
import { useReportContext } from "../../../providers/reportContext";
import { CircularProgress } from "@mui/material";
import {
  responsiveHeightPieChart,
  responsiveWidthPieChart,
  getPositionsPieChart,
  legendPieChartX,
  legendPieChartY,
  fontSizeLabelsPieChart,
  tooltipPosition,
  tooltipPositionY,
} from "../../../util/ResponsiveCharts/responsive";

export default function PieChart({ pieSvg, data, positionLegend, handleSliderChange,startTime, endTime }) {

  const { equipmentConsumptions } = data;
  const { loadingChartPie } = useReportContext();

  const [fontSizeLabelsPie, setFontSizeLabelsPie] = useState(0);
  const [singleSliceVisible, setSingleSliceVisible] = useState(false);
  const [widthSize, setWidthSize] = useState(0);
  const [heightSize, setHeightSize] = useState(0);
  const [positionChart, setPositionChart] = useState("");
  const [legendX, setLegendX] = useState(0);
  const [legendY, setLegendY] = useState(0);
  const [tooltip, setTooltip] = useState(0);
  const [tooltipY, setTooltipY] = useState(0);
  const [hiddenSlices, setHiddenSlices] = useState(new Set());

  const customColorScale = [
    "#0066b2",
    
    "#edab27",
    "#6CB4EE",
    "#34A65F",
    "#90be6d",
  "#43aa8b",
  "#4d908e",
  "#577590",
  "#277da1",
    // Add more colors if needed
  ];


   const [graphicData, setGraphicData] = useState([]);

useEffect(() => {
  console.log('Data:', data);
  console.log('Start time:', startTime);
  console.log('End time:', endTime);
  if(startTime != null && endTime != null) {
    setGraphicData(mountedArrayForPieChart());
  }
}, [data, startTime, endTime]); // Add startTime and endTime to useEffect dependencies
// ...

  useEffect(() => {
    setWidthSize(responsiveWidthPieChart());
    setHeightSize(responsiveHeightPieChart());
    setPositionChart(getPositionsPieChart());
    setLegendX(legendPieChartX());
    setLegendY(legendPieChartY());
    setFontSizeLabelsPie(fontSizeLabelsPieChart()); // Make sure this is a valid value
    setTooltip(tooltipPosition());
    setTooltipY(tooltipPositionY());
  }, []);

  const originalLabelStyle = { fill: "#666", fontSize: fontSizeLabelsPie };

  function CustomLabel(props) {
    const visibleData = getVisibleData();

    if (singleSliceVisible && visibleData.length === 1) {
      const datum = visibleData[0];
      return (
        <VictoryTooltip
          {...props}
          active
          x={tooltip}
          y={tooltipY}
          data={[datum]}
          text={[
            `R$ ${datum.x.toFixed(2)}`,
            `${datum.y.toFixed(2)} kWh`,
            datum.name,
          ]}
          orientation="top"
          pointerLength={0}
          cornerRadius={150}
          flyoutWidth={300}
          flyoutHeight={300}
          flyoutStyle={{ fill: "white", stroke: "transparent" }}
          style={[
            { fill: "#666", fontSize: 40, fontWeight: "bold" },
            { fill: "#666", fontSize: 30 },
            { fill: "#666", fontSize: 20 },
          ]}
        />
      );
    } else {
      return (
        <g>
          <VictoryTooltip
            {...props}
            x={tooltip}
            y={tooltipY}
            orientation="top"
            pointerLength={0}
            cornerRadius={150}
            flyoutWidth={300}
            flyoutHeight={300}
            flyoutStyle={{ fill: "white", stroke: "transparent" }}
            style={[
              { fill: "#666", fontSize: 40, fontWeight: "bold" },
              { fill: "#666", fontSize: 30 },
              { fill: "#666", fontSize: 20 },
            ]}
          />
        </g>
      );
    }
  }

  CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;



 function mountedArrayForPieChart() {
  let pieData = [];

  console.log('Filtered Data:', startTime, endTime);

  if (data && data.equipmentConsumptions) {
    if (startTime != null && endTime != null) {
      // Filter the values array based on the selected date range
      for (const [index, { name, values }] of data.equipmentConsumptions.entries()) {
        const filteredValues = values.filter(
          (value) =>
            new Date(value.timestamp + 3 * 60 * 60 * 1000).getTime() >= new Date(startTime).getTime() &&
            new Date(value.timestamp + 3 * 60 * 60 * 1000).getTime() <= new Date(endTime).getTime()
        );

        console.log(`Filtered Data for ${name}:`, filteredValues);

        // Calculate total consumption and price for the selected date range
        let totalConsumption = 0;
        let totalPrice = 0;
        for (const { consumption, price } of filteredValues) {
          totalConsumption += consumption;
          totalPrice += price;
        }

        if (totalConsumption !== 0) {
          const colorIndex = index % customColorScale.length;
          pieData = pieData.concat({ x: totalPrice, y: totalConsumption, name, color: customColorScale[colorIndex] });
        }
      }
    } else {
      // No date range selected, include all data
      for (const [index, { name, price, consumption }] of data.equipmentConsumptions.entries()) {
        if (consumption !== 0) {
          const colorIndex = index % customColorScale.length;
          pieData = pieData.concat({ x: price, y: consumption, name, color: customColorScale[colorIndex] });
        }
      }
    }

    const otherX = (data.totalEquipmentConsumptionPrice / data.totalConsumption) * ((data.totalSmartMeterConsumption ) - data.totalConsumption);
    const otherY = (data.totalSmartMeterConsumption ) - data.totalConsumption;

    const otherColorIndex = customColorScale.length; // Index for the "Outros" color
  
  
    pieData = pieData.concat({ x: otherX, y: otherY, name: "Outros", color: "#4d908e", });
   
  }

  return pieData;
}







  function handleLegendClick(datum) {
    setHiddenSlices((prevHiddenSlices) => {
      const newHiddenSlices = new Set(prevHiddenSlices);
      if (newHiddenSlices.has(datum.name)) {
        newHiddenSlices.delete(datum.name);
      } else {
        newHiddenSlices.add(datum.name);
      }

      if (newHiddenSlices.size === graphicData.length) {
        setSingleSliceVisible(false);
        return new Set();
      } else {
        const visibleSlicesCount = graphicData.length - newHiddenSlices.size;
        setSingleSliceVisible(visibleSlicesCount === 1);
        return newHiddenSlices;
      }
    });
  }

  function getVisibleData() {
    return graphicData.filter((datum) => !hiddenSlices.has(datum.name));
  }

  function getLegendData() {
    if (singleSliceVisible) {
      const visibleData = getVisibleData();
      if (visibleData.length === 1) {
        return visibleData.map((datum) => ({
          ...datum,
          symbol: {
            type: "circle",
            fill: datum.color,
          },
        }));
      }
    }

    return graphicData.map((datum) => ({
      ...datum,
      symbol: {
        type: hiddenSlices.has(datum.name) ? "square" : "circle",
        fill: datum.color,
      },
    }));
  }

  if (loadingChartPie) {
    return (
      <div className={styles.blurLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Buscando Informações</h2>
          <CircularProgress className={styles.circle} />
        </div>
      </div>
    );
  } else {
    if (data.totalConsumption === 0) {
      return (
        <div className={styles.NotConsumer}>
          <h2>Não encontramos dados de consumo total</h2>
          <EquipamentIconOutline color="#0f85ca" />
        </div>
      );
    } else {
      if (Object.keys(data).length > 0) {
        if (equipmentConsumptions.length > 0) {
          return (
            <svg viewBox={pieSvg ? pieSvg : positionChart}>
              <VictoryPie
                animate={{ easing: "exp" }}
                theme={theme}
                standalone={false}
                width={widthSize}
                height={heightSize}
                data={getVisibleData()}
                style={{
                  data: { fill: ({ datum }) => datum.color },
                  labels: { fontSize: 20, fill: "white" },
                }}
                labels={({ datum }) => [
                  `R$ ${datum.x.toFixed(2)}`,
                  `${datum.y.toFixed(2)} kWh`,
                  datum.name,
                ]}
                labelComponent={<CustomLabel />}
              />

              <VictoryLegend
                standalone={false}
                theme={theme}
                x={positionLegend ? positionLegend : legendX}
                y={legendY}
                itemsPerRow={3}
                gutter={30}
                centerTitle
                data={getLegendData()}
                events={[
                  {
                    target: "labels",
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            target: "data",
                            mutation: (props) => {
                              handleLegendClick(props.datum);
                              return null;
                            },
                          },
                        ];
                      },
                      onMouseEnter: () => {
                        return [
                          {
                            target: "labels",
                            mutation: () => ({
                              style: {
                                ...originalLabelStyle,
                                fontSize: fontSizeLabelsPie + 1,
                                fill: "#0f85ca",
                                cursor: "pointer",
                              },
                            }),
                          },
                        ];
                      },
                      onMouseLeave: () => {
                        return [
                          {
                            target: "labels",
                            mutation: () => ({
                              style: originalLabelStyle,
                            }),
                          },
                        ];
                      },
                    },
                  },
                ]}
                style={{ labels: originalLabelStyle }}
              />
            </svg>
          );
        }
      }
    }
  }

  return (
    <div className={styles.NotConsumer}>
      <h2>Não encontramos dados de consumo total nesta data.</h2>
      <EquipamentIconOutline color="#0f85ca" />
    </div>
  );
}
