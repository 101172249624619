import { useNavigate, useLocation } from "react-router-dom"
import { Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Logo from '../../assets/image/deep-ambiente-logo.svg'

import styles from './styles.module.scss'
import { postRefreshPassword } from "../../services/restApiAuth";
import React, { useState } from "react";

import CustomInput from '../../components/CustomInput'
import { useStateContext } from "../../providers/stateContext";
import CustomButtonLoading from "../../components/Form/buttonLoading";
import CustomButton from "../../components/Form/button";

export default function ResetPassword() {
  const navigate = useNavigate()
  const { setIsLoading } = useStateContext()
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openStatusErrorSnackBar, setStatusErrorOpenSnackBar] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const validationSchema = yup.object({
    password: yup.string(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Senhas não são iguais!'),
  })

  let query = useQuery();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      let getToken = query.get('token')!.replaceAll(' ', '+')

      const mountedRefreshPassword = {
        userId: query.get('uid'),
        token: getToken,
        newPassword: values.password,
        confirmNewPassword: values.confirmPassword
      }
      await postRefreshPassword(mountedRefreshPassword).then((response) => {
        setStatusErrorOpenSnackBar(false)
        handleOpenSnackBar()
        if (response.status === 200) {
          setIsLoading(false)
          navigate('/resetPasswordSuccess')
        }
      }).catch(err => {
        setIsLoading(false)
        setStatusErrorOpenSnackBar(true)
        handleOpenSnackBar()
      })
    },
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })

  return (
    <main className={styles.container}>
      <form onSubmit={formik.handleSubmit} >
        <div className={styles.ContentBox}>
          <div className={styles.header}>
            <div className={styles.contentHeader}>
              <div className={styles.containerImage}>
                <img
                  src={Logo}
                  alt="Logo Deep Ambiente"
                  width={140}
                  height={90}
                />
              </div>
              <h3 className={styles.titleHeader}>Redefinir senha</h3>
              <div />
            </div>
            <div className={styles.dividerHorizontal} />
          </div>
          <div className={styles.Content}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '400px',
              mb: '1rem'
            }}>
              <Typography component={'span'} variant={'body2'} sx={{ mb: '1rem' }}>Nova senha</Typography>
              <CustomInput
                size="small"
                variant="outlined"
                id="password"
                name="password"
                label="Nova senha"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                sx={{ div: { height: '40px' } }}
              />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '400px',
              mb: '1rem'
            }}>
              <Typography component={'span'} variant={'body2'} sx={{ mb: '1rem' }}>Confirmar Senha</Typography>
              <CustomInput
                size="small"
                variant="outlined"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label="Confirmar Senha"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                sx={{ div: { height: '40px' } }}
              />
            </Box>
          </div>
          <div className={styles.dividerHorizontal} />
          <div className={styles.positionButton}>
            <CustomButton title="voltar para login" className="middleOutline" onClick={() => navigate('/')} />
            <CustomButtonLoading type="submit" title="Redefinir" className="middle" />
          </div>
        </div>
      </form>
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity={openStatusErrorSnackBar ? "error" : "success"} sx={{ width: '100%' }}>
          {openStatusErrorSnackBar ? "Ocorreu um erro inesperado, por favor tente novamente mais tarde!" : "Senha redefinida com sucesso!"}
        </Alert>
      </Snackbar>
    </main>
  )
}
