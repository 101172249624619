import { useState } from "react";
import { Typography } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import CustomButton from "../../Form/button";
import { useModalContext } from "../../../providers/modalContext";
import { useStateContext } from "../../../providers/stateContext";
import CustomButtonLoading from "../../Form/buttonLoading";

type Props = {
  id: number | string;
  title?: string;
  functionDesassociated: (id: string | number) => Promise<Boolean>;
}

export function DesassociatedItemModal(Props: Props) {
  const { id, title, functionDesassociated } = Props;
  const [maxWidthExit] = useState<DialogProps["maxWidth"]>("sm");
  const { setModalDesassociated, modalDesassociated } = useModalContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext();


  async function handleDesassociatedItem(itemId: string | number) {
    setIsLoading(true);
    const hasDelete = await functionDesassociated(itemId)
    if (hasDelete) {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(false)
      setIsLoading(false);
      setModalDesassociated(false)
    } else {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(true)
      setIsLoading(false);
      setModalDesassociated(false)
    }
  }

  const handleCloseModalDelete = () => {
    setModalDesassociated(false);
  };

  return (
    <Dialog
      maxWidth={maxWidthExit}
      open={modalDesassociated}
      onClose={handleCloseModalDelete}
      aria-labelledby="responsive-dialog-title"
    >
      <Box
        sx={{
          padding: "1.25rem !important",
          display: "grid",
          maxWidth: "400px",
          minWidth: "370px",
          minHeight: "170px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ fontSize: '1.20rem' }}>
          {title}?
        </Typography>
        <Typography component={"span"} variant={"body2"}>
          Não é possível reverter essa ação.
        </Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "8rem 8rem",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: '1rem',
          }}
        >
          <CustomButton
            type="button"
            title="Cancelar"
            className="smallOutline"
            onClick={() => handleCloseModalDelete()}
          />
          <CustomButtonLoading
            type="button"
            title="Desassociar"
            className="delete"
            onClick={() => handleDesassociatedItem(id)}
          />
        </div>
      </Box>
    </Dialog>
  );
}