import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { Pagination } from "@material-ui/lab";

import { ModalEditMeter } from "../../../Modal/EditMeter";
import { useModalContext } from "../../../../providers/modalContext";
import styles from "./styles.module.scss";
import { usePagination } from "../../../../util/hooks/pagination";
import { DeleteItemModal } from "../../../Modal/DeleteItem";
import { useMeterContext } from "../../../../providers/meterContext";

export function SmartMeterTab({ smartMeter }: any) {
  const { setModalEditMeter, setModalDelete } = useModalContext();
  const { deleteMeter, } = useMeterContext();
  let [page, setPage] = useState(1);
  const [meterEdit, setMeterEdit] = useState();
  const [meterId, setMeterId] = useState(0);
  const [meterName, setMeterName] = useState();

  const PER_PAGE = 8;
  const count = Math.ceil(smartMeter.length / PER_PAGE);
  const _DATA = usePagination(smartMeter, PER_PAGE);

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleEditMeter = (meter: any) => {
    setMeterEdit(meter)
    setModalEditMeter(true);
  };

  const handleDeleteMeter = (meter: any) => {
    setMeterName(meter.name)
    setMeterId(meter.id)
    setModalDelete(true)
  }

  return (
    <main className={styles.mainContainer}>
      <section className={styles.section}>
        <div>
          <div className={styles.itemContainer}>
            <div className={styles.titletable}>
              <div className={styles.contentHeader}>
                <h5>Nome</h5>
              </div>
              <div className={styles.contentHeader}>
                <h5>Identificador</h5>
              </div>
              <div className={styles.contentHeader}>
                <h5>Unidade Consumidora</h5>
              </div>
              <div />
            </div>
            {
              smartMeter &&
              _DATA.currentData().map((meter: any, index: any) => {
                return (
                  <div key={index}>
                    <div className={styles.mainContent}>
                      <div className={styles.contentBox}>
                        <div className={styles.contentText}>
                          <h4 className={styles.idName}>{meter.name}</h4>
                        </div>
                      </div>
                      <div className={styles.contentBox2}>
                        <div className={styles.contentText}>
                          <h4 className={styles.idMeter}>{meter.id}</h4>
                        </div>
                      </div>
                      
                        {
                          meter.consumerUnit !== null ?
                            <div className={styles.contentText}>
                              <h4 className={styles.nameId}>{meter.consumerUnit.placeName}</h4>
                            </div>
                            :
                            <div className={styles.contentText}>
                              <h4 className={styles.notAss}>Não associado</h4>
                            </div>
                        }

                      
                      <div className={styles.contentBox3}>
                        <div className={styles.contentItem}>
                          <button onClick={() => handleEditMeter(meter)}>
                            <EditIcon />
                          </button>
                          <button onClick={() => handleDeleteMeter(meter)}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*  <div className={styles.containerButtons}>
                      <button
                        onClick={() => navigate("/dashboard")}
                      >
                        Ver Monitoramento
                      </button>
                    </div> */}
                    <div className={styles.dividerHorizontal} />
                  </div>
                )
              })
            }
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mb: "1.25rem !important",
              ul: {
                display: "flex",
                flexDirection: "row",
              }
            }}
          >
            {/*<Pagination
              count={count}
              size="large"
              //page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
        />*/}
          </Box>
        </div>

        {/*      <div className={styles.noConsumerUnit}>
            <span>
              Não foi encontrado nenhuma medidor inteligente cadastrado
              {role !== "Cliente"
                ? "você pode cadastrar um novo no botão acima!"
                : ""}
            </span>
          </div>
 */}
      </section>
      {
        meterEdit ? (
          <ModalEditMeter meterEdit={meterEdit} title="QGBT" />
        ) : (
          <div />
        )
      }
      <DeleteItemModal id={meterId} name={meterName} functionDelete={() => deleteMeter(meterId)} />
    </main >
  );
}
