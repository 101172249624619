import { useState } from "react";
import { Typography } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import CustomButton from "../../Form/button";
import { useModalContext } from "../../../providers/modalContext";
import { useStateContext } from "../../../providers/stateContext";
import CustomButtonLoading from "../../Form/buttonLoading";

import styles from './styles.module.scss';

type Props = {
  id: number | string;
  name?: string;
  functionDelete: (id: string | number) => Promise<Boolean>;
}

export function DeleteItemModal(Props: Props) {
  const { id, name, functionDelete } = Props;
  const [maxWidthExit] = useState<DialogProps["maxWidth"]>("sm");
  const { setModalDelete, modalDelete } = useModalContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext();


  async function handleDeleteItem(itemId: string | number) {
    setIsLoading(true);
    const hasDelete = await functionDelete(itemId)
    if (hasDelete) {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(false)
      setIsLoading(false);
      setModalDelete(false)
    } else {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(true)
      setIsLoading(false);
      setModalDelete(false)
    }
  }

  const handleCloseModalDelete = () => {
    setModalDelete(false);
  };

  return (
    <Dialog
      maxWidth={maxWidthExit}
      open={modalDelete}
      onClose={handleCloseModalDelete}
      aria-labelledby="responsive-dialog-title"
    >
      <Box className={styles.excluirUnid}
        sx={{
          padding: "1.25rem !important",
          display: "grid",
          maxWidth: "400px",
          //minWidth: "370px",
          //minHeight: "170px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ fontSize: '1.20rem' }}>
          Deseja excluir {name}?
        </Typography>
        <Typography component={"span"} variant={"body2"}>
          Não é possível reverter essa ação.
        </Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "8rem 8rem",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: '1rem',
          }}
        >
          <CustomButton
            type="button"
            title="Cancelar"
            className="smallOutline"
            onClick={() => handleCloseModalDelete()}
          />
          <CustomButtonLoading
            type="button"
            title="Deletar"
            className="delete"
            onClick={() => handleDeleteItem(id)}
          />
        </div>
      </Box>
    </Dialog>
  );
}