import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"

export default function AuthPages() {
  const navigate = useNavigate()
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    const hasnosign = localStorage.getItem('hasnosign')!==null;
    if (accessToken && !hasnosign) {
      navigate('/dashboard')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Outlet />
  )
}