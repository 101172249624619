import {
  ModelCategory
} from '../util/types'
import api from './api'

//Model rest
export function getAllModel() {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get('/api/EquipmentModel', config)
}

export function getModelById(id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/EquipmentModel/${id}`, config)
}

export function postModel(model: any) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.post('/api/EquipmentModel', model, config)
}

export function putModel(model: any, id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.put(`/api/EquipmentModel/${parseInt(id)}`, model, config)
}

export function delModel(id: number | string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.delete(`/api/EquipmentModel/${id}`, config)
}


// Category rest
export function getAllCategory() {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get('/api/EquipmentModelCategory', config)
}

export function getCategoryById(id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/EquipmentModelCategory/${id}`, config)
}

export function postCategory(category: ModelCategory) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.post('/api/EquipmentModelCategory', category, config)
}

export function putCategory(category: any, id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.put(`/api/EquipmentModelCategory/${parseInt(id)}`, category, config)
}

export function delCategory(id: number | string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.delete(`/api/EquipmentModelCategory/${id}`, config)
}

// BusinessType rest
export function getAllBusinessType() {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get('/api/EquipmentModelBusiness', config)
}

export function getBusinessTypeById(id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/EquipmentModelBusiness/${id}`, config)
}

export function postBusinessType(BusinessType: any) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.post('/api/EquipmentModelBusiness', BusinessType, config)
}

export function putBusinessType(BusinessType: any, id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.put(`/api/EquipmentModelBusiness/${parseInt(id)}`, BusinessType, config)
}

export function delBusinessType(id: number | string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }
  return api.delete(`/api/EquipmentModelBusiness/${id}`, config)
}