import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useStateContext } from "../../providers/stateContext";
import { SmartMeterTab } from "../../components/Tabs/SmartMeterManagementTabs/Smart";
import { IntrusiveMeterTab } from "../../components/Tabs/SmartMeterManagementTabs/Intrusive";
import { useMeterContext } from "../../providers/meterContext";
import { useModalContext } from "../../providers/modalContext";
import { ModalMeterRegistration } from "../../components/Modal/MeterRegistration";
import { CustomSnackBar } from "../../components/Snackbar";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SmartMeterManagement() {
  const { isGetFetching } = useStateContext();
  const { getMeterList, meterList } = useMeterContext()
  const { setModalMeterRegistration } = useModalContext()
  const [value, setValue] = useState(0);
  const [associated, setAssociated] = useState(false);
  const [smart, setSmart] = useState(true);
  const [title, setTitle] = useState("");

  const [selectFilter, setSelectFilter] = useState<string>("all");
  const [filterSmart, setFilterSmart] = useState<any>();
  const [filterIntrusive, setFilterIntrusive] = useState<any>();

  useEffect(() => {
    if (value === 0) {
      setSmart(true)
      setAssociated(false)
      setTitle('QGBT')
      setFilterSmart(meterList.smart)
      setSelectFilter('all')
    } else if (value === 1) {
      setSmart(false)
      setAssociated(false)
      setTitle('Setor/Aparelho')
      setFilterIntrusive(meterList.intrusive)
      setSelectFilter('all')
    }
  }, [value]) // eslint-disable-line

  useEffect(() => {
    getMeterList()
  }, []) // eslint-disable-line

  useEffect(() => {
    filterMeters(selectFilter, value)
  }, [meterList, selectFilter, value])  // eslint-disable-line

  const filterMeters = (type: string, tabValue: any) => {
    let filter
    switch (type) {
      case 'notAssociated':
        if (tabValue === 0) {
          console.log('check meterList notAssociated', meterList)
          filter = meterList.smart.filter(meter => meter.fullAssociated === false)
          setFilterSmart(filter)
        } else {
          filter = meterList.intrusive.filter(meter => meter.fullAssociated === false)
          setFilterIntrusive(filter)
        }
        break
      case 'associated':
        if (tabValue === 0) {
          console.log('check meterList associated', meterList)
          filter = meterList.smart.filter(meter => meter.fullAssociated === true)
          setFilterSmart(filter)
        } else {
          filter = meterList.intrusive.filter(meter => meter.fullAssociated === true)
          setFilterIntrusive(filter)
        }
        break
      default:
        if (tabValue === 0) {
          filter = meterList.smart
          setFilterSmart(filter)
        } else {
          filter = meterList.intrusive
          setFilterIntrusive(filter)
        }
        break
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenMeterRegistration = () => {
    setModalMeterRegistration(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <main className={styles.mainContainer}>
          <header className={styles.header}>
            <div className={styles.topHeader}>
              <h2>Gerenciamento de Medidores</h2>
              <Box className={styles.medidores}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label={
      <div>
        <Typography>
          QGBT
        </Typography>
        <Typography>
          
        </Typography>
      </div>
   } {...a11yProps(0)} />
                  <Tab label={
      <div>
        <Typography>
          Setor/
        </Typography>
        <Typography>
          Aparelho
        </Typography>
      </div>
   } {...a11yProps(1)} />
                </Tabs>
              </Box>
            </div>
          </header>

          <TabPanel value={value} index={0}>
            <div className={styles.searchAndAddContainer}>
              <input
                type="text"
                placeholder="Pesquisar"
                className={styles.Input}
              />
              <button onClick={() => handleOpenMeterRegistration()}>
                Cadastrar QGBT
              </button>
            </div>
            <div className={styles.contentTitle}>
              <h3>Lista de QGBT</h3>
              <FormControl className={styles.filt}>
                <InputLabel>
                  Filtro
                </InputLabel>
                <Select 
                  value={selectFilter}
                  name="filter"
                  labelId="filter"
                  id="filter"
                  label="Filtro"
                  onChange={(e) => setSelectFilter(e.target.value)}
                  className={styles.fixMuiInputBaseRootEditModel}
                >
                  <MenuItem value="all">
                    Todos
                  </MenuItem>
                  <MenuItem value="notAssociated">
                    Disponível
                  </MenuItem>
                  <MenuItem value="associated">
                    Indisponível
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            
            {isGetFetching ? (
              <div className={styles.LoadingScreen}>
                <CircularProgress />
              </div>
            ) :

              filterSmart && (
                <SmartMeterTab smartMeter={filterSmart} associated={associated} />
              )}
              
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className={styles.searchAndAddContainer}>
              <input
                type="text"
                placeholder="Pesquisar"
                className={styles.Input}
              />
              <button onClick={() => handleOpenMeterRegistration()}>
                Cadastrar Medidor <br /> Setor/Aparelho
              </button>
            </div>
            <div className={styles.contentTitle}>
              <h3>Lista de Setor/Aparelho</h3>
              <FormControl>
                <InputLabel id="filter">
                  Filtro
                </InputLabel>
                <Select
                  size="small"
                  value={selectFilter}
                  name="filter"
                  label="Filtro"
                  onChange={(e) => setSelectFilter(e.target.value)}
                  className={styles.fixMuiInputBaseRootEditModel}
                >
                  <MenuItem value="all">
                    Todos
                  </MenuItem>
                  <MenuItem value="notAssociated">
                    Disponíveis
                  </MenuItem>
                  <MenuItem value="associated">
                    Indisponível
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {isGetFetching ? (
              <div className={styles.LoadingScreen}>
                <CircularProgress />
              </div>
            ) :
              filterIntrusive && (
                <IntrusiveMeterTab intrusiveMeter={filterIntrusive} associated={associated} />
              )
            }
          </TabPanel>
        </main>
      </div>
      <ModalMeterRegistration isSmart={smart} title={title} />
      <CustomSnackBar />
    </div >
  )
}