import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import { useFormik } from "formik";
import * as yup from "yup";

import CustomButton from "../../Form/button";

import styles from "./styles.module.scss";
import { useEquipmentContext } from "../../../providers/equipmentContext";
import { useModelContext } from "../../../providers/modelContext";
import { useStateContext } from "../../../providers/stateContext";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import CustomButtonLoading from "../../Form/buttonLoading";

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalEquipmentRegistration() {
  const { modalEquipmentRegistration, setModalEquipmentRegistration } =
    useModalContext();
  const { equipmentPostApi } = useEquipmentContext();
  const { stateModelList, getModelList } = useModelContext();
  const {
    selectConsumerUnitId,
    setIsLoading,
    setOpenSnackBar,
    setStatusErrorOpenSnackBar,
  } = useStateContext();
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);

  useEffect(() => {
    getModelList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = yup.object({
    name: yup.string().required("O nome é obrigatório"),
    description: yup.string(),
    equipmentModelId: yup.string().required("O modelo é obrigatório"),
    consumerUnitId: yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      equipmentModelId: "",
      description: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setIsLoading(true);
      const MountedEquipmentPost = {
        name: values.name,
        equipmentModelId:
          values.equipmentModelId === "" ? null : values.equipmentModelId,
        consumerUnitId: selectConsumerUnitId,
        description: values.description === "" ? null : values.description,
      };

      const equipmentPostResponse = await equipmentPostApi(
        MountedEquipmentPost
      );

      if (equipmentPostResponse) {
        setIsLoading(false);
        setStatusErrorOpenSnackBar(false);
        handleOpenSnackBar();
        setModalEquipmentRegistration(false);
        formik.resetForm();
      } else {
        setIsLoading(false);
        setStatusErrorOpenSnackBar(true);
        handleOpenSnackBar();
      }
    },
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalEquipmentRegistration(false);
      setStateExitModal(false);
      formik.resetForm();
    };

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "800px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleClose()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalEquipmentRegistration}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "-20px",
                  }}
                >
                  Cadastrar
                  <br />
                  Equipamento
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "0px !important",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1.25rem",
                }}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  id="name"
                  name="name"
                  label="Nome"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <CustomInput
                  size="small"
                  variant="outlined"
                  id="description"
                  name="description"
                  label="Descrição"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Box>
              <FormControl
                sx={{
                  width: "100%",
                  maxHeight: "40px !important",
                }}
              >
                <InputLabel
                  id="equipmentModelId"
                  sx={{ lineHeight: "16px !important" }}
                >
                  Modelo
                </InputLabel>
                <CustomSelect
                  size="small"
                  labelId="equipmentModelId"
                  id="equipmentModelId"
                  value={formik.values.equipmentModelId}
                  name="equipmentModelId"
                  label="Modelo"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.equipmentModelId &&
                    Boolean(formik.errors.equipmentModelId)
                  }
                  className={styles.fixMuiInputBaseRootEditModel}
                >
                  {stateModelList &&
                    stateModelList.map((model) => {
                      return (
                        <MenuItem
                          key={`${model.id}-${model.name}`}
                          value={model.id}
                        >
                          {model.name} - {model.brand}
                        </MenuItem>
                      );
                    })}
                </CustomSelect>
                <span className={styles.error}>
                  {formik.touched.equipmentModelId &&
                    formik.errors.equipmentModelId}
                </span>
              </FormControl>

              <Divider />
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <div style={{ marginTop: 20 }}>
                  <CustomButtonLoading
                    type="submit"
                    title="Cadastrar"
                    className="middle"
                  />
                </div>
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
      <ExitModal
        messageTitle="Deseja cancelar o cadastros?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
