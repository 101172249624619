import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import Logo from '../../assets/image/deep-ambiente-logo.svg'
import ResetSuccess from '../../assets/image/sucess-reset.png'
import CustomButton from "../../components/Form/button"
import { CustomSnackBar } from "../../components/Snackbar"
import { useAuth } from "../../providers/authContext"

import styles from './styles.module.scss'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ConfirmarEmailSuccess() {
  const { confirmEmail } = useAuth()
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  let query = useQuery();

  useEffect(() => {
    handleConfirmEmail()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleConfirmEmail = async () => {
    let token = query.get('token')
    let uid = query.get('uid')
    if (uid && token) {
      let hasConfirmEmail = await confirmEmail(uid, token)
      if (hasConfirmEmail) {
        setVisible(true)
      } else {
        setVisible(false)
        navigate('/')
      }
    }
  }

  return (
    <main className={styles.container}>
      {
        visible ? (
          <div className={styles.ContentBox}>
            <div className={styles.header}>
              <div className={styles.contentHeader}>
                <div className={styles.containerImage}>
                  <img
                    src={Logo}
                    alt="Logo Deep Ambiente"
                    width={140}
                    height={90}
                  />
                </div>
                <h3 className={styles.titleHeader}>Confirmação de E-mail</h3>
                <div />
              </div>
            </div>

            <div className={styles.dividerHorizontal} />

            <div className={styles.Content}>
              <img
                src={ResetSuccess}
                alt="Logo Deep Ambiente"
                width={140}
                height={100}
              />
              <div className={styles.textContainer}>
                <h2 className={styles.title}>E-mail confirmado com sucesso!</h2>
              </div>
            </div>
            <div className={styles.dividerHorizontal} />
            <div className={styles.positionButton}>
              <CustomButton type="button" title="ir para login" className="large" onClick={() => navigate('/')} />
            </div>
          </div>
        ) : <div />
      }
      <CustomSnackBar />
    </main >
  )
}
