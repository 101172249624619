import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel, Typography } from "@mui/material";
import TextField from "@material-ui/core/TextField";


import styles from './styles.module.scss';

import { useFormik } from "formik";
import * as yup from "yup";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import Button from "../../Form/button";

import CustomButton from "../../Form/button";
import { useStateContext } from "../../../providers/stateContext";
import CustomButtonLoading from "../../Form/buttonLoading";
import { useAuth } from "../../../providers/authContext";

type propsModal = {
  userEdit: any;
  list: boolean;
};

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalUserADM({ userEdit, list }: propsModal) {
  const { modalUserADM, setModalUserADM } = useModalContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } =
    useStateContext();
  const { EditUser } = useAuth();
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);

  useEffect(() => {
    formik.setFieldValue("fullName", userEdit.fullName, false);
    formik.setFieldValue("userName", userEdit.userName, false);
    formik.setFieldValue("email", userEdit.email, false);
    formik.setFieldValue("phone", userEdit.phone, false);
    formik.setFieldValue("role", userEdit.userRole.name, false);
    formik.setFieldValue("password", "", false);
    formik.setFieldValue("confirmPassword", "", false);
  }, [userEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = yup.object({
    fullName: yup.string(),
    userName: yup.string(),
    email: yup.string().email("Entrar com email valido"),
    password: yup.string(),
    role: yup.string().required("O papel é obrigatório!"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: userEdit.fullName,
      userName: userEdit.userName,
      email: userEdit.email,
      phone: userEdit.phone,
      role: userEdit.userRole.name,
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(false);
      const mountedEditUser = {
        fullName: values.fullName,
        userName: values.userName,
        email: values.email,
        phone: userEdit.phone,
        password: values.password !== "" ? values.password : null,
        role: values.role,
      };

      setIsLoading(true);
      const hasEdit = await EditUser(mountedEditUser, userEdit.id, list);

      if (hasEdit) {
        setOpenSnackBar(true);
        setStatusErrorOpenSnackBar(false);
        setIsLoading(false);
        setModalUserADM(false);
      } else {
        setOpenSnackBar(true);
        setIsLoading(false);
        setStatusErrorOpenSnackBar(true);
      }
      setIsLoading(false);
    },
  });

  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalUserADM(false);
      setStateExitModal(false);
    };

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "0.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleClose()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalUserADM}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "1rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "0rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "-30px",
                  }}
                >
                  Administrador
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box>
                <Box sx={{ mb: "10px" }}>
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    sx={{ mb: "1rem" }}
                  >
                    Dados Pessoais
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="fullName"
                    name="fullName"
                    label="Nome Completo"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                  <TextField
                    variant="outlined"
                    id="userName"
                    name="userName"
                    label="Nome de Usuário"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                  />
                  <TextField
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <FormControl>
                    <InputLabel
                      sx={{ top: "-7px", fontSize: "0.9rem" }}
                      id="role"
                    >
                      Papel
                    </InputLabel>
                    <Select
                      labelId="role"
                      id="role"
                      value={formik.values.role}
                      name="role"
                      label="Papel"
                      onChange={formik.handleChange}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      /* className={styles.fixMuiInputBaseRootEditModel} */
                    >
                      <MenuItem value="" selected disabled>
                        <em>Selecione um papel</em>
                      </MenuItem>
                      <MenuItem value={"Cliente"}>Cliente</MenuItem>
                      <MenuItem value={"Tecnico"}>Técnico</MenuItem>
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                    </Select>
                    {/*  <span className={styles.error}>{formik.touched.tariffType && formik.errors.tariffType}</span> */}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "1rem 0rem",
                  }}
                >
                  <Button
                    type="button"
                    title="Limpar Campos"
                    className="middleOutline"
                    onClick={() => console.log("limpando")}
                  />
                </Box>
                <Divider />
              </Box>
              <Box sx={{ mt: "1rem" }}>
                <Box sx={{ mb: "10px" }}>
                  <Typography component={"span"} variant={"body2"}>
                    Nova senha
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "fr 1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="password"
                    name="password"
                    label="Senha"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <TextField
                    variant="outlined"
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirmar Senha"
                    type="password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                  <Box className={styles.boxButton}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      title="Limpar Campos"
                      className="middleOutline"
                      onClick={() => formik.resetForm()}
                    />
                  </Box>
                </Box>
                <Divider sx={{ mt: "1rem" }} />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <CustomButtonLoading
                  type="submit"
                  title="Confirmar"
                  className="middle"
                />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
      <ExitModal
        messageTitle="Deseja cancelar o cadastro?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
