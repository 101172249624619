import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeIcon from '@mui/icons-material/DateRange';
import styles from "./styles.module.scss";
import useFormatDate from "../../../util/hooks/useFormartDate";
import { useStateContext } from "../../../providers/stateContext";

const DatePickerCalendarIcon = ({ typeChart, handleDateChange }) => {
  const today = new Date(new Date().getTime()- (new Date().getTimezoneOffset() * 60000))
  const [startDate, setStartDate] = useState(today);
  const { setDateToCallApiForChartArea, setDateToCallApiForChartBar, setDateToCallApiForChartPie,} = useStateContext()
  const formatDate = useFormatDate();

  useEffect(() => {
    switch (typeChart) {
      case 'pie':
        setDateToCallApiForChartPie(formatDate.formatTomorrow(startDate.toISOString()));
        return
      case 'area':
        setDateToCallApiForChartArea(formatDate.formatTomorrow(startDate.toISOString()));
        return
        
      case 'bar':
        setDateToCallApiForChartBar(formatDate.formatTomorrow(startDate.toISOString()));
        return
      default:
        return null
    }
  }, [startDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (e) => {
    e.preventDefault();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={styles.containerIcon} onClick={onClick} ref={ref}>
      <DateRangeIcon
        className={styles.circleIcon}
        onClick={handleClick}
      />
    </button>
  ));

  return (
    <div className={styles.containerDatepicker}>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        closeOnScroll={true}
        withPortal
        fixedHeight
        maxDate={new Date()}
        onChange={(date) => {
          setStartDate(date);
          handleDateChange(date);
        }}
        customInput={<ExampleCustomInput />}
        
      />
    </div>
  );
};

const getLastWeek = () => {
  const now = new Date()
  const lastWeek = {
    start: new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000)),
    end: now
  }
  return lastWeek
}

const subDays = (days) => {
  const now = new Date()
  const lastWeek = new Date(now.getTime() - (days * 24 * 60 * 60 * 1000))
  return lastWeek
}

const DatePickerCalendarIcon1 = () => {
  const today1 = new Date(new Date().getTime()- (new Date().getTimezoneOffset() * 60000))
  const yesterday1 = new Date(new Date().getTime() - (24 * 60 * 60 * 1000))
  const [startDate1, setStartDate1] = useState(today1);
  const formatDate = useFormatDate();
  const { setDateToCallApiForChartArea1 } = useStateContext()  
  useEffect(() => {
    setDateToCallApiForChartArea1(formatDate.formatTomorrow(startDate1.toISOString()));
  }, [startDate1]);
  
  const handleClick = (e) => {
    e.preventDefault();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={styles.containerIcon} onClick={onClick} ref={ref}>
      <DateRangeIcon
        className={styles.circleIcon}
        onClick={handleClick}
      />
    </button>
  ));

  return (
    <div className={styles.containerDatepicker}>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        closeOnScroll={true}
        withPortal
        fixedHeight
        maxDate={new Date()}
        selected={startDate1}
        onChange={(date1) => setStartDate1(date1)}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};

const DatePickerCalendarIcon2 = () => {
  const today2 = new Date(new Date().getTime()- (new Date().getTimezoneOffset() * 60000))
  const yesterday2 = new Date(new Date().getTime() - (24 * 60 * 60 * 1000))
  const [startDate2, setStartDate2] = useState(yesterday2);
  const formatDate = useFormatDate();
  const { setDateToCallApiForChartArea2 } = useStateContext()  
  useEffect(() => {
    setDateToCallApiForChartArea2(formatDate.formatTomorrow(startDate2.toISOString()));
  }, [startDate2]);
  
  const handleClick = (e) => {
    e.preventDefault();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={styles.containerIcon} onClick={onClick} ref={ref}>
      <DateRangeIcon
        className={styles.circleIcon}
        onClick={handleClick}
      />
    </button>
  ));

  return (
    <div className={styles.containerDatepicker}>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        closeOnScroll={true}
        withPortal
        fixedHeight
        maxDate={new Date()}
        selected={startDate2}
        onChange={(date2) => setStartDate2(date2)}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};



const DatePickerCalendarRange = ({ typeChart }) => {
  const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000))

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(null);
  const [mountedDate, setMountedDate] = useState()
  const { setDateToCallApiForChartAreaRange,  setDateToCallApiForChartBarRange, setDateToCallApiForChartPieRange } = useStateContext()
  const formatDate = useFormatDate();

  useEffect(() => {
    setStartDate(new Date(getLastWeek().start))
    setEndDate(new Date(getLastWeek().end))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (startDate && endDate) {
      setMountedDate({
        start: formatDate.formatSimpleDate(startDate.toISOString()),
        end: formatDate.formatSimpleDate(endDate.toISOString())
      })
    }
  }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mountedDate) {
      switch (typeChart) {
        case 'pie':
          setDateToCallApiForChartPieRange(mountedDate);
          return
        case 'area':
          setDateToCallApiForChartAreaRange(mountedDate);
          return
          
        case 'bar':
          setDateToCallApiForChartBarRange(mountedDate);
          return
        default:
          return null
      }
    }
  }, [mountedDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClick = (e) => {
    e.preventDefault();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={styles.containerIcon} onClick={onClick} ref={ref}>
      <DateRangeIcon
        className={styles.circleIcon}
        onClick={handleClick}
      />
    </button>
  ));

  return (
    <div className={styles.containerDatepicker}>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        closeOnScroll={true}
        withPortal
        fixedHeight
        selected={startDate}
        onChange={onChange}
        minDate={subDays(62)}
        maxDate={new Date()}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};



export {
  DatePickerCalendarIcon,
  DatePickerCalendarIcon1,
  DatePickerCalendarIcon2,
  DatePickerCalendarRange
}