import React, { createContext, ReactNode, useContext, useState } from "react"
import { getReportConsumerUnitForHoursAPI, getReportEquipmentForDaysAPI, getReportEquipmentForHoursAPI, getReportSmartMeterForDaysAPI, getReportSmartMeterForHoursAPI } from "../services/restApiReport"

type PropsReportContext = {
  reportEquipmentForHoursList: any,
  reportEquipmentForDaysList: any,
  reportSmartMeterForHoursList: any,
  reportSmartMeterForDaysList: any,
  reportConsumerUnitForHoursList: {},
  reportConsumerUnitForHoursPie: {},
  reportConsumerUnitForHoursArea: {},
  
  loadingChartBar: boolean,
  loadingChartPie: boolean,
  loadingChart: boolean,
  setLoadingChartBar: React.Dispatch<React.SetStateAction<boolean>>,
  getReportEquipmentForHours: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportEquipmentForDays: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportSmartMeterForHours: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportSmartMeterForDays: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportConsumerUnitForHours: (id: string | number, startDate: string, endDate: string, typeChart: string) => Promise<void>,
}

type MeterContextTypes = {
  children: ReactNode
}

export const ReportContext = createContext({} as PropsReportContext)

function ReportContextProvider(props: MeterContextTypes) {
  const [reportEquipmentForHoursList, setReportEquipmentForHoursList] = useState<any>()
  const [reportEquipmentForDaysList, setReportEquipmentForDaysList] = useState<any>()
  const [reportSmartMeterForHoursList, setReportSmartMeterForHoursList] = useState<any>()
  const [reportSmartMeterForDaysList, setReportSmartMeterForDaysList] = useState<any>()
  const [reportConsumerUnitForHoursList, setReportConsumerUnitForHoursList] = useState({})
  const [reportConsumerUnitForHoursPie, setReportConsumerUnitForHoursPie] = useState({})
  const [reportConsumerUnitForHoursArea, setReportConsumerUnitForHoursArea] = useState({})
  
  const [loadingChartBar, setLoadingChartBar] = useState(true)
  const [loadingChartPie, setLoadingChartPie] = useState(true)
  const [loadingChart, setLoadingChart] = useState(true)

  const getReportEquipmentForHours = async (id: string | number, startDate: string, endDate: string) => {
  setLoadingChartBar(true);
  try {
    const { data, status } = await getReportEquipmentForHoursAPI(id, startDate, endDate);
    if (status === 200) {
      console.log('Updating reportEquipmentForHoursList', data);  // Log the new data
      setReportEquipmentForHoursList(data);
    } else {
      console.error(`getReportEquipmentForHours failed with status ${status}`);
      setReportEquipmentForHoursList(null);
    }
  } catch (error) {
    console.error('getReportEquipmentForHours API call failed:', error);
    setReportEquipmentForHoursList(null);
  } finally {
    setLoadingChartBar(false);
  }
};


  const getReportEquipmentForDays = async (id: string | number, startDate: string, endDate: string) => {

    const { data, status } = await getReportEquipmentForDaysAPI(id, startDate, endDate)
    if (status === 200) {
      setReportEquipmentForDaysList(data)
    } else {
      setReportEquipmentForDaysList(null)
    }
  }

  const getReportSmartMeterForHours = async (id: string | number, startDate: string, endDate: string) => {

    const { data, status } = await getReportSmartMeterForHoursAPI(id, startDate, endDate)
    if (status === 200) {
      setReportSmartMeterForHoursList(data)
    } else {
      setReportSmartMeterForHoursList(null)
    }
  }

  const getReportSmartMeterForDays = async (id: string | number, startDate: string, endDate: string) => {

    const { data, status } = await getReportSmartMeterForDaysAPI(id, startDate, endDate)
    if (status === 200) {
      setReportSmartMeterForDaysList(data)
    } else {
      setReportSmartMeterForDaysList(null)
    }
  }

  const getReportConsumerUnitForHours = async (id: string | number, startDate: string, endDate: string, typeChart: string) => {
    typeChart === "pie" ? setLoadingChartPie(true) : setLoadingChart(true)

    const { data, status } = await getReportConsumerUnitForHoursAPI(id, startDate, endDate)
    if (status === 200) {
      if (typeChart === "pie") {
        setLoadingChartPie(false)
        setReportConsumerUnitForHoursPie(data)
      }
      if (typeChart === "area") {
        setLoadingChart(false)
        setReportConsumerUnitForHoursArea(data)
      }
      setLoadingChart(false)
      setReportConsumerUnitForHoursList(data)
      
      setLoadingChart(false)
      setReportConsumerUnitForHoursList(data)
    } else {
      setLoadingChart(false)
      setReportConsumerUnitForHoursList(data)
    }
  }

  const objProvider = {
    loadingChartBar,
    loadingChartPie,
    loadingChart,
    setLoadingChartBar,
    reportEquipmentForHoursList,
    reportEquipmentForDaysList,
    reportSmartMeterForHoursList,
    reportSmartMeterForDaysList,
    reportConsumerUnitForHoursList,
    reportConsumerUnitForHoursPie,
    reportConsumerUnitForHoursArea,
    
    getReportEquipmentForHours,
    getReportEquipmentForDays,
    getReportSmartMeterForHours,
    getReportSmartMeterForDays,
    getReportConsumerUnitForHours,
  }

  return (
    <ReportContext.Provider
      value={objProvider}
    >
      {props.children}
    </ReportContext.Provider>
  )
}

function useReportContext() {
  const context = useContext(ReportContext)
  return context
}

export {
  ReportContextProvider,
  useReportContext
}
