import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  delUser,
  getUserAll,
  postUser,
  putUser,
} from "../services/restApiUser";
import {
  logoutUser,
  postConfirmEmail,
  postForgotPassword,
  postGenerateConfirmationEmail,
  refresh,
} from "../services/restApiAuth";
import { OrdinationIsAdmin } from "../util/ordernation";
import {
  User,
  Auth,
  AccessToken,
  PlaceData,
  LogedInUser,
  DataUser,
} from "../util/types";
import { useStateContext } from "./stateContext";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../util/hooks/useAxiosPrivate";
import api from "../services/api";


interface propsUserContext {
  user: User;
  place: PlaceData;
  auth: Auth;
  logedInUser: LogedInUser;
  dataUser: DataUser;
  accessToken: AccessToken;
  statePerson: boolean;
  tokenValidate: boolean;
  isCNPJ: boolean;
  isContractSigned: boolean;
  isAuthenticated: boolean;
  role: string;
  statusResponse: number;
  userList: Array<DataUser>;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  setPlace: React.Dispatch<React.SetStateAction<PlaceData>>;
  setAuth: React.Dispatch<React.SetStateAction<Auth>>;
  setLogedInUser: React.Dispatch<React.SetStateAction<LogedInUser>>;
  setDataUser: React.Dispatch<React.SetStateAction<DataUser>>;
  setAccessToken: React.Dispatch<React.SetStateAction<AccessToken>>;
  setStatePerson: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenValidate: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCNPJ: React.Dispatch<React.SetStateAction<boolean>>;
  setIsContractSigned: React.Dispatch<React.SetStateAction<boolean>>;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  setUserList: React.Dispatch<React.SetStateAction<Array<DataUser>>>;
  getUserList: () => Promise<void>;
  getUserByIdContext: (id: string) => Promise<void>;
  loginUser: (user: Auth, remember: boolean) => Promise<boolean>;
  signUpUser: (user: any) => Promise<boolean>;
  confirmEmail: (uid: string, token: string) => Promise<boolean>;
  generateConfirmEmail: (email: string) => Promise<boolean>;
  forgotPassword: (email: string) => Promise<boolean>;
  EditUser: (user: any, id: string, list: boolean) => Promise<boolean>;
  deleteUser: (id: string) => Promise<boolean>;
  refreshTokenContext: () => Promise<boolean>;
  logout: () => void;
}

type AuthContextProps = {
  children: ReactNode;
};



export const AuthContext = createContext({} as propsUserContext);

function AuthProvider({ children }: AuthContextProps) {
  const [user, setUser] = useState<User>({} as User);
  const [place, setPlace] = useState<PlaceData>({} as PlaceData);
  const [auth, setAuth] = useState<Auth>({} as Auth);
  const [logedInUser, setLogedInUser] = useState<LogedInUser>(
    {} as LogedInUser
  );
  const [dataUser, setDataUser] = useState<DataUser>({} as DataUser);
  const [accessToken, setAccessToken] = useState<AccessToken>(
    {} as AccessToken
  );
  const [statePerson, setStatePerson] = useState(true);
  const [isCNPJ, setIsCNPJ] = useState(false);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState<string>("");
  const [userList, setUserList] = useState<Array<DataUser>>(
    [] as Array<DataUser>
  );
  const [statusResponse, setStatusResponse] = useState<number>(0);
  const {
    setSnackBarTextResponse,
    setOpenSnackBar,
    setStatusErrorOpenSnackBar,
    setIsFetching,
  } = useStateContext();
  const [tokenValidate, setTokenValidate] = useState(false);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let recoveredAccessToken = localStorage.getItem("accessToken");
    let recoveredUserId = localStorage.getItem("userIdValue");

    if (recoveredAccessToken && recoveredUserId) {
      localStorage.setItem("accessToken", recoveredAccessToken);
      setIsAuthenticated(true);
    }
  }, []); // eslint-disable-line

  const loginUser = async (user: Auth, remember: boolean) => {
    let hasLoggedIn = false;
    
    try {
      const { data, status } = await api.post("/api/Auth/signin", user);
      hasLoggedIn = status === 200;
      
      if (hasLoggedIn) {
        const { accessToken, refreshToken, expiration, user, role } = data;
         
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiration", expiration);                
        let convertToString = `${user.id}`;
        localStorage.setItem("userIdValue", convertToString);
        localStorage.setItem("role", role);
        setDataUser(user);
        setIsAuthenticated(true);
        setIsFetching(false);
        if (await user.isContractSigned){                    
          navigate({
            pathname: "/dashboard",
            search: `?${user.consumerUnits.length > 0
              ? `consumerUnitId=${user.consumerUnits[0].id}`
              : ""
              }`,
          })
        }else{
          navigate('/contract');
        };
      } else {
        let message =
          data.message ||
          "Ocorreu um error inesperado, tente novamente mais tarde!";
        setSnackBarTextResponse(message);
      }
    } catch (error: any) {
      setStatusResponse(error.request.status);
      if (error.request.status === 401) {
        setSnackBarTextResponse("Usuário ou senha inválidos!");
      } else {
        const errorMessage = JSON.parse(error.request.response)?.error;
        setSnackBarTextResponse(errorMessage);
      }
    }

    return hasLoggedIn;
  };

  const signUpUser = async (user: any) => {
    let hasSignedUp = false;

    try {
      const { status } = await postUser(user);
      hasSignedUp = status === 200;

      if (hasSignedUp) {
        setStatusResponse(status);
        setSnackBarTextResponse("Usuário cadastrado com sucesso!");
      }
    } catch (error: any) {
      setStatusResponse(error.request.status);
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }

    return hasSignedUp;
  };

  const generateConfirmEmail = async (login: string) => {
    let hasConfirmEmail = false;
    const confirmEmail = { login: login };
    try {
      const { status } = await postGenerateConfirmationEmail(confirmEmail);
      hasConfirmEmail = status === 200;
      if (hasConfirmEmail) {
        setStatusResponse(status);
        setSnackBarTextResponse(
          "Email de confirmação enviado, por favor não deixe verificar sua caixa de spam!"
        );
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }

    return hasConfirmEmail;
  };

  const confirmEmail = async (uid: string, token: string) => {
    let hasFetch = false;
    let mountedPostConfirmEmail = {
      id: uid,
      token: token,
    };
    try {
      const { status } = await postConfirmEmail(mountedPostConfirmEmail);
      hasFetch = status === 200;
      if (hasFetch) {
        setOpenSnackBar(true);
        setStatusErrorOpenSnackBar(false);
        setSnackBarTextResponse("Email confirmado com sucesso!");
      }
    } catch (error: any) {
      setOpenSnackBar(true);
      setStatusErrorOpenSnackBar(true);
      setSnackBarTextResponse(
        `O link de confirmação está expirado ou o e-mail já foi confirmado.`
      );
    }
    return hasFetch;
  };

  const getUserList = async () => {
    const { data, status } = await getUserAll();

    if (status === 200) {
      OrdinationIsAdmin(data);
      setUserList(data);
    }
  };

  const getUserByIdContext = async (id: string) => {
    const { data, status } = await axiosPrivate.get(`/api/User/${id}`);    
    if (status === 200) {
      setDataUser(data);
    }
  };

  const EditUser = async (user: any, id: string, list: boolean) => {
    let hasEdit = false;

    try {
      const { status } = await putUser(user, id);
      hasEdit = status === 200;
      if (hasEdit) {
        if (list) {
          await getUserList();
        } else {
          await getUserByIdContext(id);
        }
        setSnackBarTextResponse("Usuário editado com sucesso!");
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }
    return hasEdit;
  };

  const deleteUser = async (id: string) => {
    let hasDelete = false;
    try {
      const { status } = await delUser(id);
      hasDelete = status === 200;
      if (hasDelete) {
        getUserList();
        setSnackBarTextResponse("Usuário deletado com sucesso!");
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }
    return hasDelete;
  };

  const forgotPassword = async (email: string) => {
    let hasForgotPassword = false;

    try {
      const { status } = await postForgotPassword(email);
      hasForgotPassword = status === 200;
      if (hasForgotPassword) {
        setSnackBarTextResponse("Email de recuperação enviado!");
      }
    } catch (error: any) {
      setStatusResponse(error.request.status);
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }
    return hasForgotPassword;
  };

  const refreshTokenContext = async () => {
    let hasFetch = false;
    setTokenValidate(true);
    const refreshToken = localStorage.getItem("refreshToken") || null;
    const accessToken = localStorage.getItem("accessToken") || null;
    const expiration = localStorage.getItem("expiration") || null;
    const now = new Date();

    let objToken = {
      refreshToken: refreshToken,
      accessToken: accessToken,
    };

    if (refreshToken && expiration && accessToken) {
      const formattedExpiration = new Date(expiration);
      const oneDayForExpiration = new Date(formattedExpiration.getDay() - 1);
      try {
        const { data, status } = await refresh(objToken);
        hasFetch = status === 200;
        if (now < oneDayForExpiration) {
          if (hasFetch) {
            const { accessToken, refreshToken, expiration } = data;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("expiration", expiration);
          } else {
            setTokenValidate(false);
            setSnackBarTextResponse(
              "Token expirado, por favor efetue o login novamente!"
            );
            setStatusErrorOpenSnackBar(true);
            setOpenSnackBar(true);
          }
        }
      } catch (error: any) {
        setTokenValidate(false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userIdValue");
        localStorage.removeItem("role");
        localStorage.removeItem("hasnosign")
        setStatusResponse(error.request.status);
        const errorMessage = JSON.parse(error.request.response)?.error;
        setSnackBarTextResponse(errorMessage);
        setStatusErrorOpenSnackBar(true);
        setOpenSnackBar(true);
      }
    }

    return hasFetch;
  };

  const logout = async () => {
    let hasLogout = false;
    try {
      const accessToken = localStorage.getItem("accessToken") || null;
      if (accessToken) {
        const { status } = await logoutUser();
        hasLogout = status === 204;
      }
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userIdValue");
      localStorage.removeItem("role");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expiration");
      localStorage.removeItem("hasnosign");      
      setIsAuthenticated(false);
      setDataUser({} as any);
      setStatusResponse(0);
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }
    return hasLogout;
  };

  const objProvider = {
    user,
    place,
    auth,
    logedInUser,
    dataUser,
    accessToken,
    statePerson,
    tokenValidate,
    isCNPJ,
    isContractSigned,
    isAuthenticated,
    statusResponse,
    role,
    userList,
    setUser,
    setPlace,
    setAuth,
    setLogedInUser,
    setDataUser,
    setAccessToken,
    setStatePerson,
    setTokenValidate,
    setIsCNPJ,
    setIsContractSigned,
    setRole,
    setUserList,
    loginUser,
    signUpUser,
    confirmEmail,
    generateConfirmEmail,
    EditUser,
    getUserList,
    getUserByIdContext,
    deleteUser,
    forgotPassword,
    refreshTokenContext,
    logout,
    
  };

  return (
    <AuthContext.Provider value={objProvider}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
