import { DateTime } from "luxon";
import { getLast24HoursWithParams, getTomorrow } from "../getLast24hours"

const formartDateInUTC = (timestamp) => {
  const date = DateTime.fromMillis(timestamp);

  return date.toISO();
}

const useFormatDate = () => {

  const formatUTC24 = (timestamp) => {
  if (!timestamp) return;
  if (typeof timestamp !== 'number') {
    console.error(`formatUTC24 expects a numerical input, but received: ${timestamp}`);
    return;
  }
  const formatUTC = formartDateInUTC(timestamp)
  let utcDate = DateTime.fromISO(formatUTC, { zone: 'UTC' });    
  return utcDate.toLocaleString(DateTime.TIME_24_SIMPLE)
}

  const formatUTCLestWeek = (timestamp) => {
    if (!timestamp) return;
    const formatUTC = formartDateInUTC(timestamp)
    let utcDate = DateTime.fromISO(formatUTC, { zone: 'UTC' }).toFormat('dd LLL');
    return utcDate.toLocaleString(DateTime.DATETIME_SHORT)
  }

  const formatSimpleDate = (date) => {
    if (!date) return;
    let simpleDate = DateTime.fromISO(date, { zone: 'UTC' });
    return simpleDate
  }

  const formatDateTimestamp = (date) => {
    if (!date) return;
    let timestamp = DateTime.fromISO(date, { zone: 'UTC' });
    return timestamp.toMillis()
  }

  const formatLast24Hours = (date) => {
    if (!date) return;

    let dateInTimestamp = formatDateTimestamp(date)

    let takeLast24Hours = getLast24HoursWithParams(dateInTimestamp)

    return takeLast24Hours
  }

  const formatTomorrow = (date) => {
    if (!date) return;
    let dateInTimestamp = formatDateTimestamp(date)
    let tomorrow = getTomorrow(dateInTimestamp)
    return tomorrow
  }

  return { formartDateInUTC, formatUTC24, formatSimpleDate, formatDateTimestamp, formatLast24Hours, formatUTCLestWeek, formatTomorrow }
}

export default useFormatDate;