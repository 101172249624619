import api from "./api";

export function getReportEquipmentForHoursAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/equipment/${id}/hours/${startTime}/${endTime}`);
}

export function getReportEquipmentForDaysAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/equipment/${id}/days/${startTime}/${endTime}`);
}

export function getReportSmartMeterForHoursAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/smartMeter/${id}/hours/${startTime}/${endTime}`);
}

export function getReportSmartMeterForDaysAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/smartMeter/${id}/days/${startTime}/${endTime}`);
}

export function getReportConsumerUnitForHoursAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/consumerUnit/${id}/hours/${startTime}/${endTime}`);
}

export function getReportModbusDataAPI(macid: string, startTime: string, endTime: string, column: string) {  
  return api.get(`/api/report/moddata/${macid}/${startTime}/${endTime}/${column}`);
}

export function getReportKhompDataAPI(macid: string, startTime: string, endTime: string, type: string) {  
  return api.get(`/api/report/kdata/${macid}/${startTime}/${endTime}/${type}`);
}