import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Header from "../../components/Header";
import { SideMenu } from "../../components/SideMenu";
import { useAuth } from "../../providers/authContext";
import { useConsumerUnitContext } from "../../providers/consumerUnitContext";
import { useStateContext } from "../../providers/stateContext";
import styles from "./styles.module.scss";
import { CircularProgress } from "@mui/material";
import { axiosPrivate } from "../../services/api";
export default function Layout() {
  const { showSideMenu, setShowSideMenu, isFetching, setIsFetching, setOpenSnackBar, setStatusErrorOpenSnackBar, setSnackBarTextResponse } = useStateContext()
  const { getUserByIdContext } = useAuth();
  const { getConsumerUnitList, consumerUnitList, mountPickerData } = useConsumerUnitContext()
  const location = useLocation();
  const navigate = useNavigate();

  function checkAccessToken() {
    const isAccessToken = () => localStorage.getItem("accessToken") !== null;

    if (isAccessToken()) {
      getUser()
      getSign()
      mountedStateConsumerUnit()
      mountPickerData(consumerUnitList)
    }
  }

  async function getUser() {
    try {
      const userValueId = await localStorage.getItem("userIdValue");
      if (userValueId) {
        await getUserByIdContext(userValueId)
      }
      setIsFetching(false)
    } catch (error) {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(true)
      setSnackBarTextResponse('Seu token de acesso expirou, por favor faça o login novamente.')
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("userIdValue")
      localStorage.removeItem("role")
      localStorage.removeItem("expiration")
      localStorage.removeItem("hasnosign")
      navigate("/", { state: { from: location }, replace: true })
    }
  }

 async function getSign() {
  try{
    const userValueId = await localStorage.getItem("userIdValue");
      if (userValueId) {        
        const { data, status } = await axiosPrivate.get(`/api/User/${userValueId}`);                        
        if (!await data.isContractSigned){          
          localStorage.setItem("hasnosign", "no")              
          navigate("/contract");
        }
      }
  }catch(erro){
    console.log("erro");
  }
 }

  const mountedStateConsumerUnit = async () => {
    let privilege = false
    const userValueId = await localStorage.getItem("userIdValue");
    const role = await localStorage.getItem("role");

    privilege = role === 'Admin' 

    if (userValueId) {
      getConsumerUnitList(userValueId, privilege)
    }
  }

  useEffect(() => {
    if (consumerUnitList.length > 0) {
      mountPickerData(consumerUnitList)
    }
  }, [consumerUnitList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpenSnackBar(false)

    switch (location.pathname) {
      case '/dashboard':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      case '/dashboard/modelManagement':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      case '/dashboard/smartMeterManagement':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      case '/dashboard/equipmentManagement':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      case '/dashboard/consumerUnit':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      case '/dashboard/myProfile':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      case '/dashboard/userManagement':
        setShowSideMenu(true)
        checkAccessToken()
        break;
      default:
        setShowSideMenu(false)
        break;
    }
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "20vw 80vw",
    }}>
      {showSideMenu ? <SideMenu /> : undefined}
      {
        isFetching ?
          <div className={styles.container}>
            <div className={styles.content}>
              <CircularProgress />
            </div>
          </div> :
          <main style={{ backgroundColor: '#FCFEFF' }}>
            <Header />
            <Outlet />
          </main>
      }
    </div>
  )
}
