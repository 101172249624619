import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "../../util/types";
import { useStateContext } from "../../providers/stateContext";
import { useAuth } from "../../providers/authContext";

import { useFormik } from "formik";
import * as Yup from "yup";

import Logo from "../../assets/image/deep-ambiente-logo.svg";
import styles from "./stylesbackup.module.scss";
import { Checkbox, FormControlLabel, InputAdornment } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";

import CustomInput from "../../components/CustomInput";
import { CustomSnackBar } from "../../components/Snackbar";
import CustomButtonLoading from "../../components/Form/buttonLoading";

export default function Signin() {
  const {
    loginUser,
    generateConfirmEmail,
    statusResponse,
    setTokenValidate,
    logout,
  } = useAuth();
  const { setIsLoading, setStatusErrorOpenSnackBar, setOpenSnackBar } =
    useStateContext();
  const [loginName, setLoginName] = useState("");

  useEffect(() => {
    logout();
  }, []); // eslint-disable-line

  const schema = Yup.object({
    username: Yup.string().required("Usuário obrigatório"),
    password: Yup.string()
      .min(6, "No mínimo 6 caracteres")
      .required("Você esqueceu a sua senha!"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      remember: false,
      
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const MountedAuthPost: Auth = {
        username: values.username,
        password: values.password,
      };
      setLoginName(values.username);

      const hasLogged = await loginUser(MountedAuthPost, values.remember);

      if (hasLogged) {
        setTokenValidate(hasLogged);
      } else {
        setOpenSnackBar(true);
        setStatusErrorOpenSnackBar(true);
      }
      setIsLoading(false);
    },
  });

  const handleGenerateConfirmEmail = async () => {
    setOpenSnackBar(false);
    let hasConfirmEmail = false;
    hasConfirmEmail = await generateConfirmEmail(loginName);
    if (hasConfirmEmail) {
      setOpenSnackBar(true);
      setStatusErrorOpenSnackBar(false);
    } else {
      setOpenSnackBar(true);
      setStatusErrorOpenSnackBar(true);
    }
  };
  

  return (
    <div className={styles.container}>
      <div className='color-overlay'/>
      <div className={styles.ContentBox}>
        <div className={styles.containerHeader}>
          <div className={styles.containerImage}>
            <img src={Logo} alt="Logo Deep Ambiente" width={260} height={170} />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.containerInput}>
            <CustomInput 
            type="text" 
            placeholder="E-mail"
              size="small"
              color='primary'
              variant="outlined"
              id="username"
              name="username"
              label=""
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              sx={{
          input: {
            color: "white",
            background: "transparent"
          }
        }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
              }}
            />
            
            <CustomInput
            placeholder="Password"
              size="small"
              variant="outlined"
              id="password"
              name="password"
              label=""
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{
          input: {
            color: "white",
            
          }
        }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{
            color: 'white',   
          }} />
          
                    
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              sx={{ margin: "0", justifyContent: "flex-end" }}
              control={
                <Checkbox
                  id="remember"
                  name="remember"
                  value={formik.values.remember}
                  onChange={formik.handleChange}
                  sx={{ padding: "0", marginRight: "5px", color:"white" }}
                  size="small"

                />
              }
              label="Manter logado"
            />
          </div>
          <div className={styles.containerButtons}>
            <CustomButtonLoading
              type="submit"
              title="Entrar"
              className="large"
            />
          </div>
        </form>
        <div className={styles.containerTextButtons}>
          <Link to="/signup" className={styles.buttonText}>
            criar uma conta
          </Link>
          <div className={styles.dividerVertical} />
          <Link to="/forgotPassword" className={styles.buttonText}>
            Esqueci a senha
          </Link>
        </div>
      </div>
      <CustomSnackBar
        action={
          statusResponse === 400 ? (
            <>
              <br />
              <span onClick={() => handleGenerateConfirmEmail()}>
                Clique aqui para reenviar email de confirmação
              </span>
            </>
          ) : null
        }
      />
      
    </div>
  );
}
