import { CircularProgress } from "@mui/material";
import { useStateContext } from "../../../providers/stateContext";
import styles from "./styles.module.scss";

export default function CustomButtonLoading({
  onClick,
  type,
  className,
  title,
}: React.ComponentProps<"button">) {
  const { isLoading } = useStateContext();
  function checkStyleButton(styleButton: string | undefined) {
    switch (styleButton) {
      case "small":
        return styles.smallButton;
      case "middle":
        return styles.middleButton;
      case "large":
        return styles.largeButton;
      case "smallOutline":
        return styles.smallOutlineButton;
      case "middleOutline":
        return styles.middleOutlineButton;
      case "largeOutline":
        return styles.largeOutlineButton;
      case "delete":
        return styles.deleteButton;
    }
  }
  function checkStyleLoading(styleButton: string | undefined) {
    switch (styleButton) {
      case "small":
        return styles.smallLoading;
      case "middle":
        return styles.middleLoading;
      case "large":
        return styles.largeLoading;
      case "delete":
        return styles.deleteLoading;
    }
  }

  return (
    <button
      type={type}
      className={checkStyleButton(className)}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        title
      )}
    </button>
  );
}
