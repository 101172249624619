import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import { CircularProgress, Divider } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Pagination } from "@material-ui/lab";

import { useModalContext } from "../../providers/modalContext";
import { ModalUserADM } from "../../components/Modal/UserADM";
import { ModalEditClient } from "../../components/Modal/EditClient";

import styles from "./styles.module.scss";
import { useStateContext } from "../../providers/stateContext";
import { usePagination } from "../../util/hooks/pagination";
import { DeleteItemModal } from "../../components/Modal/DeleteItem";
import { useAuth } from "../../providers/authContext";
import { CustomSnackBar } from "../../components/Snackbar";

export default function UserManagement() {
  const { setModalUserADM, setModalEditClient, setModalDelete } = useModalContext()
  const { isLoading, setIsLoading, setShowSelectAppBar, setSearchItem, searchItem } = useStateContext()
  const { deleteUser, userList, getUserList, logout } = useAuth()
  const [mountedUserEdit, setMountedUserEdit] = useState()
  const [mountedUserClientEdit, setMountedUserClientEdit] = useState()
  const [userId, setUserId] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [userFilter, setUserFilter] = useState<Array<any>>([] as any);
  const navigate = useNavigate()
  const location = useLocation()

  const PER_PAGE = 100;

  const handleFilterList = (e: any) => {
    if (e.target.value === "All") {
      setUserFilter(userList);
    } else {
      let FilterUser = userList.filter(
        (item: any) => item.userRole.name === e.target.value
      );
      setUserFilter(FilterUser);
    }
  };

  useEffect(() => {
    if (userList.length > 0) {
      setUserFilter(userList);
    }
  }, [userList]); // eslint-disable-line react-hooks/exhaustive-deps

  const count = Math.ceil(userFilter.length / PER_PAGE);
  const _DATA = usePagination(userFilter, PER_PAGE);

  useEffect(() => {
    getAll();
    setShowSelectAppBar(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchItem !== "") {
      if (userFilter!.length > 0) {
        const filter = userFilter!.filter(
          (item: any) =>
            item.fullName
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase()) ||
            item.document
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase()) ||
            item.email
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase()) ||
            item.phone
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase())
        );
        setUserFilter(filter);
      }
    } else {
      getAll();
      setUserFilter(userList);
    }
  }, [searchItem]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getAll() {
    try {
      setIsLoading(true)
      await getUserList()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      logout()
      navigate('/', { state: { from: location }, replace: true })
    }
  }

  const handleChange = (e: any, p: any) => {
    _DATA.jump(p);
  };

  const handleDeleteItem = (id: string, name: string) => {
    setUserId(id);
    setUserName(name);
    setModalDelete(true);
  };

  const handleOpenModalUserAdm = (user: any) => {
    setMountedUserEdit(user);
    setModalUserADM(true);
  };

  const handleOpenModalEditClient = (user: any) => {
    setMountedUserClientEdit(user);
    setModalEditClient(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <main className={styles.mainContainer}>
          <header className={styles.header}>
            <div className={styles.topHeader}>
              <h2>Usuários</h2>
              <div className={styles.searchUser}>
                <input
                  type="text"
                  placeholder="Pesquisar"
                  className={styles.Input}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.filterContainer}>
              <h3>Filtrar por:</h3>
              <button value="All" onClick={handleFilterList}>
                Todos
              </button>
              <button value="Cliente" onClick={handleFilterList}>
                Cliente
              </button>
              <button value="Tecnico" onClick={handleFilterList}>
                Técnico
              </button>
            </div>
          </header>

          <section className={styles.section}>
            <div className={styles.itemContainer}>
              <div className={styles.titletable}>
                <div />
                <div className={styles.contentHeader}>
                  <h5>Nome</h5>
                </div>
                <div className={styles.contentHeader}>
                  <h5 className={styles.doc}>Documento</h5>
                </div>
                <div className={styles.contentHeader}>
                  <h5 className={styles.phone}>Celular</h5>
                </div>
                <div className={styles.contentHeader}>
                  <h5 className={styles.email}>E-mail</h5>
                </div>
                <div />
              </div>
              {isLoading ? (
                <div className={styles.LoadingScreen}>
                  <CircularProgress />
                </div>
              ) : (
                userFilter &&
                _DATA.currentData().map((user: any) => {
                  return (
                    <div key={`${user.id}-${user.fullName}`}>
                      <div className={styles.mainContent}>
                        <div className={styles.contentBox}>
                          <div className={styles.iconCircle}>
                            {user.userRole?.name === "Admin" ? (
                              <ManageAccountsIcon sx={{ color: "#0f85ca" }} />
                            ) : (
                              <PersonIcon sx={{ color: "#0f85ca" }} />
                            )}
                          </div>
                        </div>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4>{user.fullName}</h4>
                          </div>
                        </div>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4 className={styles.doc}>{user.document}</h4>
                          </div>
                        </div>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4 className={styles.phone}>{user.phone}</h4>
                          </div>
                        </div>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4 className={styles.email}>{user.email}</h4>
                          </div>
                        </div>
                        <div className={styles.contentBox3}>
                          <div className={styles.contentItem}>
                            <button
                              onClick={() => {
                                user.userRole.name === "Admin"
                                  ? handleOpenModalUserAdm(user)
                                  : handleOpenModalEditClient(user);
                              }}
                            >
                              <EditIcon />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteItem(user.id, user.fullName)
                              }
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
              <Divider sx={{ mb: "1rem" }} />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  ul: {
                    display: "flex",
                    flexDirection: "row",
                  },
                }}
              >
                {/*<Pagination
              count={count}
              size="large"
              //page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
        />*/}
              </Box>
            </div>
          </section>
        </main>
        {mountedUserEdit ? (
          <ModalUserADM userEdit={mountedUserEdit} list={true} />
        ) : (
          <div />
        )}
        {mountedUserClientEdit ? (
          <ModalEditClient userEdit={mountedUserClientEdit} list={true} />
        ) : (
          <div />
        )}
        <DeleteItemModal
          id={userId}
          name={userName}
          functionDelete={() => deleteUser(userId)}
        />

        <CustomSnackBar />
      </div>
    </div>
  );
}
