import { useEffect, useState } from "react";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from "victory";
import useFormatDate from "../../../util/hooks/useFormartDate";
import styles from "./styles.module.scss";
import { useReportContext } from "../../../providers/reportContext";
import { ReactComponent as EquipamentIconOutline } from "../../../assets/equipamentIconOutline.svg";
import theme from "../../../styles/victoryTheme";
import { CircularProgress } from "@mui/material";

import{
  widthBarChart,
  heightBarChart,
  paddingLeftBar,
  paddingLeftBarFull,
  

} from "../../../util/ResponsiveCharts/responsive";


export default function BarChart({ width, height, data, typeFormatDate, chartSizeType }) {
  const formartDate = useFormatDate();
  const { loadingChartBar } = useReportContext();

  const [widthBarChartX, setBarChartX] = useState(0);
  const [heightBarChartY, setBarChartY] = useState(0);
  const [paddingBar, setpaddingBar] = useState(0);



useEffect(() => {
    switch(chartSizeType){
      case 'normal':
        setBarChartX(widthBarChart);
    setBarChartY(heightBarChart);
    setpaddingBar(paddingLeftBar);
                    
        break;      
      case 'fullscreen':
      setBarChartX(widthBarChart);
     setBarChartY(heightBarChart);
     setpaddingBar(paddingLeftBarFull);
               
        break;
      }    
  }, []);




  if (loadingChartBar) {
    return (
      <div className={styles.blurLoading}>
        <div
          style={{
            //backgroundColor:"red",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Buscando informações</h2>
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    if (data && Object.keys(data).length > 0 && data.consumption === 0) {
      return (
        <div className={styles.NotConsumer}>
          <h2>
            Não encontramos dados de consumo deste equipamento nesta data.
          </h2>
          <EquipamentIconOutline color="red" />
        </div>
      );
    } else if (
      data !== undefined &&
      Object.keys(data).length > 0 &&
      data.consumption > 0
    ) {
      return (
        <div className={styles.boxChart}>
        <VictoryChart 
          width={widthBarChartX}
          height={heightBarChartY}
          
          
          domainPadding={{ x: 0 }}
          animate={{duration: 500}}

          scale={{ x: "time" }}
          theme={theme}
          padding={{ top: 30, bottom: 120, left: paddingBar, right: 80, }}
           
        >
          <VictoryAxis
          
            dependentAxis
            crossAxis
            tickFormat={(tick) => `${tick} kWh`}
            style={{
              
              axis: { stroke: "#B8B8B8" },
              grid: {
                stroke: ({ tick }) => (tick > 0.5 ? "#B8B8B8" : "#B8B8B8"),
              },
              ticks: { stroke: "#B8B8B8", size: 35 },
              tickLabels: { fontSize: 20, padding: 35, fontWeight: "700" , },
            }}
          />
          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: "#646462" },
              grid: {
                stroke: ({ tick }) => (tick > 0.9 ? "#B8B8B8" : "#B8B8B8"),
              },
              ticks: { stroke: "#646462", size: 32, padding: 5 },
              tickLabels: {
                fontSize: 20,
                padding: 15,
                fontWeight: "700",
                angle: 45,
                
              },
            }}
          />
          <VictoryBar
               style={{
                data: { fill: "#0066b2", width: 23}
                
                }}
                animate={{
                onLoad: {
                  duration: 1500,
                  before: () => ({
                    _y: 0,
                    fill: "orange",
                    
                  })
                }
              }}
            alignment="start"
            theme={theme}
            height="90vh"
            x={(d) =>
              typeFormatDate === "hours"
                ? formartDate.formatUTC24(d.timestamp)
                : formartDate.formatUTCLestWeek(d.timestamp)
            }
            y={(d) => {
              return d.consumption ? d.consumption : 0;
            }}
            data={data.values}
            labels={(d) => `${d.datum.consumption.toFixed(2)} kWh`}
            labelComponent={<VictoryTooltip />}
          />
        </VictoryChart>
        </div>
      );
    }
  }

  return (
    <div className={styles.blurLoading}>
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo de equipamentos nesta data.</h2>
        <EquipamentIconOutline color="#0f85ca" />
      </div>
    </div>
  );
}