import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

import { Typography } from '@mui/material';

import Logo from '../../../assets/image/deep-ambiente-logo.svg'

import styles from './styles.module.scss';

import { useFormik } from "formik";
import * as Yup from 'yup'
import CustomButton from '../../Form/button';

import { useModalContext } from '../../../providers/modalContext';
import { ModelCategory } from '../../../util/types';
import CustomInput from '../../CustomInput';
import { useStateContext } from '../../../providers/stateContext';
import { useModelContext } from '../../../providers/modelContext';
import CustomButtonLoading from '../../Form/buttonLoading';

type props = {
  messageTitle: string,
  massageContent: string,
}


export function CategoryRegistration() {
  const { modalSubCategoryRegistration, setModalSubCategoryRegistration } = useModalContext()
  const { postCategoryContext } = useModelContext()
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext()
  const [maxWidth] = useState<DialogProps['maxWidth']>('xl');
  const [stateExitModal, setStateExitModal] = useState(false)


  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const validationSchema = Yup.object({
    name: Yup.string().min(3).required('esqueceu o Nome'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setIsLoading(true)

      const MountedCategoryPost: ModelCategory = {
        name: values.name
      }

      const hasPostCategory = await postCategoryContext(MountedCategoryPost)

      if (hasPostCategory) {
        setModalSubCategoryRegistration(false)
        setStatusErrorOpenSnackBar(false)
        handleOpenSnackBar()
        setIsLoading(false)
        formik.resetForm()
      } else {
        setStatusErrorOpenSnackBar(true)
        handleOpenSnackBar()
        setIsLoading(false)
        formik.resetForm()
      }
    }

  })

  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps['maxWidth']>('sm');

    const CloseAllModal = () => {
      setModalSubCategoryRegistration(false)
      setStateExitModal(false)
    }

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"

      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "600px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">{messageTitle}</Typography>
          <Typography component={'span'} variant={'body2'}>{massageContent}</Typography>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '8rem 8rem',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}>
            <CustomButton type="button" title="Não" className="smallOutline" onClick={() => handleClose()} />
            <CustomButton type="button" title="Sim" className="small" onClick={() => CloseAllModal()} />
          </div>
        </Box>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalSubCategoryRegistration}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center'
                }}
              >
                <DialogTitle sx={{ textAlign: 'center', textTransform: 'uppercase', fontSize: '1rem', marginRight: '-20px' }}>Cadastrar Categoria</DialogTitle>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-end',

                }}
              >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleClose()} />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '0px !important'
              }}
            >
              <CustomInput
                size="small"
                variant="outlined"
                id="name"
                name="name"
                label="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{
                  width: '400px'
                }}
              />
            </DialogContent>
            <Box
              sx={{
                padding: '20px 24px',
              }}
            >
              <Divider />
            </Box>
            <DialogActions
              sx={{
                padding: '0rem 1.5rem !important'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mb: '1rem'
                }}
              >
                <CustomButtonLoading type="submit" title="Cadastrar" className="middle" />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <ExitModal messageTitle="Deseja cancelar o cadastro?" massageContent="Os dados preenchidos serão perdidos!" />
    </>
  );
}
