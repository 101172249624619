import React, { useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

import styles from './styles.module.scss';
import { RadioGroup, FormControlLabel, Radio, FormControl } from "@mui/material"


import { useModalContext } from "../../../providers/modalContext";
import { useMeterContext } from "../../../providers/meterContext";
import { useStateContext } from '../../../providers/stateContext';
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import { useFormik } from "formik";
import * as yup from "yup";

import CustomInput from "../../CustomInput";
import CustomButtonLoading from "../../Form/buttonLoading";
import CustomButton from '../../Form/button';

type props = {
  messageTitle: string;
  massageContent: string;
};

type meterProps = {
  isSmart: boolean;
  title: string
}

export function ModalMeterRegistration({ isSmart, title }: meterProps) {
  const { modalMeterRegistration, setModalMeterRegistration } = useModalContext();
  const { postMeter } = useMeterContext()
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext()
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);

  const [meterTypeSelection, setMeterTypeSelection] = useState('modbus'); // default to 'modbus'

  const validationSchema = yup.object({
    macId: yup.string(),
    name: yup.string(),
  });




  const formik = useFormik({
    initialValues: {
      macId: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      const moutendMeterPost = {
        id: values.macId,
        name: values.name,
        isSmart: isSmart,
        meterType: meterTypeSelection, // include meterTypeSelection here
      };
      

      const hasMeter = await postMeter(moutendMeterPost);

      if (hasMeter) {
        setIsLoading(false)
        setStatusErrorOpenSnackBar(false)
        handleOpenSnackBar()
        formik.resetForm()
        setModalMeterRegistration(false)
      } else {
        setIsLoading(false)
        setStatusErrorOpenSnackBar(true)
        handleOpenSnackBar()
      }
    },
  });

  const handleClose = () => {
  console.log('Closing dialog...');
  setStateExitModal(true);
};
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalMeterRegistration(false);
      setStateExitModal(false);
    };

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "250px",
            //minHeight: "100px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleClose()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalMeterRegistration}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "1rem",
            
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "40px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                   marginRight: "-80px",
                  }}
                >
                  Cadastrar
                  <br />
                  {title}
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: "flex",
                //alignItems: "center",
                //justifyContent: "center",
                paddingTop: "4px !important",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gap: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60vw",
                    //backgroundColor:"red"
                  }}
                >
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    sx={{ mb: "1rem" }}
                  >
                    Nome do Medidor
                  </Typography>
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60vw",
                  }}
                >
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    sx={{
                      mb: "0.8rem",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Identificador do Medidor
                    <Tooltip title="O identificador é Tópico MQTT">
                      <ErrorIcon
                        sx={{ width: "20px", height: "20px", color: "#ee9c0f" }}
                      />
                    </Tooltip>
                  </Typography>
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="macId"
                    name="macId"
                    label="Tópico MQTT"
                    value={formik.values.macId}
                    onChange={formik.handleChange}
                    error={formik.touched.macId && Boolean(formik.errors.macId)}
                    helperText={formik.touched.macId && formik.errors.macId}
                  />


                  <FormControl>
  <Typography component={"span"} variant={"body2"}>
    <div style={{ marginBottom: 15 }}>Selecionar tipo de medidor</div>
  </Typography>
  <RadioGroup
    aria-labelledby="meter-type-radio-group"
    name="meterTypeSelection"
    value={meterTypeSelection}
    onChange={(event) => setMeterTypeSelection(event.target.value)}
    sx={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    }}
  >
    <FormControlLabel
      value='modbus'
      control={<Radio size="small" />}
      label="Modbus"
      sx={{ margin: 0, gap: '7px' }}
    />
    <FormControlLabel
      value='TC'
      control={<Radio size="small" />}
      label="TC"
      sx={{ margin: 0, gap: '7px' }} />
  </RadioGroup>
</FormControl>

                  
                </Box>
                
              </Box>
              <Divider />
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.7rem !important",
                justifyContent: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  //justifyContent: "start",
                  mb: "1rem",
                  marginTop: 3,
                }}
              >
                <CustomButtonLoading
                  type="submit"
                  title="Cadastrar"
                  className="middle"
                />
              </Box>\
              
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <ExitModal
        messageTitle="Deseja cancelar o cadastro?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
