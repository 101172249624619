import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";


import styles from './styles.module.scss';

import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from "../../Form/button";
import CustomInput from "../../CustomInput";
import { useStateContext } from "../../../providers/stateContext";
import InputMask from "react-input-mask";
import maskConfig from "../../../util/maks";
import onlyNumber from "../../../util/onlyNumber";
import { useConsumerUnitContext } from "../../../providers/consumerUnitContext";
import { CustomSnackBar } from "../../Snackbar";
import CustomButtonLoading from "../../Form/buttonLoading";



type propsModal = {
  consumerUnitEdit: any;
};

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalEditConsumerUnit(consumerUnitEdit: propsModal) {
  const { modalEditConsumerUnit, setModalEditConsumerUnit } = useModalContext();
  const { editConsumerUnit, powerDistributorsList, getPowerDistributorsList } =
    useConsumerUnitContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } =
    useStateContext();
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);

  const {
    id,
    placeName,
    phone,
    placeEmail,
    zipCode,
    state,
    city,
    street,
    district,
    number,
    complement,
    powerDistributorId,
    powerDistributor,
    tariffType
  } = consumerUnitEdit.consumerUnitEdit;

  useEffect(() => {
    getPowerDistributorsList();
  }, []); // eslint-disable-line

  useEffect(() => {
    formik.setFieldValue("placeName", placeName || "", false);
    formik.setFieldValue("phone", phone, false);
    formik.setFieldValue("email", placeEmail, false);
    formik.setFieldValue("zipCode", zipCode, false);
    formik.setFieldValue("state", state, false);
    formik.setFieldValue("city", city, false);
    formik.setFieldValue("street", street, false);
    formik.setFieldValue("district", district, false);
    formik.setFieldValue("number", number, false);
    formik.setFieldValue(
      "complement",
      complement === null ? "" : complement,
      false
    );
    formik.setFieldValue("distribuctors", powerDistributorId, false);
    formik.setFieldValue("tariffType", tariffType, false);
  }, [consumerUnitEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = yup.object({
    placeName: yup.string().required("Campo obrigatório"),
    phone: yup.string().required("Campo obrigatório"),
    email: yup.string().email().required("Campo obrigatório"),
    zipCode: yup.string().required("Campo obrigatório"),
    state: yup.string().required("Campo obrigatório"),
    city: yup.string().required("Campo obrigatório"),
    street: yup.string().required("Campo obrigatório"),
    district: yup.string().required("Campo obrigatório"),
    number: yup.number().required("Campo obrigatório"),
    complement: yup.string(),
    distribuctors: yup.string().required("Campo obrigatório"),
    tariffType: yup.string().required("Campo obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      placeName: placeName,
      phone: onlyNumber(phone),
      placeEmail: placeEmail,
      zipCode: onlyNumber(zipCode),
      state: state,
      city: city,
      street: street,
      district: district,
      number: number,
      complement: complement === null ? "" : complement,
      distribuctors: powerDistributor.id,
      tariffType: tariffType,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const userId = localStorage.getItem("userIdValue") || "";

      const mountedConsumerUnitPut = {
        placeName: values.placeName,
        phone: values.phone,
        placeEmail: values.placeEmail,
        zipCode: values.zipCode,
        state: values.state,
        city: values.city,
        street: values.street,
        district: values.district,
        number: values.number,
        complement: values.complement === "" ? null : values.complement,
        userId: userId,
        powerDistributorId: values.distribuctors,
        tariffType: parseInt(values.tariffType)
      };


      setIsLoading(true);
      const hasPutConsumerUnit = await editConsumerUnit(
        mountedConsumerUnitPut,
        id
      );

      if (hasPutConsumerUnit) {
        setIsLoading(false);
        setStatusErrorOpenSnackBar(false);
        handleOpenSnackBar();
        setModalEditConsumerUnit(false);
      } else {
        setIsLoading(false);
        setStatusErrorOpenSnackBar(true);
        handleOpenSnackBar();
      }
    },
  });

  const options = powerDistributorsList.map((option) => {
    const firstLetter = option.distribuidora[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidthExit] = useState<DialogProps["maxWidth"]>("xl");

    const CloseAllModal = () => {
      setModalEditConsumerUnit(false);
      setStateExitModal(false);
    };

    const handleCloseExit = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidthExit}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleCloseExit()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalEditConsumerUnit}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "-10px",
                  }}
                >
                  Editar <br /> Unidade Consumidora
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon 
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1.25rem",
                  }}
                >
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="placeName"
                    name="placeName"
                    label="Nome da Unidade"
                    value={formik.values.placeName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.placeName &&
                      Boolean(formik.errors.placeName)
                    }
                    helperText={
                      formik.touched.placeName && formik.errors.placeName
                    }
                  />

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    defaultValue={powerDistributor}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) =>
                      `${option.distribuidora} - ${option.uf}`
                    }
                    /* sx={{ width: 300, height: 150, backgroundColor: "gray" }} */
                    isOptionEqualToValue={(option, value) =>
                      option.distribuidora === value.distribuidora
                    }
                    onChange={(event, value) => {
                      formik.setFieldValue("distribuctors", value?.id);
                    }}
                    renderInput={(params) => (
                      <CustomInput
                        {...params}
                        size="small"
                        name="distribuctors"
                        label="Empresa de Energia"
                        value={formik.values.distribuctors}
                        onChange={formik.handleChange}
                        sx={{ height: 40 }}
                        error={
                          formik.touched.distribuctors &&
                          Boolean(formik.errors.distribuctors)
                        }
                        helperText={
                          formik.touched.distribuctors &&
                          formik.errors.distribuctors
                        }
                      />
                    )}
                  />

                  <FormControl
                    sx={{
                      display: "flex !important",
                      justifyContent: "flex-end !important",
                      "& .MuiFormControl-root": {
                        display: "flex !important",
                        justifyContent: "flex-end !important",
                      },
                      label: {
                        marginTop: "1rem !important",
                      }
                    }}
                  >
                    <InputLabel sx={{ top: '-7px', fontSize: '0.9rem' }} id="equipmentModelBusinessId">
                      Tarifa
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="tariffType"
                      id="tariffType"
                      value={formik.values.tariffType}
                      name="tariffType"
                      label="Tarifa"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tariffType &&
                        Boolean(formik.errors.tariffType)
                      }
                    /* className={styles.fixMuiInputBaseRootEditModel} */
                    >
                      <MenuItem value="" selected disabled>
                        <em>Selecione um tipo de tarifa</em>
                      </MenuItem>
                      <MenuItem value={1}>Tarifa Convencional</MenuItem>
                      <MenuItem value={2}>Tarifa Branca Ponta</MenuItem>
                      <MenuItem value={3}>Tarifa Branca Intermediária </MenuItem>
                      <MenuItem value={4}>Tarifa Branca Fora Ponta </MenuItem>
                    </Select>
                    <span className={styles.error}>{formik.touched.tariffType && formik.errors.tariffType}</span>
                  </FormControl>

                  {/* <InputMask
                    mask={maskConfig.phone}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  >
                    {(inputProps: any) => (
                      <CustomInput
                        {...inputProps}
                        size="small"
                        variant="outlined"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        id="phone"
                        name="phone"
                        label="Telefone"
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    )}
                  </InputMask> */}
                  <CustomInput
                    size="small"
                    variant="outlined"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    id="phone"
                    name="phone"
                    label="Telefone"
                    error={
                      formik.touched.phone && Boolean(formik.errors.phone)
                    }
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="placeEmail"
                    name="placeEmail"
                    label="E-mail"
                    value={formik.values.placeEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.placeEmail &&
                      Boolean(formik.errors.placeEmail)
                    }
                    helperText={
                      formik.touched.placeEmail && formik.errors.placeEmail
                    }
                  />
                  {/* <InputMask
                    mask={maskConfig.cep}
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                  >
                    {(inputProps: any) => (
                      <CustomInput
                        size="small"
                        value={formik.values.zipCode}
                    onChange={formik.handleChange}
                        variant="outlined"
                        id="zipCode"
                        name="zipCode"
                        label="CEP"
                        error={
                          formik.touched.zipCode &&
                          Boolean(formik.errors.zipCode)
                        }
                        helperText={
                          formik.touched.zipCode && formik.errors.zipCode
                        }
                      />
                    )}
                  </InputMask> */}
                  <CustomInput
                    size="small"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    variant="outlined"
                    id="zipCode"
                    name="zipCode"
                    label="CEP"
                    error={
                      formik.touched.zipCode &&
                      Boolean(formik.errors.zipCode)
                    }
                    helperText={
                      formik.touched.zipCode && formik.errors.zipCode
                    }
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="state"
                    name="state"
                    label="Estado"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="city"
                    name="city"
                    label="Cidade"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="district"
                    name="district"
                    label="Bairro"
                    value={formik.values.district}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched.district && formik.errors.district
                    }
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="street"
                    name="street"
                    label="Rua"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.street && Boolean(formik.errors.street)
                    }
                    helperText={formik.touched.street && formik.errors.street}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="number"
                    name="number"
                    label="N°"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    helperText={formik.touched.number && formik.errors.number}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="complement"
                    name="complement"
                    label="Complemento"
                    value={formik.values.complement}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.complement &&
                      Boolean(formik.errors.complement)
                    }
                    helperText={
                      formik.touched.complement && formik.errors.complement
                    }
                  />

                </Box>
                <Box
                  sx={{
                    padding: "1rem 0rem",
                  }}
                ></Box>
                <Divider />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <CustomButtonLoading
                  type="submit"
                  title="Editar"
                  className="middle"
                />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
      <CustomSnackBar />
      <ExitModal
        messageTitle="Deseja cancelar a edição?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
