export function getLast24Hours() {
  const now = new Date()
  const yesterday = new Date(now.getTime() - (24 * 60 * 60 * 1000))

  const last24Hours = {
    start: yesterday.toISOString().split('T')[0],
    end: now.toISOString().split('T')[0]
  }
  return last24Hours
}

export function getLast24HoursWithParams(dateFormated) {
  const now = new Date(dateFormated)
  const yesterday = new Date(now.getTime() - (24 * 60 * 60 * 1000))

  const last24Hours = {
    start: now.toISOString().split('T')[0],
    end: yesterday.toISOString().split('T')[0]
  }
  return last24Hours
}

export function getTomorrow(dateFormated) {
  const now = new Date(dateFormated)
  const tomorrow = new Date(now.getTime() + (24 * 60 * 60 * 1000))

  const tomorrowDate = {
    start: now.toISOString().split('T')[0],
    end: tomorrow.toISOString().split('T')[0]
  }
  return tomorrowDate
}