import React from "react";
import { Snackbar } from "@mui/material";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useStateContext } from "../../providers/stateContext";
import styles from "./styles.module.scss";

type props = {
  action?: any
}

export function CustomSnackBar(props: props) {
  const { openSnackBar, setOpenSnackBar, openStatusErrorSnackBar, snackBarTextResponse } = useStateContext()
  const { action } = props

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={10000}
      className={styles.snackbar}
      onClose={handleCloseSnackBar}
    >
      <Alert
        onClose={handleCloseSnackBar}
        severity={openStatusErrorSnackBar ? "error" : "success"}
        sx={{ width: "100%" }}
      >
        {snackBarTextResponse} {action}
      </Alert>
    </Snackbar >
  )
}