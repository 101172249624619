
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import styles from "./styles.module.scss";

import lampada from "../../../assets/image/lampada.jpg";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useModalContext } from "../../../providers/modalContext";
import { useEffect, useState } from "react";
import {  
  DatePickerCalendarIcon1,
  DatePickerCalendarIcon2,
  DatePickerCalendarRange,
} from "../../Form/DatePicker";
import { useStateContext } from "../../../providers/stateContext";
import PieChart from "../../Charts/PieChart";
import { useConsumerUnitContext } from "../../../providers/consumerUnitContext";
import AreaChart from "../../Charts/AreaChart";
import BarChart from "../../Charts/BarChart";
import LineAreaChart from "../../Charts/TensaoChart";
import LineAreaChart2 from "../../Charts/DemandaChart";
import LineAreaChart3 from "../../Charts/CorrenteChart";
import LineAreaChart4 from "../../Charts/Energia";
import LineAreaChart5 from "../../Charts/PotenciaChart";
import { useReportContext } from "../../../providers/reportContext";
import CustomButton from "../../Form/button";
import { getReportConsumerUnitForHoursAPI } from "../../../services/restApiReport";
import AreaChartComp from "../../Charts/AreaChartComp";


type props = {
  dataChart: any;
  typeChart: string;
};

export default function ChartFullScreen({ dataChart, typeChart }: props) {
  if (typeChart === "pie") {
    return <CardPie data={dataChart} typeChart={typeChart} />;
  }
  if (typeChart === "bar") {
    return <CardBar data={dataChart} typeChart={typeChart} />;
  }
  if (typeChart === "area") {
    return <CardArea data={dataChart} typeChart={typeChart} />;
  }
  
  
  return null;
}

function CardPie({ data, typeChart }: any) {  
  const {
    modalChartFullscreenPie,
    setModalChartFullscreenPie,
    setModalChartFullscreenArea,
    setModalChartFullscreenBar,
  } = useModalContext();
  const { loadingChartPie } = useReportContext();
  const { consumerUnitList } = useConsumerUnitContext();
  const [currentConsumerUnit, setCurrentConsumerUnit] = useState({} as any);
  const { dateToCallApiForChartPieRange, dateToCallApiForChartPie } = useStateContext();
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const { getReportConsumerUnitForHours, reportConsumerUnitForHoursPie } = useReportContext()
  const [pieChartData, setPieChartData] = useState([] as any);

  
  

  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.3);
const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.3);

useEffect(() => {
    const handleResize = () => {
    setChartWidth(window.innerWidth * 0.3);
    setChartHeight(window.innerHeight * 0.3);
}

    window.addEventListener('resize', handleResize)

    // Clean up function
    return () => {
        window.removeEventListener('resize', handleResize)
    }
}, []) // Empty array ensures this runs on mount and unmount
  
  
  useEffect(() => {
    setModalChartFullscreenArea(false);
    setModalChartFullscreenBar(false);    
  }, [modalChartFullscreenPie]); // eslint-disable-line

  useEffect(() => {
    let current = getInfoConsumerUnitFilter();
    setCurrentConsumerUnit(current);
  }, [data]); // eslint-disable-line

  function getInfoConsumerUnitFilter() {
    let consumerUnitFilter = consumerUnitList.filter(
      (item) => item.id === data.consumerUnitId
    );
    return consumerUnitFilter[0];
  }
  useEffect(() => {
    if (Object.keys(dateToCallApiForChartPieRange).length > 0) {
      let datePieStart = dateToCallApiForChartPieRange.start;
      let datePieEnd = dateToCallApiForChartPieRange.end;
      let formatPieDateStart = DateTime.fromJSDate(new Date(datePieStart))
        .setLocale("GTM")
        .toLocaleString({ month: "long", day: "numeric" });
      let formatPieDateEnd = DateTime.fromJSDate(new Date(datePieEnd))
        .setLocale("GTM")
        .toLocaleString({ month: "long", day: "numeric" });
      setDateStart(formatPieDateStart);
      setDateEnd(formatPieDateEnd);
      let fStart = DateTime.fromJSDate(
        new Date(datePieStart)
      )
        .setLocale("GTM")
        .toFormat("yyyy-MM-dd");
      let fEnd = DateTime.fromJSDate(
        new Date(datePieEnd)
      )
        .setLocale("GTM")
        .toFormat("yyyy-MM-dd");        
      getReportConsumerUnitForHours(currentConsumerUnit.id, fStart, fEnd, 'pie')
      
    }
  }, [dateToCallApiForChartPieRange]);

  useEffect(() => {
    setPieChartData(reportConsumerUnitForHoursPie)    
  }, [reportConsumerUnitForHoursPie])  
  
  return (
    <>
      <Dialog className={styles.caixa}
        open={modalChartFullscreenPie}
        onClose={() => ( getReportConsumerUnitForHours(currentConsumerUnit.id, dateToCallApiForChartPie.start, dateToCallApiForChartPie.end, 'pie'), setModalChartFullscreenPie(false))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <Box className={styles.content} >
          <DialogTitle id="alert-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h6" style={{ color: '#30313D', fontWeight: 'bold', fontSize: '22px' }}>Calculadora de Consumo</Typography>
                <Typography variant="subtitle1">{dateStart} - {dateEnd}</Typography>
              </Box>
              <Box display="flex" sx={{ gap: "1.5rem" }}>
                <DatePickerCalendarRange typeChart={typeChart} />
                <FullscreenExitIcon
                  style={{
                    color: "#0f85ca",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => ( getReportConsumerUnitForHours(currentConsumerUnit.id, dateToCallApiForChartPie.start, dateToCallApiForChartPie.end, 'pie'),setModalChartFullscreenPie(false))}
                />
              </Box>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="start"
            >
              <Box
   sx={{
    height: "100vh", // change to desired height
    width: "200",  // change to desired width
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // This will align items vertically center
    alignItems: "center", // This will align items horizontally center
    backgroundColor: "rgba(220, 224, 233, 0.845)"
    
  }}
>
                {!loadingChartPie && pieChartData && pieChartData.totalConsumption > 0 && (
                  <>
                    <Typography variant="subtitle1" style={{ color: '#30313D', fontWeight: 'bold', fontSize: '20px' }}>
    Consumo Total: {(pieChartData.totalSmartMeterConsumption) > pieChartData.totalConsumption ? 
    (pieChartData.totalSmartMeterConsumption).toFixed(2):pieChartData.totalConsumption.toFixed(2)} kWh
  </Typography>
  <Typography variant="subtitle1" style={{ color: '#30313D', fontWeight: 'bold', fontSize: '20px' }}>
    Valor Consumo Total:
    {((pieChartData.totalEquipmentConsumptionPrice/pieChartData.totalConsumption)*(pieChartData.totalSmartMeterConsumption)) > 
    pieChartData.totalEquipmentConsumptionPrice ? 
    ` R$ ${((pieChartData.totalEquipmentConsumptionPrice/pieChartData.totalConsumption)*(pieChartData.totalSmartMeterConsumption)).toFixed(2)}`
    :` R$ ${pieChartData.totalEquipmentConsumptionPrice.toFixed(2)}`}
  </Typography>
                  </>
                )}
                <Box
    width={500}
    height={700}
    
>
                <PieChart
      pieSvg={"0 0 500 850"}
      positionLegend={5}
      data={pieChartData}
      startTime={dateToCallApiForChartPieRange.start}
      endTime={dateToCallApiForChartPieRange.end}
      handleSliderChange
    />
                </Box>
              </Box>
              <Box>
                {currentConsumerUnit &&
                  Object.keys(currentConsumerUnit).length > 0 ? (
                  <>
                {/*<Box>
                      <Typography variant="h6">
                        Detalhes de Unidade Consumidora
                      </Typography>
                      <Typography variant="subtitle1">
                        Nome: {currentConsumerUnit.placeName}
                      </Typography>
                      <Typography variant="subtitle1">
                        E-mail: {currentConsumerUnit.placeEmail}
                      </Typography>
                      <Typography variant="subtitle1">
                        Telefone: {currentConsumerUnit.phone}
                      </Typography>
                      <Typography variant="subtitle1">
                        Distribuidora de Energia:
                        {currentConsumerUnit.powerDistributor.distribuidora} -
                        {currentConsumerUnit.powerDistributor.uf}
                      </Typography>
                    </Box>
                    <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      <Typography variant="h6">Detalhes de Medidor</Typography>
                      <Typography variant="subtitle1">
                        Nome: {currentConsumerUnit.mac?.name}
                      </Typography>
                      <Typography variant="subtitle1">
                        Identificador: {currentConsumerUnit.mac?.id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Detalhes de Equipamentos
                      </Typography>
                      <Typography variant="subtitle1">
                        Equipamentos cadastrados:{" "}
                        {currentConsumerUnit.equipments.length}
                      </Typography>
                    </Box>*/}
                  </>
                ) : (
                  <div />
                )}
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
function CardArea({ data, typeChart }: any) {    
  const {
    modalChartFullscreenArea,
    setModalChartFullscreenArea,
    setModalChartFullscreenBar,
    setModalChartFullscreenPie,
  } = useModalContext();  
  const [currentConsumerUnit, setCurrentConsumerUnit] = useState({} as any);  
  const [areaData, setAreaData] = useState([] as any); 
  const [lineData, setLineData] = useState([] as any);   
  const [areaData1, setAreaData1] = useState([] as any);  
  const [areaData2, setAreaData2] = useState([] as any);  
  const { consumerUnitList } = useConsumerUnitContext(); 
  const [comparasion, setComparasion] = useState(false);
  const { dateToCallApiForChartArea1 } = useStateContext();
  const { dateToCallApiForChartArea2 } = useStateContext();
  let dateAreaStart1 = new Date(dateToCallApiForChartArea1.start);
  let dateAreaEnd1 = new Date(dateToCallApiForChartArea1.end);
  let dtAreaStart = DateTime.fromMillis(dateAreaStart1.getTime());
  let dtAreaEnd = DateTime.fromMillis(dateAreaEnd1.getTime());

  let formatAreaDate = {
    end: dtAreaEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
    start: dtAreaStart.toUTC().toLocaleString({ month: "long", day: "numeric" })
  }


  let dateAreaStart2 = new Date(dateToCallApiForChartArea2.start);
  let dateAreaEnd2 = new Date(dateToCallApiForChartArea2.end); //new Date(areaDate2.getTime() + (24 * 60 * 60 * 1000));
  let dtAreaStart2 = DateTime.fromMillis(dateAreaStart2.getTime());
  let dtAreaEnd2 = DateTime.fromMillis(dateAreaEnd2.getTime());  
  let formatAreaDate2 = {
    end: dtAreaEnd2.toUTC().toLocaleString({ month: "long", day: "numeric" }),
    start: dtAreaStart2.toUTC().toLocaleString({ month: "long", day: "numeric" })
  }

  function getInfoConsumerUnitFilter() {
    let consumerUnitFilter = consumerUnitList.filter(
      (item) => item.id === data.consumerUnitId
    );
    return consumerUnitFilter[0];
  }
  
  const getDataforAreaChart = async (id: string | number, startDate: string, endDate: string) => {
    const { data, status } = await getReportConsumerUnitForHoursAPI(id, startDate, endDate).then();
    if (status === 200){      
      setAreaData(data);
    }else{      
      setAreaData(data);
    }     
  }

  const getDataforAreaChart1 = async (id: string | number, startDate: string, endDate: string) => {
    const { data, status } = await getReportConsumerUnitForHoursAPI(id, startDate, endDate).then();
    if (status === 200){      
      setAreaData1(data);
    }else{      
      setAreaData1(data);
    }     
  }

  useEffect(() => {
    let current = getInfoConsumerUnitFilter();
    setCurrentConsumerUnit(current);
  }, [data]); // eslint-

  useEffect(() => {
    setModalChartFullscreenBar(false);
    setModalChartFullscreenPie(false);
    setComparasion(false);
  }, [modalChartFullscreenArea]); // eslint-disable-line

  useEffect(() => {
    let fStart1 = DateTime.fromJSDate(
      new Date(dateAreaStart1.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
  let fEnd1 = DateTime.fromJSDate(
      new Date(dateAreaEnd1.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");       
      getDataforAreaChart1(data.consumerUnitId, fStart1, fEnd1);             
  }, [dateToCallApiForChartArea1]);

  useEffect(() => {
    let fStart = DateTime.fromJSDate(
      new Date(dateAreaStart2.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
  let fEnd = DateTime.fromJSDate(
      new Date(dateAreaEnd2.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");       
      getDataforAreaChart(data.consumerUnitId, fStart, fEnd);             
  }, [dateToCallApiForChartArea2]);

  useEffect(() => {
    setAreaData2(areaData)        
  }, [areaData]);
  
  const handdleClickComparasion = () => {      
    setComparasion(true);    
  };

  const handdleClickComparasionClose = () => {     
    setComparasion(false);
  }
      
  if (comparasion){
    return (
      <Dialog
        open={modalChartFullscreenArea}
        onClose={() => setModalChartFullscreenArea(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth={true}
        fullScreen={true}
      >
        <Box sx={{ 
          //backgroundImage: `url(${lampada})`,
          height: "100vh",
          backgroundColor: "#D1DCE1 !important",
          
          //opacity:"0.6"

          }}>
          
          <Box sx={{ padding: "0"}}>
            <DialogTitle id="alert-dialog-title">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="subtitle1" sx={{
                    backgroundColor: "none",
                    borderRadius: 1,
                  }}>{formatAreaDate.start}</Typography>
                </Box>
                <Box display="flex" sx={{ gap: "1.5rem" }}>
                <Box sx={{ marginTop: "-0.7rem" }} >
                  <CustomButton type="button" title="Fechar Comparação" className="smaller" onClick={() => handdleClickComparasionClose()}/>
                </Box>
                  <DatePickerCalendarIcon1/>
                  <FullscreenExitIcon
                    style={{
                      color: "#0f85ca",
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalChartFullscreenArea(false)} 
                  />
                </Box>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box 
                  sx={{
                    minHeight: "150px",
                    minWidth: "350px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(220, 224, 233, 0.845)",
                    borderRadius: 1.5,
                  }}
                >
                  <AreaChartComp width={1820} height={810} data={areaData1} chartSizeType={'fullscreen'} />
                </Box>
              </Box>
            </DialogContent>
          
          </Box>
          <Box sx={{ padding: "0" }}>
            <DialogTitle id="alert-dialog-title">
              <Box display="flex" justifyContent="space-between" >
                <Box >
                  <Typography variant="subtitle1"sx={{
                    backgroundColor: "none",
                    borderRadius: 1,

                  }}>{formatAreaDate2.start}</Typography>
                </Box>
                <Box display="flex" sx={{ gap: "1.5rem" }}>
                  <DatePickerCalendarIcon2/>
                </Box>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                  sx={{
                    minHeight: "150px",
                    minWidth: "350px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(220, 224, 233, 0.845)",
                    borderRadius: 1.5,
                  }}
                >
                  <AreaChartComp width={1820} height={810} data={areaData2} chartSizeType={'fullscreen'}/>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    );
  }else{
    return (
      <Dialog
        open={modalChartFullscreenArea}
        onClose={() => setModalChartFullscreenArea(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth={true}
        fullScreen={true}
      >
        <Box sx={{ padding: "0",height: "100vh",//backgroundImage: `url(${lampada})`,
          //height: "3900px",
          backgroundColor: "#D1DCE1 !important",}}>
          <DialogTitle id="alert-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle1" sx={{
                    backgroundColor: "none",
                    borderRadius: 1,
                  }} >{formatAreaDate.start}</Typography>
              </Box>
              <Box display="flex" sx={{ gap: "1.5rem" }}>
              <Box sx={{ marginTop: "-0.28rem" }} >                           
                <CustomButton onClick={() => handdleClickComparasion()} type="button" title="Comparar Dias" className="smaller"/>
              </Box>
                <DatePickerCalendarIcon1/>
                <FullscreenExitIcon
                  style={{
                    color: "#0f85ca",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalChartFullscreenArea(false)}
                />
              </Box>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                sx={{
                  height: "70vh",
                  width: "90vw",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(220, 224, 233, 0.845)",
                  borderRadius: 1.5,
                }}
              >              
                <AreaChart width={1820} height={810} data={areaData1} chartSizeType={'fullscreensolo'}
               />
              </Box>
            </Box>
          </DialogContent>                
        </Box>
      </Dialog>
    );
  }
}





function CardBar({ data, typeChart }: any) {
  const {
    modalChartFullscreenBar,
    setModalChartFullscreenBar,
    setModalChartFullscreenArea,
    setModalChartFullscreenPie,
  } = useModalContext();
  const { dateToCallApiForChartBarRange } = useStateContext();
  const { reportEquipmentForDaysList, getReportEquipmentForDays } =
    useReportContext();

  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [dataChartEquipmentList, setDataChartEquipmentList] = useState([]);
  const [dataChartEquipmentSelect, setDataChartEquipmentSelect] = useState("");
  const [dataChartEquipmentSelected, setDataChartEquipmentSelected] = useState();

  useEffect(() => {
    if (Object.keys(dateToCallApiForChartBarRange).length > 0) {
      let dateAreaStart = dateToCallApiForChartBarRange.start;
      let dateAreaEnd = dateToCallApiForChartBarRange.end;
      let formatAreaDateStart = DateTime.fromJSDate(new Date(dateAreaStart))
        .setLocale("GTM")
        .toLocaleString({ month: "long", day: "numeric" });
      let formatAreaDateEnd = DateTime.fromJSDate(new Date(dateAreaEnd))
        .setLocale("GTM")
        .toLocaleString({ month: "long", day: "numeric" });
      setDateStart(formatAreaDateStart);
      setDateEnd(formatAreaDateEnd);
    }
  }, [dateToCallApiForChartBarRange]);

  useEffect(() => {
    if (data) {
      setDataChartEquipmentList(data.equipmentConsumptions);
      if (data.equipmentConsumptions.length > 0) {
        setDataChartEquipmentSelect(data.equipmentConsumptions[0].id);
      }
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    dataChartEquipmentList.forEach((item: any) => {
      if (item.id === dataChartEquipmentSelect) {
        setDataChartEquipmentSelected(item);
      }
    });
  }, [dataChartEquipmentSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setModalChartFullscreenPie(false);
    setModalChartFullscreenArea(false);
  }, [modalChartFullscreenBar]); // eslint-disable-line

  useEffect(() => {
    let formatStart = DateTime.fromJSDate(
      new Date(dateToCallApiForChartBarRange.start)
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
    let formatEnd = DateTime.fromJSDate(
      new Date(dateToCallApiForChartBarRange.end)
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");

    if (dataChartEquipmentSelect) {
      getReportEquipmentForDays(
        dataChartEquipmentSelect,
        formatStart,
        formatEnd
      );
    }
  }, [dateToCallApiForChartBarRange, dataChartEquipmentSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDataChartEquipmentSelected(undefined);
    if (reportEquipmentForDaysList) {
      setDataChartEquipmentSelected(reportEquipmentForDaysList);
    }
  }, [reportEquipmentForDaysList]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: any) => {
    setDataChartEquipmentSelect(event.target.value);
  };

  return (
    <Dialog
      open={modalChartFullscreenBar}
      onClose={() => setModalChartFullscreenBar(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      fullWidth={true}
      fullScreen={true}
    >
      <Box sx={{ padding: "0",height: "100vh",//backgroundImage: `url(${lampada})`,
          //height: "3900px",
          backgroundColor: "#D1DCE1 !important",}}>
        <DialogTitle id="alert-dialog-title"
        sx={{
                
                backgroundColor: "rgba(220, 224, 233, 0.7)",
                borderRadius: 0,
                
                  
              }}>
          <Box display="flex" justifyContent="space-between" width="90vw">
            <Box>
              <Typography variant="h6"></Typography>
              <Typography variant="subtitle1"sx={{
                    backgroundColor: "none",
                    borderRadius: 1,
                    width: "20vw",
                    margin: "0 2rem 0 0"
                    
                  }}>
                {dateStart} - {dateEnd}
              </Typography>
            </Box>
            <Box sx={{ width:"30rem" }}>
              <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label"
                sx={{ color:"black", }}>
                  Escolher equipamento
                </InputLabel>
                <Select
                sx={{ color:"grey", backgroundColor:"none", width: "20vw" }}
                  defaultValue={dataChartEquipmentSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dataChartEquipmentSelect}
                  label="Escolher equipamento"
                  onChange={handleChange}
                  
                >
                  {dataChartEquipmentList.map((equipment: any, index: number) => (
                    <MenuItem key={index} value={equipment.id}>
                      {equipment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl >
            </Box>
            <Box className={styles.containerIcon}>
              <DatePickerCalendarRange typeChart={typeChart} />
              <FullscreenExitIcon
                style={{
                  color: "#0f85ca",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                onClick={() => setModalChartFullscreenBar(false)}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
          <Box
              sx={{
                height: "50vh",
                width: "90vw",
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgba(220, 224, 233, 0.845)",
                  borderRadius: 1.5,
              }}
            >
              <BarChart
                width={1420}
                height={810}
                data={dataChartEquipmentSelected}
                chartSizeType={'fullscreen'}
                typeFormatDate="days"
                
                
              />
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}