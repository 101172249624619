import api from './api'
import { User } from '../util/types'

export function getUserAll() {
  return api.get(`/api/User`)
}

export function getUserById(id: string) {
  return api.get(`/api/User/${id}`)
}

export function postUser(user: User) {
  return api.post('/api/User', user)
}

export function putUser(user: any, id: string) {
  return api.put(`/api/User/${id}`, user)
}

export function delUser(id: string) {
  return api.delete(`/api/User/${id}`)
}
