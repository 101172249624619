import { useLocation, Navigate } from 'react-router-dom'
import { useAuth } from '../providers/authContext'

type props = {
  roles: Array<string>
  children: JSX.Element
}

export function PrivateRoute({ children, roles }: props) {
  const { isAuthenticated } = useAuth()
  let location = useLocation();
  let currentRole = localStorage.getItem("role");
  let isAccessToken = localStorage.getItem("accessToken") !== null;


  if (!isAccessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const userHasRequiredRole = currentRole && roles.includes(currentRole) ? true : false; // eslint-disable-line eqeqeq

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
}
