import { useNavigate } from "react-router-dom"

import Logo from '../../assets/image/deep-ambiente-logo.svg'
import ResetSuccess from '../../assets/image/sucess-reset.png'
import CustomButton from "../../components/Form/button"
import styles from './styles.module.scss'

export default function ResetSuccessPassword() {
  const navigate = useNavigate()

  return (
    <main className={styles.container}>
      <div className={styles.ContentBox}>
        <div className={styles.header}>
          <div className={styles.contentHeader}>
            <div className={styles.containerImage}>
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={140}
                height={90}
              />
            </div>
            <h3 className={styles.titleHeader}>Redefinir senha</h3>
            <div />
          </div>
        </div>
        <div className={styles.dividerHorizontal} />
        <div className={styles.Content}>
          <img
            src={ResetSuccess}
            alt="Redifinir senha"
            width={153}
            height={147}
          />
          <div className={styles.textContainer}>
            <h2 className={styles.title}>Senha alterada com sucesso!</h2>
          </div>
        </div>
        <div className={styles.dividerHorizontal} />
        <div className={styles.positionButton}>
          <CustomButton title="Ir para login" className="large" onClick={() => navigate('/')} />
        </div>
      </div>
    </main>
  )
}
