import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "../../components/Form/button";
import { ModalConsumerUnitRegistration } from "../../components/Modal/ConsumerUnitRegistration";
import { ModalEditConsumerUnit } from "../../components/Modal/EditConsumerUnit";
import { ConnectSmartMeter } from "../../components/Modal/ConnectSmartMeter";
import IconConsumerUnit from "../../assets/image/icon-consumer-unit.png";
import styles from "./styles.module.scss";
import { useModalContext } from "../../providers/modalContext";
import { useStateContext } from "../../providers/stateContext";
import { useConsumerUnitContext } from "../../providers/consumerUnitContext";
import { DeleteItemModal } from "../../components/Modal/DeleteItem";
import { DesassociatedItemModal } from "../../components/Modal/DisconnectMeter";
import { useMeterContext } from "../../providers/meterContext";
import { CircularProgress } from "@mui/material";

export default function ConsumerUnit() {
  const [consumerUnitEdit, setConsumerUnitEdit] = useState();
  const [consumerIdPros, setConsumerIdPros] = useState();
  const [idConsumerSelect, setIdConsumerSelect] = useState<number>(0);
  const [consumerUnitName, setConsumerUnitName] = useState<string>("");
  const [consumerFilter, setConsumerFilter] = useState<Array<any>>([] as any);

  const {
    setModalConsumerUnitRegistration,
    setModalEditConsumerUnit,
    setConnectSmartMeter,
    setModalDelete,
    setModalDesassociated,
  } = useModalContext();

  const {
    deleteConsumer,
    consumerUnitList,
  } = useConsumerUnitContext();

  const { desassociateSmartMeter, getMeterList } = useMeterContext();
  const { setSearchItem, searchItem, isGetFetching } = useStateContext();

  const role = localStorage.getItem("role");

  const navigate = useNavigate();

  useEffect(() => {
    if (consumerUnitList.length > 0) {
      setConsumerFilter(consumerUnitList);
    }
  }, [consumerUnitList]) // eslint-disable-line

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role !== "Cliente") {
      getMeterList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setConsumerFilter(searchConsumerUnit())
  }, [searchItem]) // eslint-disable-line react-hooks/exhaustive-deps

  const searchConsumerUnit = () => {
    const lowerCaseSearch = searchItem.toLowerCase();
    let fil
    fil = consumerUnitList.filter((item) => {
      if (item.placeName.toLowerCase().includes(lowerCaseSearch)) {
        return item
      }
    });
    return fil
  };

  const handleDeleteItem = (id: number, name: string) => {
    setIdConsumerSelect(id);
    setConsumerUnitName(name);
    setModalDelete(true);
  };

  const handleConsumerUnitRegistrations = () => {
    setModalConsumerUnitRegistration(true);
  };

  const handleEditConsumerUnit = (unit: any) => {
    setConsumerUnitEdit(unit);
    setModalEditConsumerUnit(true);
  };

  const handleConnectSmartMeter = (unit: any) => {
    setConsumerIdPros(unit.id);
    setConnectSmartMeter(true);
  };

  const handleDisconnectSmartMeter = (id: number, name: string) => {
    setIdConsumerSelect(id);
    setConsumerUnitName(name);
    setModalDesassociated(true);
  };

  const handleSeeMonitoring = (unit: any) => {
    navigate({
      pathname: "/dashboard",
      search: `?consumerUnitId=${unit.id}`,
    });
  };

  const handleSeeEquipmentList = (unit: any) => {
    navigate({
      pathname: "/dashboard/equipmentManagement",
      search: `?consumerUnitId=${unit.id}`,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <main className={styles.mainContainer}>
          <header className={styles.header}>
            <div className={styles.topHeader}>
              <h2>Unidade Consumidora</h2>
              <div className={styles.actionHeaderContainer}>
                <div className={styles.searchUser}>
                  <input
                    type="text"
                    placeholder="Pesquisar"
                    className={styles.Input}
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>
                <button onClick={handleConsumerUnitRegistrations}>
                  CADASTRAR UNIDADE CONSUMIDORA
                </button>
              </div>
            </div>
          </header>
          <div className={styles.filterContainer}>
            <h3>Lista de Unidades</h3>
          </div>
          {isGetFetching ? (
            <div className={styles.LoadingScreen}>
              <CircularProgress />
            </div>
          ) : (
            <section className={styles.section}>
              {consumerFilter.length !== 0 ? (
                <div className={styles.itemContainer}>
                  <div className={styles.titletable}>
                    <div className={styles.contentHeader}>
                      <h5>Unidade Consumidora</h5>
                    </div>
                    <div className={styles.contentHeader}>
                      <h5>Equipamentos Monitorados</h5>
                    </div>
                    <div className={styles.contentHeader}>
                      <h5>Medidor associado</h5>
                    </div>
                  </div>
                  {consumerFilter.map((unit: any) => {
                    function getAssociatedEquipment(): string {
                      let name: string;
                      if (unit.equipments.length === 0) {
                        name = "Não há equipamento associado";
                      } else if (unit.equipments.length === 1) {
                        name = "Equipamento Associado";
                      } else {
                        name = "Equipamentos Associados";
                      }
                      return name;
                    }

                    const AssocietedEquipment = getAssociatedEquipment();

                    return (
                      <div key={unit.id}>
                        <div className={styles.mainContent}>
                          <div className={styles.contentBox}>
                            <div className={styles.contentItem}>
                              <div className={styles.circleIcon}>
                                <img
                                  src={IconConsumerUnit}
                                  alt="icone Gerenciamento de equipamentos"
                                  width={18}
                                  height={18}
                                />
                              </div>
                              <div className={styles.contentText}>
                                <h5>
                                  {unit.placeName} - {unit.state}
                                </h5 >
                                <div className={styles.address}>
                                  <p>
                                    {unit.street} / n°: {unit.number}
                                  </p>
                                
                                <p>Cep: {unit.zipCode}</p>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.contentBox2}>
                            <div className={styles.contentItem}>
                              <div className={styles.CircleNumber}>
                                {unit.equipments.length}
                              </div>
                              <div className={styles.contentText}>
                                <h4>{AssocietedEquipment}</h4>
                                <span
                                  className={styles.link}
                                  onClick={() => handleSeeEquipmentList(unit)}
                                >
                                  Ver lista
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={styles.contentBox2}>
                            <div className={styles.contentItemMeter}>
                              {unit.mac ? (
                                <div className={styles.contentText}>
                                  <h4>{unit.mac.name}</h4>
                                </div>
                              ) : (
                                <div className={styles.contentText}>
                                  <h4>Não associado</h4>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.contentBox3}>
                            <div className={styles.contentItem}>
                              <button
                                onClick={() => handleEditConsumerUnit(unit)}
                              >
                                <EditIcon />
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteItem(unit.id, unit.placeName)
                                }
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className={styles.containerButtons}>
                          {role !== "Cliente" ? (
                            unit.mac ? (
                              <CustomButton
                                type="button"
                                title="Desassociar Medidor"
                                className="middleOutLineRed"
                                onClick={() =>
                                  handleDisconnectSmartMeter(
                                    unit.id,
                                    unit.placeName
                                  )
                                }
                              />
                            ) : (
                              <CustomButton
                                type="button"
                                title="Associar Medidor"
                                className="middleOutline"
                                onClick={() => handleConnectSmartMeter(unit)}
                              />
                            )
                          ) : (
                            <div />
                          )}
                          <CustomButton
                            type="button"
                            title="Ver monitoramento"
                            className={"middle"}
                            onClick={() => handleSeeMonitoring(unit)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={styles.noConsumerUnit}>
                  <span>
                    Não conseguimos encontrar nenhuma unidade consumidora
                    cadastra, para cadastrar basta clicar no botão acima!
                  </span>
                </div>
              )}
            </section>
          )}
        </main>
      </div>
      <DeleteItemModal
        id={idConsumerSelect}
        name={consumerUnitName}
        functionDelete={() => deleteConsumer(idConsumerSelect)}
      />
      <DesassociatedItemModal
        id={idConsumerSelect}
        title={`Desassociar medidor de ${consumerUnitName}`}
        functionDesassociated={() => desassociateSmartMeter(idConsumerSelect)}
      />
      <ModalConsumerUnitRegistration />
      {consumerUnitEdit ? (
        <ModalEditConsumerUnit consumerUnitEdit={consumerUnitEdit} />
      ) : (
        <div />
      )}
      {consumerIdPros ? (
        <ConnectSmartMeter consumerUnitId={consumerIdPros} />
      ) : (
        <div />
      )}
    </div>
  );
}
