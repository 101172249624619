import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { EquipmentCategory } from "../../components/Tabs/ModelManagement/Category";
import { Model } from "../../components/Tabs/ModelManagement/Models";
import { BusinessType } from "../../components/Tabs/ModelManagement/BusinessType";
import { CategoryRegistration } from "../../components/Modal/CategoryRegistration";
import { BusinessTypeRegistration } from "../../components/Modal/BusinessTypeRegistration";
import { ModalModelRegistration } from "../../components/Modal/ModelRegistration";

import { useModalContext } from "../../providers/modalContext";
import styles from "./styles.module.scss";
import { useStateContext } from "../../providers/stateContext";
import { useModelContext } from "../../providers/modelContext";
import { CustomSnackBar } from "../../components/Snackbar";
import { CircularProgress } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ModelManagement() {

  const isScreenWidthAbove520 = useMediaQuery("(min-width:520px)");

  const [value, setValue] = useState(0);
  const {
    setModalBusinessTypeRegistration,
    setModalModelRegistration,
    setModalSubCategoryRegistration,
  } = useModalContext();
  const { isFetching, setIsLoading, setShowSelectAppBar, setSearchItem } =
    useStateContext();
  const { getModelList, getCategoryList, getBusinessTypeList } =
    useModelContext();

  useEffect(() => {
    async function getAllModelCategoryAndBusinessType() {
      setIsLoading(true);

      getModelList();
      getCategoryList();
      getBusinessTypeList();

      setIsLoading(false);
    }

    getAllModelCategoryAndBusinessType();
    setShowSelectAppBar(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenModalRegistrationModel = () => {
    setModalModelRegistration(true);
  };
  const handleOpenModalBusinessTypeRegistration = () => {
    setModalBusinessTypeRegistration(true);
  };
  const handleOpenModalSubCategoryRegistration = () => {
    setModalSubCategoryRegistration(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <main className={styles.mainContainer}>
          <Box sx={{ width: "100%" }}>
            <header className={styles.header}>
              <div className={styles.topHeader}>
                <h2>Gerenciamento de Modelo</h2>
                  <Box className={styles.tabsPosition} sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      className={styles.tabsPositionTab}
                      orientation={isScreenWidthAbove520 ? "horizontal" : "vertical"}
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
>
                      <Tab label="Modelos" {...a11yProps(0)} />
                      <Tab label="Categorias" {...a11yProps(1)} />
                      <Tab className={styles.tabs2} label="Tipo de Negócio" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                </div>

            </header>

            <TabPanel value={value} index={0}>
              <div className={styles.searchAndAddContainer}>
                <input
                  type="text"
                  placeholder="Pesquisar"
                  className={styles.Input}
                  onChange={(e) => setSearchItem(e.target.value)}
                />

                <button onClick={() => handleOpenModalRegistrationModel()}>
                  Cadastrar Modelo
                </button>
              </div>
              <div className={styles.contentTitle}>
                <h3>Lista de Modelos</h3>
              </div>
              {isFetching ? (
                <div className={styles.LoadingScreen}>
                  <CircularProgress />
                </div>
              ) : (
                <Model />
              )}
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className={styles.searchAndAddContainer}>
                <div />
                <button
                  onClick={() => handleOpenModalSubCategoryRegistration()}
                >
                  Cadastrar Categoria
                </button>
              </div>
              <div className={styles.contentTitle}>
                <h3>Lista de Categorias</h3>
              </div>
              {isFetching ? (
                <div className={styles.LoadingScreen}>
                  <CircularProgress />
                </div>
              ) : (
                <EquipmentCategory />
              )}
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div className={styles.searchAndAddContainer}>
                <div />
                <button
                  onClick={() => handleOpenModalBusinessTypeRegistration()}
                >
                  Cadastrar Tipo de Negócio
                </button>
              </div>
              <div className={styles.contentTitle}>
                <h3>Lista de Tipo de Negócio</h3>
              </div>
              {isFetching ? (
                <div className={styles.LoadingScreen}>
                  <CircularProgress />
                </div>
              ) : (
                <BusinessType />
              )}
            </TabPanel>
          </Box>
        </main>

        <ModalModelRegistration />
        <CategoryRegistration />
        <BusinessTypeRegistration />

        <CustomSnackBar />
      </div>
    </div>
  );
}
