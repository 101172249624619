import api from './api'
import { Auth } from '../util/types'

//Auth rest
export function signIn(data: Auth) {
  return api.post('/api/Auth/signin', data)
}

export function postForgotPassword(email: any) {
  return api.post('/api/Auth/forgot-password', {
    email: email
  })
}

export function postRefreshPassword(rest: any) {
  return api.post('/api/Auth/reset-password', rest)
}

export function postConfirmEmail(confirmEmail: any) {
  return api.post('/api/Auth/confirm-email', confirmEmail)
}

export function postGenerateConfirmationEmail(login:any) {
  return api.post('/api/Auth/generate-confirmation-email', login)
}

export function refresh (objTokens: any) {
  return api.post('/api/Auth/refresh', objTokens)
}

export function logoutUser() {
  return api.get('/api/Auth/revoke')
}
// Mock
export async function getMock(period: Object) {
  return api.post('/api/mock', period)
}

export async function getMockByEquipmentId(id: number, period: Object) {
  return api.post(`/api/mock/${id}`, period)
}

export async function getMockByEquipmentIdForHours(id: number, hours: number) {
  return api.post(`/api/mock/${id}/${hours}`)
}

export async function getMockByIdToConsumerUnit(id: number, period: Object) {
  return api.post(`/api/mock/${id}/ConsumerUnit`, period)
}

export async function getMockByIdToConsumerUnitForHours(id: number, hours: number) {
  return api.post(`/api/mock/${id}/ConsumerUnit/${hours}`)
}