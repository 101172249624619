import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";

const CustomSelect = styled(Select)({
  marginTop: "0rem",
  "& input:valid + fieldset": {
    borderColor: "#C8CBCC",
    borderWidth: 1.5,
    fontSize: "1rem",
  },
  "& input:valid:hover + fieldset": {
    borderColor: "#C8CBCC",
    borderWidth: 1.5,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderWidth: 2,
    borderColor: "#0f85ca",
    padding: "4px !important", // override inline-style
  },
});

export default CustomSelect;
