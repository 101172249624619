import { useEffect, useState } from 'react';

import lampada from "../../assets/image/lampada.jpg";

import { useSearchParams, useLocation } from "react-router-dom";
import { Box, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { styled } from "@mui/material/styles";
import Select from 'react-select';
import { useAuth } from '../../providers/authContext';
import { useStateContext } from '../../providers/stateContext';
import { useConsumerUnitContext } from '../../providers/consumerUnitContext';

import styles from './styles.module.scss'
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type selectType = {
  value: string | number;
  label: string;
}

const drawerWidth = 250;

export default function Header() {
  const { dataUser } = useAuth()
  const {
    setSelectConsumerUnitId,
    showSelectAppBar,
    setShowSelectAppBar,
  } = useStateContext();
  const { pickerData } = useConsumerUnitContext();
  const [defaultValueSelect, setDefaultValueSelect] = useState<selectType>()
  const [espera, setEspera] = useState(true);

  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const paramsConsumerUnitId = searchParams.get('consumerUnitId')

  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard':
        setShowSelectAppBar(true)
        break;
      case '/dashboard/equipmentManagement':
        setShowSelectAppBar(true)
        break;
      case '/dashboard/modelManagement':
        setShowSelectAppBar(true)
        break;
      default:
        setShowSelectAppBar(false)
    }
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialSelectValue()
    if (pickerData.length > 0) {
      pickerData.forEach((item: any) => {
        if (paramsConsumerUnitId !== null) {
          let parsedParamsConsumerUnitId = parseInt(paramsConsumerUnitId)
          if (item.value === parsedParamsConsumerUnitId) {
            setDefaultValueSelect({
              value: item.value,
              label: item.label,
            })
          }
        }
      })

      if (defaultValueSelect === undefined) {
        if (paramsConsumerUnitId !== null) {
          pickerData.forEach((item: any) => {
            let parsedParamsConsumerUnitId = parseInt(paramsConsumerUnitId)
            if (item.value === parsedParamsConsumerUnitId) {
              setDefaultValueSelect({
                value: item.value,
                label: item.label,
              })
              setSelectConsumerUnitId(item.value)
            }
          })
        } else {
          setDefaultValueSelect({
            value: pickerData[0].value,
            label: pickerData[0].label,
          })
          setSelectConsumerUnitId(pickerData[0].value)
        }
      }
    }
  }, [pickerData, paramsConsumerUnitId]) // eslint-disable-line

  useEffect(() => {
    if (pickerData.length > 0 && Object.keys(dataUser).length !== 0) {
      setEspera(false)
    }
  }, [pickerData, dataUser])

  const setInitialSelectValue = () => {
    if (paramsConsumerUnitId) {
      setSelectConsumerUnitId(parseInt(paramsConsumerUnitId))
    }
  }

  const handleChangeSelect = (event: any) => {
    setSearchParams({
      consumerUnitId: event.value
    })

    setSelectConsumerUnitId(event.value)
  }

  if (espera) {
    return null
  } else {
    return (
      <AppBar className={styles.bg}  //passar uma clase e jogar no css - @media background
        position="relative"
        
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "1.25rem",
          }}
        >
          

            {/*tive que fazer modificacoes aqui para aparecer o select em todos os links do sidemenu*/}

            <div className={styles.select}>
              
                
                  <div>
                    <Select
                      defaultValue={defaultValueSelect}
                      onChange={(e) => handleChangeSelect(e)}
                      options={pickerData}
                      styles={customStyles}
                    />
                  </div>
                  </div>
                 
             {/* fim do tive que fazer modificacoes aqui para aparecer o select em todos os links do sidemenu*/}
          
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              mr: "1rem",
            }}
          >
            <div className={styles.circleBlue}>
              <PersonSharpIcon className={styles.iconColorWhite} />
            </div>
            <div className={styles.userInfo}>
              <h3>{dataUser.fullName}</h3>
              <h5>{dataUser.email}</h5>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    )
  }
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }: any) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    //borderBottom: '1px dotted red',
    color: state.isSelected ? '#30313D' : '#30313D',
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 250,
    display: 'flex',
    color: ' #30313D',
    border: '1px solid #30313D',
    borderRadius: 5,
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.2 : 1;
    const transition = 'opacity 300ms';
    const color = '#30313D';

    return { ...provided, opacity, transition, color };
  }
}