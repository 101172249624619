import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AreaChart from "../../Charts/AreaChart";
import BarChart from "../../Charts/BarChart";
import PieChart from "../../Charts/PieChart";

import Slider from '@mui/material/Slider';
import { DateTime } from 'luxon';


import TensaoChart from "../../Charts/TensaoChart";
import CorrenteChart from "../../Charts/CorrenteChart"
import DemandaChart from "../../Charts/DemandaChart";
import EnergiaChart from "../../Charts/Energia";
import PotenciaChart from "../../Charts/PotenciaChart";
import FatorPotenciaChart from "../../Charts/FatorPotenciaChart";
import TemperaturaChart from "../../Charts/Temperatura";
import styles from "./styles.module.scss";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import BoltIcon from '@mui/icons-material/Bolt';

import { DatePickerCalendarIcon, DatePickerCalendarIcon2  } from "../../Form/DatePicker";
import { useEffect, useState } from "react";
import { useStateContext } from "../../../providers/stateContext";
import { useReportContext } from "../../../providers/reportContext";
import { useModalContext } from "../../../providers/modalContext";
import ChartFullScreen from "../../Modal/ChartFullScreen";
import useFormatDate from "../../../util/hooks/useFormartDate";

export default function ChartsCard({ charts, title, stylesCard, dataChart, setSelectedID, selectedID }) {
  const [dataChartEquipmentSelected, setDataChartEquipmentSelected] = useState([]);
  const [typeChartModel, setTypeChartModel] = useState("");
  const {
    setModalChartFullscreenArea,
    setModalChartFullscreenLine,
    setModalChartFullscreenBar,
    setModalChartFullscreenPie,
  } = useModalContext();
  const [dataChartEquipmentList, setDataChartEquipmentList] = useState([]);
  const [dataChartEquipmentSelect, setDataChartEquipmentSelect] = useState("");
  const {
    dateToCallApiForChartBar,
    dateToCallApiForChartArea,
    dateToCallApiForChartPie,
  } = useStateContext();
  const {
    reportEquipmentForHoursList,
    getReportEquipmentForHours,
    loadingChartPie,
  } = useReportContext();
  const { totalConsumption, totalEquipmentConsumptionPrice, totalSmartMeterConsumption } = dataChart;  
  const formatDate = useFormatDate()




const generateColors = (equipmentId, equipmentList) => {
  const colorsSet = ['#e74c3c', '#5faae7', '#e67f22','#31cb6f', '#f2c600', '#edab27'];

  // Find the index of the equipment in the equipmentList
  const equipmentIndex = equipmentList.findIndex(item => item.id === equipmentId);

  // If the equipment is not found in the list, use the first color
  if (equipmentIndex === -1) {
    return Array(dataChartEquipmentSelected.length).fill(colorsSet[0]);
  }

  // Use the equipment index to pick a color from the colorsSet
  const color = colorsSet[equipmentIndex % colorsSet.length];

  // Return an array filled with the selected color
  return Array(dataChartEquipmentSelected.length).fill(color);
};





const [sliderValues, setSliderValues] = useState([0, 24]);

  const now = DateTime.now();
  const initialStartTime = now.startOf('day').toJSDate();
  const initialEndTime = now.endOf('day').toJSDate();

  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);

  const handleSliderChange = (event, newValues) => {
  const originalDate = DateTime.fromJSDate(startTime).startOf('day');

  const newStartHour = newValues[0];
  const newEndHour = newValues[1] >= 24 ? 23 : newValues[1];

  const newStartTime = originalDate
    .set({
      hour: newStartHour,
      minute: 0,
      second: 0,
    })
    .toJSDate();

  const newEndTime = originalDate
    .set({
      hour: newEndHour,
      minute: newEndHour === 23 ? 59 : 0,
      second: newEndHour === 23 ? 59 : 0,
    })
    .toJSDate();

  console.log("Slider values changed: ", newValues);
  console.log("New start time and end time: ", newStartTime, newEndTime);

  setStartTime(newStartTime);
  setEndTime(newEndTime);
  setSliderValues(newValues);
};


  const handleDateChange = (date) => {
  const originalDate = DateTime.fromJSDate(date).startOf('day');
  
  const newStartHour = sliderValues[0];
  const newEndHour = sliderValues[1] >= 24 ? 23 : sliderValues[1];
  
  const newStartTime = originalDate.set({ 
    hour: newStartHour, // Add 3 hours for Brazilian time
    minute: 0, 
    second: 0 
  }).toJSDate();
  
  const newEndTime = originalDate.set({ 
    hour: newEndHour, // Add 3 hours for Brazilian time
    minute: newEndHour === 23 ? 59 : 0, 
    second: newEndHour === 23 ? 59 : 0 
  }).toJSDate();

  console.log("Date changed: ", date);
  console.log("New start time and end time: ", newStartTime, newEndTime);

  setStartTime(newStartTime);
  setEndTime(newEndTime);
};


  useEffect(() => {
    if (dataChartEquipmentSelect) {
      getReportEquipmentForHours(dataChartEquipmentSelect, startTime, endTime);
    }
  }, [dataChartEquipmentSelect, startTime, endTime]);


const handleChange = (event) => {
  setDataChartEquipmentSelect(event.target.value);
  
  if(charts === "pie") {
    getReportEquipmentForHours(event.target.value, startTime, endTime);
  } else {
    const originalDate = DateTime.fromJSDate(startTime).startOf('day');
  
    const newStartTime = originalDate.set({ 
      hour: 0, 
      minute: 0, 
      second: 0 
    }).toJSDate();
    
    const newEndTime = originalDate.set({ 
      hour: 23, 
      minute: 59, 
      second: 59 
    }).toJSDate();
    
    console.log("Data chart equipment selection changed: ", event.target.value);

    getReportEquipmentForHours(event.target.value, newStartTime, newEndTime);
  }
};



  useEffect(() => {
    if (charts === "bar" && dataChart) {
      console.log("Data chart has changed, new equipment list: ", dataChart.equipmentConsumptions);
      setDataChartEquipmentList(dataChart.equipmentConsumptions);
      if (dataChart.equipmentConsumptions.length > 0) {
        setDataChartEquipmentSelect(dataChart.equipmentConsumptions[0].id);
      }
    }
  }, [dataChart]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dataChartEquipmentList.forEach((item) => {
      console.log("Data chart equipment selection changed: ", dataChartEquipmentSelect);
      if (item.id === dataChartEquipmentSelect) {
        setDataChartEquipmentSelected(item);
      }
    });
  }, [dataChartEquipmentSelect]); // eslint-disable-line react-hooks/exhaustive-deps




  useEffect(() => {
    if (dataChartEquipmentSelect) {
      console.log("dateToCallApiForChartBar or dataChartEquipmentSelect has changed: ", dateToCallApiForChartBar, dataChartEquipmentSelect);
      getReportEquipmentForHours(
        dataChartEquipmentSelect,
        dateToCallApiForChartBar.start,
        dateToCallApiForChartBar.end
        
      );
    }
  }, [dateToCallApiForChartBar, dataChartEquipmentSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("reportEquipmentForHoursList has changed: ", reportEquipmentForHoursList);
    setDataChartEquipmentSelected(undefined);
    if (reportEquipmentForHoursList) {
      setDataChartEquipmentSelected(reportEquipmentForHoursList);
    }
  }, [reportEquipmentForHoursList]); // eslint-disable-line react-hooks/exhaustive-deps


   



  

  function checkStyleCard(stylesCard) {
    switch (stylesCard) {
      case "small":
        return styles.smallCard;
      case "middle":
        return styles.middleCard;
      case "large":
        return styles.largeCard;
      case "largeTecnico":
        return styles.largeCardTecnico;
      default:
        return null;
    }
  }


  function checkCharts(charts) {
    if (dataChart) {
      switch (charts) {
        case "pie":
          return <PieChart data={dataChart}handleSliderChange={handleSliderChange} startTime={startTime}
    endTime={endTime} />;
        case "area":        
        return <AreaChart width={1520} height={710} data={dataChart} chartSizeType={'normal'}/>;
case "line":        
        return <TensaoChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID} />;
case "line2":        
        return <CorrenteChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID}/>;
case "line3":        
        return <DemandaChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID}/>;
case "line4":        
        return <EnergiaChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID}/>;
case "line5":        
        return <PotenciaChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID}/>;
case "line6":        
        return <FatorPotenciaChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID}/>;
  case "line7":        
        return <TemperaturaChart chartSizeType={'normal'} selectedID={selectedID} 
  setSelectedID={setSelectedID}/>;

      


        case "bar":
          return (
            dataChartEquipmentSelected && (
              <BarChart
  width={1500}
  height={350}
  data={dataChartEquipmentSelected}
  typeFormatDate="hours"
  chartSizeType={"normal"}
  colors={generateColors(dataChartEquipmentSelect, dataChartEquipmentList)}
  selectedEquipmentId={dataChartEquipmentSelect}
  
/>
            )
            
          );
        default:
          return null;
      }
    }
  }

  function DateForChart(charts) {
    switch (charts) {
      case "pie":
        let dateStart = new Date(dateToCallApiForChartPie.start);
        let dateEnd = new Date(dateToCallApiForChartPie.end);
        let dtStart = DateTime.fromMillis(dateStart.getTime());
        let dtEnd = DateTime.fromMillis(dateEnd.getTime());
        let formatPieDate =
        {
          end: dtEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
          start: dtStart.toUTC().toLocaleString({ month: "long", day: "numeric" })
        }
        return <p className={styles.date}>{`${formatPieDate.start}`}</p>;
      case "area":
        let dateAreaStar = new Date(dateToCallApiForChartArea.start);
        let dateAreaEnd = new Date(dateToCallApiForChartArea.end);
        let dtAreaStart = DateTime.fromMillis(dateAreaStar.getTime());
        let dtAreaEnd = DateTime.fromMillis(dateAreaEnd.getTime());

        let formatAreaDate = {
          end: dtAreaEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
          start: dtAreaStart.toUTC().toLocaleString({ month: "long", day: "numeric" })
        }
        
        
         return <p className={styles.date}>{`${formatAreaDate.start}`}</p>;

      case "bar":
        let dateBarStart = new Date(dateToCallApiForChartBar.start);
        let dateBarEnd = new Date(dateToCallApiForChartBar.end);
        let dtBarStart = DateTime.fromMillis(dateBarStart.getTime());
        let dtBarEnd = DateTime.fromMillis(dateBarEnd.getTime());

        let formatBarDate = {
          end: dtBarEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
          start: dtBarStart.toUTC().toLocaleString({ month: "long", day: "numeric" })
        }

        return <p className={styles.date}>{`${formatBarDate.start}`}</p>;
      default:
        return null;
    }
  }

  function openModalChartFullscreen(charts) {
    switch (charts) {
      case "pie":
        setTypeChartModel("pie");
        setModalChartFullscreenPie(true);
        break;
      case "area":
        setTypeChartModel("area");
        setModalChartFullscreenArea(true);
        break;
        case "bar":
        setTypeChartModel("bar");
        setModalChartFullscreenBar(true);
        break;
        

      
      default:
        return null;
    }
  }

  return (
    <div className={checkStyleCard(stylesCard)}>
      <div className={styles.CardHeader}>
        <div className={styles.containerTitle}>
          <h3>{title}</h3>
          <h5 style={{ textTransform: "uppercase" }}>{DateForChart(charts)}</h5>
        </div>
        {charts === "bar" && dataChartEquipmentList.length > 0 ? (
          <Box sx={{ width: "20vw"}}>
            <FormControl fullWidth sx={{display: ""}}>
              <InputLabel id="demo-simple-select-label">
                Escolher equipamento
              </InputLabel>
              <Select
                defaultValue={dataChartEquipmentSelect}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dataChartEquipmentSelect}
                label="Escolher equipamento"
                onChange={handleChange}
              >
                {dataChartEquipmentList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <div />
        )}
        <div className={styles.containerIcon}>
        {(charts === "line" || charts === "line2" || charts === "line3" || charts === "line4" || charts === "line5") ? (
          <div></div>
        ) : (
          <>
            <DatePickerCalendarIcon typeChart={charts} className={styles.datePicker} handleDateChange={handleDateChange}
           
            style={{
    width: "90px",
    height: "50px",
    backgroundClolor:"red"
  }}/>
            <FullscreenIcon
              onClick={() => openModalChartFullscreen(charts)}
              className={styles.fullscreenIcon}
              style={{
                color: "#0f85ca",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
            />
          </>
        )}
      </div>
    </div>
    {charts === "pie" && (
      <div>
        <h4>Selecionar Horário:</h4>
        <Slider
          value={sliderValues}
          min={0}
          max={24}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
        />
      </div>
    )}
   
      

     
<div className={styles.Informative}>
  {(charts === "pie" || charts === "bar") && !loadingChartPie && totalConsumption > 0 ? (
    <div className={styles.totals}>
      <span>
        <strong><BoltIcon/>Consumo Total:</strong>
        {totalSmartMeterConsumption > totalConsumption ? ` ${(totalSmartMeterConsumption).toFixed(2)}` : ` ${totalConsumption.toFixed(2)}`}
        kWh
      </span>
      <span>
        <strong><BoltIcon/>Valor Estimado:</strong>
        {(totalEquipmentConsumptionPrice/totalConsumption)*(totalSmartMeterConsumption) > totalEquipmentConsumptionPrice?
        ` R$ ${((totalEquipmentConsumptionPrice/totalConsumption)*(totalSmartMeterConsumption)).toFixed(2)}`: ` R$ ${totalEquipmentConsumptionPrice.toFixed(2)}`}
      </span>
    </div>
  ) : (
    <div />
  )}
</div>





      
      <div className={styles.ChartsArea}>{checkCharts(charts)}</div>
      <ChartFullScreen dataChart={dataChart} typeChart={typeChartModel} />
    </div>
  );
}


