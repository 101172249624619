import AppRoutes from './routes'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './providers/authContext'
import { StateContextProvider } from './providers/stateContext'
import { ModalProvider } from './providers/modalContext'
import { EquipmentContextProvider } from './providers/equipmentContext'
import { ConsumerUnitContextProvider } from './providers/consumerUnitContext'
import { ModelContextProvider } from './providers/modelContext';
import { MeterContextProvider } from './providers/meterContext'
import { ReportContextProvider } from './providers/reportContext'

function App() {
  return (
    <BrowserRouter>
      <StateContextProvider>
        <EquipmentContextProvider>
          <ModelContextProvider>
            <ConsumerUnitContextProvider>
              <MeterContextProvider>
                <ReportContextProvider>
                  <ModalProvider>
                    <AuthProvider>
                      <AppRoutes />
                    </AuthProvider>
                  </ModalProvider>
                </ReportContextProvider>
              </MeterContextProvider>
            </ConsumerUnitContextProvider>
          </ModelContextProvider>
        </EquipmentContextProvider>
      </StateContextProvider>
    </BrowserRouter>
  );
}

export default App;
