function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function tooltipPosition() {
  let tooltip = 0;

  if (getWindowSize().width <= 1024) {
    tooltip = 225;
  } else if (getWindowSize().width <= 1280) {
    tooltip = 225;
  } else if (getWindowSize().width <= 1440) {
    tooltip = 250;
  } else if (getWindowSize().width <= 1920) {
    tooltip = 250;
  } else if (getWindowSize().width > 1920) {
tooltip = 250;
  }

  return tooltip;
}

function tooltipPositionY() {
  let tooltip = 0;

  if (getWindowSize().width <= 1024) {
    tooltip = 350;
  } else if (getWindowSize().width <= 1280) {
    tooltip = 350;
  } else if (getWindowSize().width <= 1440) {
    tooltip = 350;
  } else if (getWindowSize().width <= 1920) {
    tooltip = 350;
  } else if (getWindowSize().width > 1920) {
tooltip = 360;
  }
  return tooltip;
}

function responsiveWidthPieChart() {
  let widthSize = 0;

  if (getWindowSize().width <= 1024) {
    widthSize = 450;
  } else if (getWindowSize().width <= 1280) {
    widthSize = 450;
  } else if (getWindowSize().width <= 1440) {
    widthSize = 500;
  } else if (getWindowSize().width <= 1920) {
    widthSize = 500;
  } else if (getWindowSize().width > 1920) {
    widthSize = 500;
  }

  return widthSize;
}

function responsiveHeightPieChart() {
  let heightSize = 0;

  if (getWindowSize().width <= 1024) {
    heightSize = 400;
  } else if (getWindowSize().width <= 1280) {
    heightSize = 400;
  } else if (getWindowSize().width <= 1440) {
    heightSize = 400;
  } else if (getWindowSize().width <= 1920) {
    heightSize = 400;
  } else if (getWindowSize().width > 1920) {
    heightSize = 420;
  }

  return heightSize;
}

function getPositionsPieChart() {
  let positionChart = "";

  if (getWindowSize().width <= 1024) {
    positionChart = "0 0 450 650";
  } else if (getWindowSize().width <= 1280) {
    positionChart = "0 0 500 750";
  } else if (getWindowSize().width <= 1440) {
    positionChart = "0 0 500 800";
  } else if (getWindowSize().width <= 1920) {
    positionChart = "0 0 500 700";
  } else if (getWindowSize().width > 1920) {
    positionChart = "0 0 500 700";
  }

  return positionChart;
}

function legendPieChartX() {
  let legendx = 0;

  if (getWindowSize().width <= 1024) {
    legendx = 10;
  } else if (getWindowSize().width <= 1280) {
    legendx = 10;
  } else if (getWindowSize().width <= 1440) {
    legendx = 10;
  } else if (getWindowSize().width <= 1920) {
    legendx = 20;
  } else if (getWindowSize().width > 1920) {
    legendx = 45;
  }

  return legendx;
}

function legendPieChartY() {
  let legendy = 0;

  if (getWindowSize().width <= 1024) {
    legendy = 390;
  } else if (getWindowSize().width <= 1280) {
    legendy = 370;
  } else if (getWindowSize().width <= 1440) {
    legendy = 400;
  } else if (getWindowSize().width <= 1920) {
    legendy = 420;
  } else if (getWindowSize().width > 1920) {
    legendy = 430;
  }

  return legendy;
}

function fontSizeLabelsPieChart() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 22;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 25;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 20;
  } else if (getWindowSize().width > 1920) {
    fontSize = 20;
  }

  return fontSize;
}

/* AREA CHART -------------------------------------------- */

function widthAreaChart() {
  let widthArea = 0;

  if (getWindowSize().width <= 445) {
    widthArea = 1500;
  } else if (getWindowSize().width <= 1024) {
    widthArea = 1200;
  } else if (getWindowSize().width <= 1280) {
    widthArea = 1200;
  } else if (getWindowSize().width <= 1440) {
    widthArea = 1350;
  } else if (getWindowSize().width <= 1920) {
    widthArea = 1400;
  } else if (getWindowSize().width > 1920) {
    widthArea = 1400;
  }

  return widthArea;
}

function heightAreaChart() {
  let heightArea = 0;

  if (getWindowSize().width <= 1024) {
    heightArea = 1050;
  } else if (getWindowSize().width <= 1280) {
    heightArea = 1100;
  } else if (getWindowSize().width <= 1440) {
    heightArea = 1200;
  } else if (getWindowSize().width <= 1920) {
    heightArea = 1450;
    } else if (getWindowSize().width > 1920) {
      heightArea = 1250;
  }

  return heightArea;
}

function paddingChart() {
  let padding  = 0;

  if (getWindowSize().width <= 1024) {
    padding  = 120;
  } else if (getWindowSize().width <= 1280) {
    padding  = 140;
  } else if (getWindowSize().width <= 1440) {
    padding  = 140;
  } else if (getWindowSize().width <= 1920) {
    padding  = 140;
  }else if (getWindowSize().width > 1920) {
padding  = 140;
  }
  return padding ;
}

function bottomSizeAreaChart() {
  let bottomSize = 0;

  if (getWindowSize().width <= 1024) {
    bottomSize = 400;
  } else if (getWindowSize().width <= 1280) {
    bottomSize = 500;
  } else if (getWindowSize().width <= 1440) {
    bottomSize = 600;
  } else if (getWindowSize().width <= 1920) {
    bottomSize = 830;
  } else if (getWindowSize().width > 1920) {
    bottomSize = 730;
  }

  return bottomSize;
}

function topSizeAreaChart() {
  let topSize = 0;

  if (getWindowSize().width <= 1024) {
    topSize = 100;
  } else if (getWindowSize().width <= 1280) {
    topSize = 100;
  } else if (getWindowSize().width <= 1440) {
    topSize = 100;
  } else if (getWindowSize().width <= 1920) {
    topSize = 60;
  } else if (getWindowSize().width > 1920) {
    topSize = 120;
  }

  return topSize;
}

function legendAreaChartX() {
  let legendx = 0;

  if (getWindowSize().width <= 1024) {
    legendx = 80;
  } else if (getWindowSize().width <= 1280) {
    legendx = 120;
  } else if (getWindowSize().width <= 1440) {
    legendx = 120;
  } else if (getWindowSize().width <= 1920) {
    legendx = 120;
  } else if (getWindowSize().width > 1920) {
    legendx = 120;
  }

  return legendx;
}

function legendAreaChartY() {
  let legendy = 0;

  if (getWindowSize().width <= 1024) {
    legendy = 770;
  } else if (getWindowSize().width <= 1280) {
    legendy = 700;
  } else if (getWindowSize().width <= 1440) {
    legendy = 750;
  } else if (getWindowSize().width <= 1920) {
    legendy = 750;
  } else if (getWindowSize().width > 1920) {
     legendy = 630;
  }

  return legendy;
}

function fontSizeLabelsAreaChart() {
  let fontSize = 0;

  if (getWindowSize().width <= 445) {
    fontSize = 60;
  } else if (getWindowSize().width <= 1024) {
    fontSize = 32;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 30;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 30;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 30;
  } else if (getWindowSize().width > 1920) {
    fontSize = 29;
  }

  return fontSize;
}

function itemsPerRowAreaChart() {
  let items = 0;

  if (getWindowSize().width <= 1024) {
    items = 2;
  } else if (getWindowSize().width <= 1280) {
    items = 2;
  } else if (getWindowSize().width <= 1440) {
    items = 2;
  } else if (getWindowSize().width <= 1920) {
    items = 2;
  } else if (getWindowSize().width > 1920) {
    items = 2;
  }


  return items;
}

function tickFontSizeLabelsAreaChart() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 18;
  } else if (getWindowSize().width > 1920) {
    fontSize = 18;
  }

  return fontSize;
}

function tooltipWidthAreaChart() {
  let flyoutWidth = 0;

  if (getWindowSize().width <= 1024) {
    flyoutWidth = 160;
  } else if (getWindowSize().width <= 1280) {
    flyoutWidth = 160;
  } else if (getWindowSize().width <= 1440) {
    flyoutWidth = 140;
  } else if (getWindowSize().width <= 1920) {
    flyoutWidth = 110;
  } else if (getWindowSize().width > 1920) {
    flyoutWidth = 110;
  }


  return flyoutWidth;
}

function tooltipHeigthAreaChart() {
  let flyoutHeight = 0;

  if (getWindowSize().width <= 1024) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1280) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1440) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1920) {
    flyoutHeight = 40;
  } else if (getWindowSize().width > 1920) {
    flyoutHeight = 40;
  }


  return flyoutHeight ;
}

function tooltipFontSizeAreaChart() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1280) {
    fontSize =20;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 20;
  } else if (getWindowSize().width > 1920) {
    fontSize = 20;
  }

  return fontSize;
}


/* AREA CHART FULL SCREEN SOLO-------------------------------------------- */

function widthAreaChartFulls() {
  let widthArea = 0;

  if (getWindowSize().width <= 445) {
    widthArea = 1800;
  } else if (getWindowSize().width <= 1024) {
    widthArea = 1800;
  } else if (getWindowSize().width <= 1280) {
    widthArea = 1900;
  } else if (getWindowSize().width <= 1440) {
    widthArea = 1750;
  } else if (getWindowSize().width <= 1920) {
    widthArea = 2250;
  } else if (getWindowSize().width > 1920) {
    widthArea = 2250;
  }

  return widthArea;
}



function heightAreaChartFulls() {
  let heightArea = 0;

  if (getWindowSize().width <= 445) {
    heightArea = 1400;
  } else if (getWindowSize().width <= 1024) {
    heightArea = 900;
  } else if (getWindowSize().width <= 1280) {
    heightArea = 900;
  } else if (getWindowSize().width <= 1440) {
    heightArea = 700;
  } else if (getWindowSize().width <= 1920) {
    heightArea = 800;
  } else if (getWindowSize().width > 1920) {
    heightArea = 800;
  }

  return heightArea;
}
function paddingChartFulls() {
  let padding  = 0;

  if (getWindowSize().width <= 1024) {
    padding  = 515;
  } else if (getWindowSize().width <= 1280) {
    padding  = 515;
  } else if (getWindowSize().width <= 1440) {
    padding  = 465;
  } else if (getWindowSize().width <= 1920) {
    padding  = 615;
  } else if (getWindowSize().width > 1920) {
    padding  = 615;
  }

  return padding ;
}


function bottomSizeAreaChartFulls() {
  let bottomSize = 0;

  if (getWindowSize().width <= 1024) {
    bottomSize = 500;
  } else if (getWindowSize().width <= 1280) {
    bottomSize = 400;
  } else if (getWindowSize().width <= 1440) {
    bottomSize = 400;
  } else if (getWindowSize().width <= 1920) {
    bottomSize = 430;
  } else if (getWindowSize().width > 1920) {
     bottomSize = 430;
  }

  return bottomSize;
}

function topSizeAreaChartFulls() {
  let topSize = 0;

  if (getWindowSize().width <= 1024) {
    topSize = 180;
  } else if (getWindowSize().width <= 1280) {
    topSize = 170;
  } else if (getWindowSize().width <= 1440) {
    topSize = 160;
  } else if (getWindowSize().width <= 1920) {
    topSize = 100;
  } else if (getWindowSize().width > 1920) {
    topSize = 100;
  }

  return topSize;
}

function legendAreaChartXFulls() {
  let legendx = 0;

  if (getWindowSize().width <= 1024) {
    legendx = 370;
  } else if (getWindowSize().width <= 1280) {
    legendx = 350;
  } else if (getWindowSize().width <= 1440) {
    legendx = 350;
  } else if (getWindowSize().width <= 1920) {
    legendx = 450;
  } else if (getWindowSize().width > 1920) {
     legendx = 450;
  }

  return legendx;
}

function legendAreaChartYFulls() {
  let legendy = 0;

  if (getWindowSize().width <= 445) {
    legendy = 1090;
  } else if (getWindowSize().width <= 1024) {
    legendy = 480;
  } else if (getWindowSize().width <= 1280) {
    legendy = 580;
  } else if (getWindowSize().width <= 1440) {
    legendy = 410;
  } else if (getWindowSize().width <= 1920) {
    legendy = 500;
  } else if (getWindowSize().width > 1920) {
     legendy = 500;
  }

  return legendy;
}

function fontSizeLabelsAreaChartFulls() {
  let fontSize = 0;

  if (getWindowSize().width <= 445) {
    fontSize = 67;
  } else if (getWindowSize().width <= 1024) {
    fontSize = 35;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 35;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 27;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 35;
  } else if (getWindowSize().width > 1920) {
    fontSize = 35;
  }

  return fontSize;
}


function itemsPerRowAreaChartFulls() {
  let items = 0;

  if (getWindowSize().width <= 445) {
    items = 2;
  } else if (getWindowSize().width <= 1024) {
    items = 2;
  } else if (getWindowSize().width <= 1280) {
    items = 3;
  } else if (getWindowSize().width <= 1440) {
    items = 3;
  } else if (getWindowSize().width <= 1920) {
    items = 3;
  } else if (getWindowSize().width > 1920) {
    items = 3;
  }


  return items;
}

function tickFontSizeLabelsAreaChartFulls() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 18;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 17;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 27;
  } else if (getWindowSize().width > 1920) {
    fontSize = 27;
  }

  return fontSize;
}

function tooltipWidthAreaChartFulls() {
  let flyoutWidth = 0;

  if (getWindowSize().width <= 1024) {
    flyoutWidth = 240;
  } else if (getWindowSize().width <= 1280) {
    flyoutWidth = 240;
  } else if (getWindowSize().width <= 1440) {
    flyoutWidth = 240;
  } else if (getWindowSize().width <= 1920) {
    flyoutWidth = 240;
  } else if (getWindowSize().width > 1920) {
    flyoutWidth = 240;
  }


  return flyoutWidth;
}

function tooltipHeigthAreaChartFulls() {
  let flyoutHeight = 0;

  if (getWindowSize().width <= 1024) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1280) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1440) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1920) {
    flyoutHeight = 70;
  } else if (getWindowSize().width > 1920) {
     flyoutHeight = 70;
  }


  return flyoutHeight ;
}

function tooltipFontSizeAreaChartFulls() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 25;
  } else if (getWindowSize().width <= 1280) {
    fontSize =25;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 20;
  } else if (getWindowSize().width > 1920) {
    fontSize = 20;
  }

  return fontSize;
}

/* AREA CHART FULL SCREEN COMPARAÇÂO-------------------------------------------- */

function widthAreaChartFull() {
  let widthArea = 0;

  if (getWindowSize().width <= 1024) {
    widthArea = 2200;
  } else if (getWindowSize().width <= 1280) {
    widthArea = 2200;
  } else if (getWindowSize().width <= 1440) {
    widthArea = 1750;
  } else if (getWindowSize().width <= 1920) {
    widthArea = 2100;
  } else if (getWindowSize().width > 1920) {
    widthArea = 2100;
  }

  return widthArea;
}



function heightAreaChartFull() {
  let heightArea = 0;

  if (getWindowSize().width <= 1024) {
    heightArea = 490;
  } else if (getWindowSize().width <= 1280) {
    heightArea = 500;
  } else if (getWindowSize().width <= 1440) {
    heightArea =400;
  } else if (getWindowSize().width <= 1920)  {
    heightArea = 480;
  } else if (getWindowSize().width > 1920) {
    heightArea = 480;
  }

  return heightArea;
}

function bottomSizeAreaChartFull() {
  let bottomSize = 0;

  if (getWindowSize().width <= 1024) {
    bottomSize = 150;
  } else if (getWindowSize().width <= 1280) {
    bottomSize = 170;
  } else if (getWindowSize().width <= 1440) {
    bottomSize = 140;
  } else if (getWindowSize().width <= 1920) {
    bottomSize = 140;
  } else if (getWindowSize().width > 1920) {
    bottomSize = 140;
  }

  return bottomSize;
}

function topSizeAreaChartFull() {
  let topSize = 0;

  if (getWindowSize().width <= 1024) {
    topSize = 80;
  } else if (getWindowSize().width <= 1280) {
    topSize = 70;
  } else if (getWindowSize().width <= 1440) {
    topSize = 60;
  } else if (getWindowSize().width <= 1920) {
    topSize = 80;
  } else if (getWindowSize().width > 1920) {
    topSize = 80;
  }

  return topSize;
}

function legendAreaChartXFull() {
  let legendx = 0;

  if (getWindowSize().width <= 1024) {
    legendx = 40;
  } else if (getWindowSize().width <= 1280) {
    legendx = 40;
  } else if (getWindowSize().width <= 1440) {
    legendx = 40;
  } else if (getWindowSize().width <= 1920) {
    legendx = 40;
  } else if (getWindowSize().width > 1920) {
    legendx = 40;
  }

  return legendx;
}

function legendAreaChartYFull() {
  let legendy = 0;

  if (getWindowSize().width <= 1024) {
    legendy = 440;
  } else if (getWindowSize().width <= 1280) {
    legendy = 440;
  } else if (getWindowSize().width <= 1440) {
    legendy = 360;
  } else if (getWindowSize().width <= 1920) {
    legendy = 420;
  } else if (getWindowSize().width > 1920) {
    legendy = 420;
  }

  return legendy;
}


function fontSizeLabelsAreaChartFull() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 34;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 30;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 25;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 25;
  } else if (getWindowSize().width > 1920) {
    fontSize = 25;
  }

  return fontSize;
}
function tickFontSizeLabelsAreaChartFull() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 25;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 23;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 20;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 25;
  } else if (getWindowSize().width > 1920) {
    fontSize = 25;
  }

  return fontSize;
}


function itemsPerRowAreaChartFull() {
  let items = 0;

  if (getWindowSize().width <= 1024) {
    items = 3;
  } else if (getWindowSize().width <= 1280) {
    items = 3;
  } else if (getWindowSize().width <= 1440) {
    items = 3;
  } else if (getWindowSize().width <= 1920) {
    items = 3;
  } else if (getWindowSize().width > 1920) {
     items = 3;
  }


  return items;
}


function tooltipWidthAreaChartFull() {
  let flyoutWidth = 0;

  if (getWindowSize().width <= 1024) {
    flyoutWidth = 250;
  } else if (getWindowSize().width <= 1280) {
    flyoutWidth = 250;
  } else if (getWindowSize().width <= 1440) {
    flyoutWidth = 220;
  } else if (getWindowSize().width <= 1920) {
    flyoutWidth = 250;
  } else if (getWindowSize().width > 1920) {
    flyoutWidth = 250;
  }


  return flyoutWidth;
}

function tooltipHeigthAreaChartFull() {
  let flyoutHeight = 0;

  if (getWindowSize().width <= 1024) {
    flyoutHeight = 60;
  } else if (getWindowSize().width <= 1280) {
    flyoutHeight = 55;
  } else if (getWindowSize().width <= 1440) {
    flyoutHeight = 45;
  } else if (getWindowSize().width <= 1920) {
    flyoutHeight = 60;
  } else if (getWindowSize().width > 1920) {
    flyoutHeight = 60;
  }


  return flyoutHeight ;
}

function tooltipFontSizeAreaChartFull() {
  let fontSize = 0;

  if (getWindowSize().width <= 1024) {
    fontSize = 25;
  } else if (getWindowSize().width <= 1280) {
    fontSize = 24;
  } else if (getWindowSize().width <= 1440) {
    fontSize = 22;
  } else if (getWindowSize().width <= 1920) {
    fontSize = 22;
  } else if (getWindowSize().width > 1920) {
    fontSize = 22;
  }

  return fontSize;
}



//Bar Chart

function widthBarChart() {
  let widthBar = 0;

  if (getWindowSize().width <= 500) {
    widthBar = 1500;
  } else if (getWindowSize().width <= 1024) {
    widthBar = 2600;
  } else if (getWindowSize().width <= 1280) {
    widthBar = 2400;
  } else if (getWindowSize().width <= 1440) {
    widthBar = 2600;
  } else if (getWindowSize().width <= 1920) {
    widthBar = 2100;
  } else if (getWindowSize().width > 1920) {
    widthBar = 2100;
  }

  return widthBar;
}

function heightBarChart() {
  let heightBar = 0;

  if (getWindowSize().width <= 500) {
    heightBar =1150;
  } else if (getWindowSize().width <= 1024) {
    heightBar = 700;
  } else if (getWindowSize().width <= 1280) {
    heightBar = 550;
  } else if (getWindowSize().width <= 1440) {
    heightBar = 550;
  } else if (getWindowSize().width <= 1920) {
    heightBar = 550;
  } else if (getWindowSize().width > 1920) {
     heightBar = 550;
  }

  return heightBar;
}

function paddingLeftBar() {
  let paddingLeft = 0;

  if (getWindowSize().width <= 500) {
    paddingLeft =220;
  } else if (getWindowSize().width <= 1024) {
    paddingLeft = 300;
  } else if (getWindowSize().width <= 1280) {
    paddingLeft = 350;
  } else if (getWindowSize().width <= 1440) {
    paddingLeft = 350;
  } else if (getWindowSize().width <= 1920) {
    paddingLeft = 250;
  } else if (getWindowSize().width > 1920) {
    paddingLeft = 250;
  }
  return paddingLeft;
}

function widthBarChartFull() {
  let widthBar = 0;

  if (getWindowSize().width <= 500) {
    widthBar = 1500;
  } else if (getWindowSize().width <= 1024) {
    widthBar = 2000;
  } else if (getWindowSize().width <= 1280) {
    widthBar = 2600;
  } else if (getWindowSize().width <= 1440) {
    widthBar = 2000;
  } else if (getWindowSize().width <= 1920) {
    widthBar = 2700;
  } else if (getWindowSize().width > 1920) {
 widthBar = 2700;
  }

  return widthBar;
}

function heightBarChartFull() {
  let heightBar = 0;

  if (getWindowSize().width <= 500) {
    heightBar =850;
  } else if (getWindowSize().width <= 1024) {
    heightBar = 1200;
  } else if (getWindowSize().width <= 1280) {
    heightBar = 1250;
  } else if (getWindowSize().width <= 1440) {
    heightBar = 850;
  } else if (getWindowSize().width <= 1920) {
    heightBar = 800;
  } else if (getWindowSize().width > 1920) {
heightBar = 800;
  }
  return heightBar;
}


function paddingLeftBarFull() {
  let paddingLeft = 0;

  if (getWindowSize().width <= 500) {
    paddingLeft =400;
  } else if (getWindowSize().width <= 1024) {
    paddingLeft = 250;
  } else if (getWindowSize().width <= 1280) {
    paddingLeft = 250;
  }else if (getWindowSize().width <= 1440) {
    paddingLeft = 250;
  } else if (getWindowSize().width <= 1920) {
    paddingLeft = 200;
  } else if (getWindowSize().width > 1920) {
paddingLeft = 200;
  }
  return paddingLeft;
}




export {
  /* PIE CHART */
  responsiveHeightPieChart,
  responsiveWidthPieChart,
  getPositionsPieChart,
  legendPieChartX,
  legendPieChartY,
  fontSizeLabelsPieChart,
  tooltipPosition,
  tooltipPositionY,
  /* AREA CHART */
  fontSizeLabelsAreaChart,
  topSizeAreaChart,
  bottomSizeAreaChart,
  widthAreaChart,
  heightAreaChart,
  legendAreaChartX,
  legendAreaChartY,
  itemsPerRowAreaChart,
  tickFontSizeLabelsAreaChart,
  tooltipWidthAreaChart,
  tooltipHeigthAreaChart,
  tooltipFontSizeAreaChart,
  /* AREA CHART FS COMPAÇÃO*/
  paddingChart,
  widthAreaChartFull,
  heightAreaChartFull,
  bottomSizeAreaChartFull,
  topSizeAreaChartFull,
  legendAreaChartXFull,
  legendAreaChartYFull,
  fontSizeLabelsAreaChartFull,
  itemsPerRowAreaChartFull,
  tickFontSizeLabelsAreaChartFull,
  tooltipWidthAreaChartFull,
  tooltipHeigthAreaChartFull,
  tooltipFontSizeAreaChartFull,

  /* AREA CHART FS SOLO*/
  widthAreaChartFulls,
  paddingChartFulls,
  heightAreaChartFulls,
  bottomSizeAreaChartFulls,
  topSizeAreaChartFulls,
  legendAreaChartXFulls,
  legendAreaChartYFulls,
  fontSizeLabelsAreaChartFulls,
  itemsPerRowAreaChartFulls,
  tickFontSizeLabelsAreaChartFulls,
  tooltipWidthAreaChartFulls,
  tooltipHeigthAreaChartFulls,
  tooltipFontSizeAreaChartFulls,
  
  /* BAR CHART */
  widthBarChart,
  heightBarChart,
  paddingLeftBar,
  widthBarChartFull,
  heightBarChartFull,
  paddingLeftBarFull,
};
