import React, { createContext, ReactNode, useContext, useState } from "react"
import { delEquipment, delSmartMeter, getAllEquipment, getEquipmentByConsumerUnitId, getEquipmentById, postEquipment, putEquipment } from "../services/restApiEquipment"
import { Equipments, EquipmentResponse, ConsumerUnitType } from "../util/types"
import { useStateContext } from "./stateContext"

type PropsEquipmentContext = {
  stateEquipments: Equipments,
  setStateEquipments: React.Dispatch<React.SetStateAction<Equipments>>,

  stateEquipmentsResponse: EquipmentResponse,
  setStateEquipmentsResponse: React.Dispatch<React.SetStateAction<EquipmentResponse>>,
  stateSmartMeterResponse: ConsumerUnitType,
  setStateSmartMeterResponse: React.Dispatch<React.SetStateAction<ConsumerUnitType>>,

  stateEquipmentsList: Array<EquipmentResponse>,
  setStateEquipmentsList: React.Dispatch<React.SetStateAction<Array<EquipmentResponse>>>,
  getEquipmentList: () => Promise<void>,
  getEquipmentByIdContext: (id: string | number) => Promise<void>,
  getEquipmentByIdConsumerUnit: (id: string | number) => Promise<void>,
  equipmentPostApi: (equipment: any) => Promise<boolean>,
  equipmentPutApi: (equipment: any, id: any, list: boolean) => Promise<boolean>,
  deleteEquipment: (id: number | string) => Promise<boolean>,
  deleteSmartMeter: (id: number | string) => Promise<boolean>,
}

type EquipmentContextTypes = {
  children: ReactNode
}

export const EquipmentContext = createContext({} as PropsEquipmentContext)


function EquipmentContextProvider(props: EquipmentContextTypes) {
  const [stateEquipments, setStateEquipments] = useState<Equipments>({} as Equipments)

  const [stateEquipmentsResponse, setStateEquipmentsResponse] = useState<EquipmentResponse>({} as EquipmentResponse)
  const [stateSmartMeterResponse, setStateSmartMeterResponse] = useState<ConsumerUnitType>({} as ConsumerUnitType)

  const [stateEquipmentsList, setStateEquipmentsList] = useState<Array<EquipmentResponse>>([{}] as Array<EquipmentResponse>)
  const { setSnackBarTextResponse, selectConsumerUnitId, setIsGetFetching } = useStateContext()

  const getEquipmentList = async () => {
    setStateEquipmentsList([])
    setIsGetFetching(true)
    const { data, status } = await getAllEquipment()

    if (status === 200) {
      setStateEquipmentsList(data)
      setIsGetFetching(false)
    } else {
      setIsGetFetching(false)
    }
  }

  const getEquipmentByIdContext = async (id: number | string) => {
    console.log("getEquipmentByIdContext", id)
    setStateEquipmentsList([])
    setIsGetFetching(true)
    const { data, status } = await getEquipmentById(id)
    if (status === 200) {
      setStateEquipmentsList(data)
      setIsGetFetching(false)
    } else {
      setIsGetFetching(false)
    }
  }

  const getEquipmentByIdConsumerUnit = async (id: number | string) => {
    setStateEquipmentsList([])
    setIsGetFetching(true)
    const { data, status } = await getEquipmentByConsumerUnitId(id)
    if (status === 200) {
      setStateEquipmentsList(data)
      setIsGetFetching(false)
    } else {
      setIsGetFetching(false)
    }
  }

  const equipmentPostApi = async (equipment: any) => {
    let hasEquipment = false
    try {
      const { status } = await postEquipment(equipment)
      hasEquipment = status === 200
      if (hasEquipment) {
        getEquipmentByIdConsumerUnit(selectConsumerUnitId)
        setSnackBarTextResponse('Equipamento cadastrado com sucesso!')
      }

    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasEquipment
  }

  const equipmentPutApi = async (equipment: any, id: any, list: boolean) => {
    let hasEquipment = false

    try {
      const { status } = await putEquipment(equipment, id)
      hasEquipment = status === 200
      if (hasEquipment) {
        getEquipmentByIdConsumerUnit(selectConsumerUnitId)
        setSnackBarTextResponse('Equipamento editado com sucesso!')
      }

    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasEquipment
  }


  const deleteEquipment = async (id: number | string) => {
    let hasDelete = false
    try {
      const { status } = await delEquipment(id)
      hasDelete = status === 200
      if (hasDelete) {
        getEquipmentByIdConsumerUnit(selectConsumerUnitId)
        setSnackBarTextResponse('Equipamento deletado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasDelete
  }

  const deleteSmartMeter = async (id: number | string) => {
    let hasDelete = false
    try {
      const { status } = await delSmartMeter(id)
      hasDelete = status === 200
      if (hasDelete) {
        setSnackBarTextResponse('Medidor deletado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasDelete
  }

  const objProvider = {
    stateEquipments,
    setStateEquipments,
    stateEquipmentsResponse,
    setStateEquipmentsResponse,
    stateEquipmentsList,
    setStateEquipmentsList,
    stateSmartMeterResponse,
    setStateSmartMeterResponse,
    getEquipmentList,
    getEquipmentByIdContext,
    getEquipmentByIdConsumerUnit,
    equipmentPostApi,
    equipmentPutApi,
    deleteEquipment,
    deleteSmartMeter,
  }

  return (
    <EquipmentContext.Provider
      value={objProvider}
    >
      {props.children}
    </EquipmentContext.Provider>
  )
}

function useEquipmentContext() {
  const context = useContext(EquipmentContext)
  return context
}

export {
  EquipmentContextProvider,
  useEquipmentContext
}
