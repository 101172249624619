import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryStack,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import useFormatDate from "../../../util/hooks/useFormartDate";
import styles from "./styles.module.scss";
import { ReactComponent as EquipamentIconOutline } from "../../../assets/equipamentIconOutline.svg";
import { useReportContext } from "../../../providers/reportContext";
import { useEffect, useState } from "react";
import theme from "../../../styles/victoryTheme";
import {
  itemsPerRowAreaChartFull,
  topSizeAreaChartFull,
  widthAreaChartFull,
  heightAreaChartFull,
  legendAreaChartXFull,
  legendAreaChartYFull,
  bottomSizeAreaChartFull,
  fontSizeLabelsAreaChartFull,
  tickFontSizeLabelsAreaChartFull,
  tooltipWidthAreaChartFull,
  tooltipHeigthAreaChartFull,
  tooltipFontSizeAreaChartFull,

} from "../../../util/ResponsiveCharts/responsive";
import { CircularProgress } from "@mui/material";

export default function AreaChartComp({ width, height, data, chartSizeType }) {
  const formartDate = useFormatDate();
  const { loadingChart } = useReportContext();
  const [areaChartData, setAreaChartData] = useState();
  const { equipmentConsumptions, totalConsumption } = data;  
  const [tickFontSize, setTickFontSize] = useState(0);
  const [fontLabelsAreaChart, setFontLabelsAreaChart] = useState(0);
  const [topSize, setTopSize] = useState(0);
  const [bottomSize, setBottomSize] = useState(0);
  const [widthAreaChartX, setAreaChartX] = useState(0);
  const [heightAreaChartY, setAreaChartY] = useState(0);
  const [legendAreaCharttX, setLegendAreaChartX] = useState(0);
  const [legendAreaCharttY, setLegendAreaChartY] = useState(0);
  const [itemsPerRow, setItemsPerRow] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeigth, setTooltipHeigth] = useState(0);
  const [tooltipFontSize, setTooltipFontSize] = useState(0);

  


  useEffect(() => {
    switch(chartSizeType){      
      case 'fullscreen':
        setTickFontSize(tickFontSizeLabelsAreaChartFull); 
        setFontLabelsAreaChart(fontSizeLabelsAreaChartFull);
        setTopSize(topSizeAreaChartFull);
        setBottomSize(bottomSizeAreaChartFull);
        setLegendAreaChartX(legendAreaChartXFull);
        setLegendAreaChartY(legendAreaChartYFull);
        setAreaChartX(widthAreaChartFull);
        setAreaChartY(heightAreaChartFull);
        setItemsPerRow(itemsPerRowAreaChartFull);
        setTooltipWidth(tooltipWidthAreaChartFull);
        setTooltipHeigth(tooltipHeigthAreaChartFull);
        setTooltipFontSize(tooltipFontSizeAreaChartFull);        
        break;      
      }    
  }, []);

  useEffect(() => {
    mountedArrayForAreaChart();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps  

  function mountedArrayForAreaChart() {
    let areaData = [];
    if (data) {
      if (equipmentConsumptions && equipmentConsumptions.length > 0) {
        equipmentConsumptions
          .filter((fil) => fil.consumption !== 0)
          .forEach((item) => {
            areaData.push({ name: item.name, values: item.values });
          });          
      }
    }
    setAreaChartData(areaData);
  }  

  if (loadingChart) {
    return (
      <div className={styles.blurLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Buscando informações</h2>
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    if (Object.keys(data).length > 0 && totalConsumption === 0) {
      return (
        <div className={styles.NotConsumer}>
          <h2>Não encontramos dados de consumo de desagregação</h2>
          <EquipamentIconOutline color="#0f85ca" />
        </div>
      );
    }
    if (areaChartData && areaChartData.length > 0) {
      return (
        <div>
          <VictoryChart
            width={widthAreaChartX}
            height={heightAreaChartY}            
            padding={{ top: topSize, bottom: bottomSize, left: 280, right: 30 }}
            scale={{ x: "time" , y: 'linear' }}
            theme={theme}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => `${datum.consumption.toFixed(2)} kWh`}
                labelComponent={
                  <VictoryTooltip
                  flyoutWidth={tooltipWidth} 
                  flyoutHeight={tooltipHeigth}
                    style={{
                      fill: "#0f85ca",
                      fontSize: tooltipFontSize,
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      textAnchor: "middle",
                      pointerEvents: "none",
                      background: "#fff",
                    }}
                  />
                }
              />
            }
          >
            <VictoryAxis
              dependentAxis
              crossAxis
              tickFormat={(tick) => `${tick} kWh`}
              style={{
                axis: { stroke: "#C8CBCC" },
                grid: {
                  stroke: ({ tick }) => (tick > 0.5 ? "#C8CBCC" : "#C8CBCC"),
                },
                ticks: { stroke: "#C8CBCC", size: 15 },
                tickLabels: { fontSize: tickFontSize, padding: 10, fontWeight: "700" },
              }}
            />
            <VictoryAxis
              crossAxis
              style={{
                axis: { stroke: "#C8CBCC" },
                grid: {
                  stroke: ({ tick }) => (tick > 0.5 ? "#C8CBCC" : "#C8CBCC"),
                },
                ticks: { stroke: "#C8CBCC", size: 15, padding: 10 },
                tickLabels: {
                  fontSize: tickFontSize,
                  padding: 10,
                  fontWeight: "700",
                  angle: 45,
                },
              }}
            />
            <VictoryStack>
              {areaChartData.map((item, index) => {
                return (
                  <VictoryGroup key={index} offset={0}>
                    <VictoryArea
                      interpolation="monotoneX"
                      theme={theme}
                      style={{
                        data: {
                          fillOpacity: 0.9,
                        },
                        labels: {
                          fontSize: 40,
                        },
                      }}
                      x={(d) => formartDate.formatUTC24(d.timestamp)}
                      y="consumption"
                      data={item.values}
                    />
                  </VictoryGroup>
                );
              })}
            </VictoryStack>
            <VictoryLegend
              theme={theme}
              x={legendAreaCharttX}
              y={legendAreaCharttY}
              orientation="horizontal"
              itemsPerRow={itemsPerRow}
              gutter={80}
              data={areaChartData}
              style={{ labels: { fontSize: fontLabelsAreaChart } }}
            />
          </VictoryChart>
        </div>
      );
    }
  }
  return (
    <div className={styles.NotConsumer}>
      <h2>Não encontramos dados de consumo de desagregação nesta data.</h2>
      <EquipamentIconOutline color="#0f85ca" />
    </div>
  );
}
