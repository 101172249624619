import {
  Routes,
  Route,
} from 'react-router-dom'

import Signin from '../pages/Signin'
import Signup from '../pages/Signup';
import ResetPassword from '../pages/ResetPassword';
import ResetSuccessPassword from '../pages/ResetSuccessPassword';

import Breakdown from '../pages/Breakdown'
import MyProfile from '../pages/MyProfile'
import ConsumerUnit from '../pages/ConsumerUnit';
import UserManagement from '../pages/UserManagement';
import EquipmentManagement from '../pages/EquipmentManagement';
import ModelManagement from '../pages/ModelManagement';
import ForgotPassword from '../pages/Forgotpassword';
import ConfirmarEmailSuccess from '../pages/ConfirmarEmailSuccess';
import { PrivateRoute } from './PrivateRote';
import AuthPages from '../pages/AuthPages';
import Layout from '../pages/Layout';
import SmartMeterManagement from '../pages/MeterManagement';
import Contract from '../pages/Contract';

export default function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<AuthPages />} >
          <Route index element={<Signin />} />
          <Route path='signup' element={<Signup />} />
          <Route path='resetPassword' element={<ResetPassword />} />
          <Route path='resetPasswordSuccess' element={<ResetSuccessPassword />} />
          <Route path='forgotPassword' element={<ForgotPassword />} />
          <Route path='emailConfirmSuccess' element={<ConfirmarEmailSuccess />} />
          <Route path='contract' element={<Contract />} />         
        </Route>
      </Routes>

      <Routes>
        <Route path='/dashboard' element={<PrivateRoute roles={['Admin', 'Tecnico', 'Cliente']}><Layout /></PrivateRoute>}>
          <Route index element={<PrivateRoute roles={['Admin', 'Tecnico', 'Cliente']}><Breakdown /></PrivateRoute>} />
          <Route path='consumerUnit' element={<PrivateRoute roles={['Admin', 'Tecnico', 'Cliente']}><ConsumerUnit /></PrivateRoute>} />
          <Route path='equipmentManagement' element={<PrivateRoute roles={['Admin', 'Tecnico', 'Cliente']}><EquipmentManagement /></PrivateRoute>} />
          <Route path='modelManagement' element={<PrivateRoute roles={['Admin', 'Tecnico']}><ModelManagement /></PrivateRoute>} />
          <Route path='smartMeterManagement' element={<PrivateRoute roles={['Admin', 'Tecnico']}><SmartMeterManagement /></PrivateRoute>} />
          <Route path='myProfile' element={<PrivateRoute roles={['Admin', 'Tecnico', 'Cliente']}><MyProfile /></PrivateRoute>} />
          <Route path='userManagement' element={<PrivateRoute roles={['Admin']}><UserManagement /></PrivateRoute>} />          
        </Route>        
      </Routes>
    </div>
  )
}
