import React, { useState, useMemo } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryLine,
  VictoryStack,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import classNames from "classnames";

import useFormatDate from "../../../util/hooks/useFormartDate";
import styles from "./styles.module.scss";
import { ReactComponent as EquipamentIconOutline } from "../../../assets/equipamentIconOutline.svg";
import { useReportContext } from "../../../providers/reportContext";
import { useEffect } from "react";
import theme from "../../../styles/victoryTheme";
import {
  paddingChart,
  itemsPerRowAreaChart,
  topSizeAreaChart,
  widthAreaChart,
  heightAreaChart,
  legendAreaChartX,
  legendAreaChartY,
  bottomSizeAreaChart,
  fontSizeLabelsAreaChart,
  tickFontSizeLabelsAreaChart,
  tooltipWidthAreaChart,
  tooltipHeigthAreaChart,
  tooltipFontSizeAreaChart,

  paddingChartFulls,
  itemsPerRowAreaChartFulls,
  topSizeAreaChartFulls,
  widthAreaChartFulls,
  heightAreaChartFulls,
  legendAreaChartXFulls,
  legendAreaChartYFulls,
  bottomSizeAreaChartFulls,
  fontSizeLabelsAreaChartFulls,
  tickFontSizeLabelsAreaChartFulls,
  tooltipWidthAreaChartFulls,
  tooltipHeigthAreaChartFulls,
  tooltipFontSizeAreaChartFulls,

} from "../../../util/ResponsiveCharts/responsive";
import { CircularProgress } from "@mui/material";

export default function AreaChart({ width, height, data, chartSizeType }) {
  const formartDate = useFormatDate();
  const { loadingChart } = useReportContext();
  const [areaChartData, setAreaChartData] = useState();
  
  const { equipmentConsumptions, totalConsumption } = data;
  const [paddingFull, setPaddingFull] = useState(0);
  const [tickFontSize, setTickFontSize] = useState(0);
  const [fontLabelsAreaChart, setFontLabelsAreaChart] = useState(0);
  const [topSize, setTopSize] = useState(0);
  const [bottomSize, setBottomSize] = useState(0);
  const [widthAreaChartX, setAreaChartX] = useState(0);
  const [heightAreaChartY, setAreaChartY] = useState(0);
  const [legendAreaCharttX, setLegendAreaChartX] = useState(0);
  const [legendAreaCharttY, setLegendAreaChartY] = useState(0);
  const [itemsPerRow, setItemsPerRow] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeigth, setTooltipHeigth] = useState(0);
  const [tooltipFontSize, setTooltipFontSize] = useState(0);


  

  const sortedAreaChartData = useMemo(() => {
  if (!areaChartData) return [];

  return areaChartData.sort((a, b) => {
    const totalA = a.values.reduce((sum, curr) => sum + curr.consumption, 0);
    const totalB = b.values.reduce((sum, curr) => sum + curr.consumption, 0);
    return totalB - totalA;
  });
}, [areaChartData]);

  const chartColors = [
  "#0066b2",
  "#6CB4EE",
  "#edab27",
  "#34A65F",
  "#90be6d",
  "#43aa8b",
  "#4d908e",
  "#577590",
  "#277da1",
];


  


  const [activeLegend, setActiveLegend] = useState([]);
  

  const handleClick = (props) => {
  const index = props.datum.index;
  if (activeLegend.includes(index)) {
    setActiveLegend(activeLegend.filter((i) => i !== index));
  } else {
    setActiveLegend([...activeLegend, index]);
  }
};

  useEffect(() => {
    switch(chartSizeType){
      case 'normal':
        setPaddingFull (paddingChart);
        setTickFontSize(tickFontSizeLabelsAreaChart);
        setFontLabelsAreaChart(fontSizeLabelsAreaChart);
        setTopSize(topSizeAreaChart);
        setBottomSize(bottomSizeAreaChart);
        setLegendAreaChartX(legendAreaChartX);
        setLegendAreaChartY(legendAreaChartY);
        setAreaChartX(widthAreaChart);
        setAreaChartY(heightAreaChart);
        setItemsPerRow(itemsPerRowAreaChart);
        setTooltipWidth(tooltipWidthAreaChart);
        setTooltipHeigth(tooltipHeigthAreaChart);
        setTooltipFontSize(tooltipFontSizeAreaChart);                
        break;      
      case 'fullscreensolo':
        setPaddingFull (paddingChartFulls);
        setTickFontSize(tickFontSizeLabelsAreaChartFulls); 
        setFontLabelsAreaChart(fontSizeLabelsAreaChartFulls);
        setTopSize(topSizeAreaChartFulls);
        setBottomSize(bottomSizeAreaChartFulls);
        setLegendAreaChartX(legendAreaChartXFulls);
        setLegendAreaChartY(legendAreaChartYFulls);
        setAreaChartX(widthAreaChartFulls);
        setAreaChartY(heightAreaChartFulls);
        setItemsPerRow(itemsPerRowAreaChartFulls);
        setTooltipWidth(tooltipWidthAreaChartFulls);
        setTooltipHeigth(tooltipHeigthAreaChartFulls);
        setTooltipFontSize(tooltipFontSizeAreaChartFulls);          
        break;
      }    
  }, []);
  

  


function mountedArrayForAreaChart() {
  let maxConsumption = 0;
  if (!data || !equipmentConsumptions || equipmentConsumptions.length === 0) {
    setAreaChartData([]);
    return maxConsumption;
  }

  const filteredEquipmentConsumptions = equipmentConsumptions.filter(
    (equipment) => equipment.consumption !== 0
  );

  const areaData = filteredEquipmentConsumptions.map((equipment) => {
    // Check if the maximum consumption for this equipment is greater than the current maxConsumption
    const equipmentMax = Math.max(...equipment.values.map(v => v.consumption));
    if (equipmentMax > maxConsumption) {
      maxConsumption = equipmentMax;
    }
    return {
      name: equipment.name,
      values: equipment.values,
    };
  });

  setAreaChartData(areaData);
  console.log('Area Chart Data:', areaData);
  return maxConsumption;
}


const [maxDailyConsumption, setMaxDailyConsumption] = useState(0);


useEffect(() => {
  const maxConsumption = mountedArrayForAreaChart();
  setMaxDailyConsumption(maxConsumption);
  console.log('Max Daily Consumption:', maxConsumption);
}, [data, equipmentConsumptions]); // Add equipmentConsumptions as a dependency


function convertToHalfHourly(timestamp) {
  // One hour in milliseconds
  const oneHour = 60 * 60 * 1000;
  
  // Half an hour in milliseconds
  const halfHour = oneHour / 2;
  
  // Add half an hour to the timestamp
  const halfHourlyTimestamp = timestamp + halfHour;
  
  return halfHourlyTimestamp;
}


  if (loadingChart) {
    return (
      <div className={styles.blurLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Buscando informações</h2>
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    if (Object.keys(data).length > 0 && totalConsumption === 0) {
      return (
        <div className={styles.NotConsumer}>
          <h2>Não encontramos dados de consumo de desagregação</h2>
          <EquipamentIconOutline color="#0f85ca" />
        </div>
      );
    }
    if (areaChartData && areaChartData.length > 0) {
      
      return (
        <div className={styles.boxChart}>
          
          <VictoryChart
          
            width={widthAreaChartX}
            height={heightAreaChartY}            
            padding={{ top: topSize, bottom: bottomSize, left: paddingFull, right: 250 }}
            scale={{ x: "time" , y: 'linear' }}
            theme={theme}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => `${datum.consumption ? datum.consumption.toFixed(2) : 0} kWh`}
                labelComponent={
                  <VictoryTooltip
                  flyoutWidth={tooltipWidth} 
                  flyoutHeight={tooltipHeigth}
                    style={{
                      fill: "#0f85ca",
                      fontSize: tooltipFontSize,
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      textAnchor: "middle",
                      pointerEvents: "none",
                     
                    }}
                  />
                }
              />
            }
          >
            <VictoryAxis
              dependentAxis
              crossAxis
              tickFormat={(tick) => `${tick} kWh`}
              style={{
                axis: { stroke: "#646462" },
                grid: {
                  stroke: ({ tick }) => (tick > 0.5 ? "#646462" : "#B8B8B8"),
                },
                ticks: { stroke: "#646462", size: 15, },
                tickLabels: { fontSize: tickFontSize, padding: 20, fontWeight: "700" },
              }}
            />
            <VictoryAxis
              crossAxis
              style={{
                axis: { stroke: "#646462" },
                grid: {
                  stroke: ({ tick }) => (tick > 0.5 ? "#B8B8B8" : "#B8B8B8"),
                },
                ticks: { stroke: "#646462", size: 15, padding: 10 },
                tickLabels: {
                  fontSize: tickFontSize,
                  padding: 10,
                  fontWeight: "700",
                  angle: 45,
                },
              }}
            />
    <VictoryStack>          
    {sortedAreaChartData.map((item, index) => (
      
  <VictoryArea
    key={index}
    interpolation="monotoneX"
    theme={theme}
    style={{
      data: {
        fill: chartColors[index % chartColors.length],
        fillOpacity: 1.3 - (index / sortedAreaChartData.length),
        stroke: chartColors[index % chartColors.length],
        strokeWidth: index === 3 ? 15 : 3,
      },
      labels: {
        fontSize: 40,
      },
    }}
    x={(d) => formartDate.formatUTC24(convertToHalfHourly(d.timestamp))}
    y="consumption"
    data={
      activeLegend.length === 0 || activeLegend.includes(index)
        ? item.values
        : item.values.map((v) => ({ ...v, consumption: 0 }))
    }
  />
  
))}
</VictoryStack>
  

<VictoryArea
  style={{ data: { fill:"yellow", opacity:"0.3" } }}
  data={[
    { x: formartDate.formatUTC24(new Date().setHours(13, 30, 0, 0)), y: (0, maxDailyConsumption) },
    { x: formartDate.formatUTC24(new Date().setHours(17, 30, 0, 0)), y: (maxDailyConsumption, maxDailyConsumption) },
    { x: formartDate.formatUTC24(new Date().setHours(17, 30, 0, 0)), y: (maxDailyConsumption,0) }    
  ]}
/>
<VictoryArea
  style={{ data: { fill:"red", opacity:"0.3" } }}
  data={[
    { x: formartDate.formatUTC24(new Date().setHours(14, 30, 0, 0)), y: (0, maxDailyConsumption) },
    { x: formartDate.formatUTC24(new Date().setHours(16, 30, 0, 0)), y: (maxDailyConsumption, maxDailyConsumption) },
    { x: formartDate.formatUTC24(new Date().setHours(16, 30, 0, 0)), y: (maxDailyConsumption,0) }    
  ]}
/>




  



            <VictoryLegend
  theme={theme}
  x={legendAreaCharttX}
  y={legendAreaCharttY}
  orientation="horizontal"
  itemsPerRow={itemsPerRow}
  gutter={80}
  data={areaChartData.map((item, index) => ({
    ...item,
    index,
  }))}
  style={{
  labels: { fontSize: fontLabelsAreaChart, className: classNames(styles.legendLabel) },
  data: {
    type: ({ datum }) =>
      activeLegend.length === 0 || activeLegend.includes(datum.index) ? "circle" : "square",
    fill: ({ datum }) => chartColors[datum.index % chartColors.length],
  },
}}
  events={[
    {
      target: "labels",
      eventHandlers: {
        onClick: () => {
          return [
            {
              target: "labels",
              mutation: (props) => {
                handleClick(props);
                return null;
              },
            },
          ];
        },
        onMouseOver: () => {
          return [
            {
              target: "labels",
              mutation: () => {
                return {style: {fill: "#0f85ca", fontSize: "30px",cursor: "pointer" }};
              }
              
            },
          ];
        },
        onMouseOut: () => {
          return [
            {
              target: "labels",
              mutation: () => {
                return null;
              },
            },
          ];
        },
      },
    },
  ]}
/>

          </VictoryChart>
        </div>
      );
    }
  }
  return (
    <div className={styles.NotConsumer}>
      <h2>Não encontramos dados de consumo de desagregação nesta data.</h2>
      <EquipamentIconOutline color="#0f85ca" />
    </div>
  );
}