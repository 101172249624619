import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

import styles from './styles.module.scss';

import { useFormik } from "formik";
import * as yup from "yup";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import CustomButton from "../../Form/button";
import CustomInput from "../../CustomInput";
import { useStateContext } from "../../../providers/stateContext";
import CustomButtonLoading from "../../Form/buttonLoading";
import { useMeterContext } from "../../../providers/meterContext";

type propsModal = {
  meterEdit: any;
  title: string;
};

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalEditMeter({ meterEdit, title }: propsModal) {
  const { putMeter } = useMeterContext()
  const { modalEditMeter, setModalEditMeter } = useModalContext();
  const { setIsLoading, setStatusErrorOpenSnackBar, setOpenSnackBar } = useStateContext()
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);
  const [meterType, setMeterType] = useState(false)
  const [checkMeterEquipment, setCheckMeterEquipment] = useState(false)

  const { id, name, consumerUnit, isSmart, equipmentR, equipmentS, equipmentT } = meterEdit;

  useEffect(() => {
    formik.setFieldValue("macId", id, true);
    formik.setFieldValue("name", name, true);
    setMeterType(isSmart)

    if (equipmentR === null && equipmentS === null && equipmentT === null) {
      setCheckMeterEquipment(true)
    } else {
      setCheckMeterEquipment(false)
    }

  }, [meterEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = yup.object({
    macId: yup.string().required('Campo obrigatório'),
    name: yup.string().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      macId: id,
      name: name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      const mountedMeterPut = {
        id: values.macId,
        name: values.name,
        isSmart: meterType,
      }

      let hasMeterPut = await putMeter(mountedMeterPut, id)

      if (hasMeterPut) {
        setIsLoading(false)
        setStatusErrorOpenSnackBar(false)
        setOpenSnackBar(true)
      } else {
        setIsLoading(false)
        setStatusErrorOpenSnackBar(true)
        setOpenSnackBar(true)
      }
    },
  });

  const handleChangeCheckBox = () => {
    setMeterType(!meterType);
  };


  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalEditMeter(false);
      setStateExitModal(false);
    };

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleClose()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalEditMeter}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "-10px",
                  }}
                >
                  Editar
                  <br />
                  {title}
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box>
                <Typography component={"span"} variant={"body2"}>
                  <div style={{ marginBottom: 15 }}>Dados do Medidor</div>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="macId"
                    name="macId"
                    label="Tópico MQTT"
                    value={formik.values.macId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.macId && Boolean(formik.errors.macId)
                    }
                    helperText={formik.touched.macId && formik.errors.macId}
                    disabled={!checkMeterEquipment}
                  />

                  {
                    consumerUnit === null || checkMeterEquipment ?

                      <FormControl>
                        <Typography component={"span"} variant={"body2"}>
                          <div style={{ marginBottom: 15 }}>Alterar tipo de medidor</div>
                        </Typography>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={meterType}
                          onChange={handleChangeCheckBox}
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio size="small" />}
                            label="QGBT"
                            sx={{ margin: 0, gap: '7px' }}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio size="small" />}
                            label="Setor/Aparelho"
                            sx={{ margin: 0, gap: '7px' }} />
                        </RadioGroup>
                      </FormControl>
                      : null
                  }
                </Box>
                <Box
                  sx={{
                    padding: "1rem 0rem",
                  }}
                ></Box>
                <Divider />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <CustomButtonLoading type="submit" title="Editar" className="middle" />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <ExitModal
        messageTitle="Deseja cancelar a edição?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
