import React, { createContext, ReactNode, useContext, useState } from "react"

type PropsModalContext = {
  modalUserADM: boolean,
  modalEditClient: boolean,
  modalEditSubCategory: boolean,
  modalEditModel: boolean,
  modalEditMeter: boolean,
  modalEditConsumerUnit: boolean,
  modalEditBusinessType: boolean,
  modalEditEquipment: boolean,
  modalManagerEquipEdit: boolean,
  modalMeterRegistration: boolean,
  modalModelRegistration: boolean,
  modalEquipmentRegistration: boolean,
  modalSubCategoryRegistration: boolean,
  modalConsumerUnitRegistration: boolean,
  modalBusinessTypeRegistration: boolean,
  exitModal: boolean,
  connectSmartMeter: boolean,
  connectIntrusiveSmartMeter: boolean,
  modalDelete: boolean,
  modalDesassociated: boolean,
  modalChartFullscreenPie: boolean,
  modalChartFullscreenArea: boolean,
  modalChartFullscreenBar: boolean,
  setModalUserADM: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEditClient: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEditSubCategory: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEditModel: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEditMeter: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEditConsumerUnit: React.Dispatch<React.SetStateAction<boolean>>,
  setEditModalBusinessType: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEditEquipment: React.Dispatch<React.SetStateAction<boolean>>,
  setModalManagerEquipEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setModalMeterRegistration: React.Dispatch<React.SetStateAction<boolean>>,
  setModalModelRegistration: React.Dispatch<React.SetStateAction<boolean>>,
  setModalEquipmentRegistration: React.Dispatch<React.SetStateAction<boolean>>,
  setModalSubCategoryRegistration: React.Dispatch<React.SetStateAction<boolean>>
  setModalConsumerUnitRegistration: React.Dispatch<React.SetStateAction<boolean>>
  setModalBusinessTypeRegistration: React.Dispatch<React.SetStateAction<boolean>>
  setExitModal: React.Dispatch<React.SetStateAction<boolean>>
  setConnectSmartMeter: React.Dispatch<React.SetStateAction<boolean>>
  setConnectIntrusiveSmartMeter: React.Dispatch<React.SetStateAction<boolean>>
  setModalDelete: React.Dispatch<React.SetStateAction<boolean>>
  setModalDesassociated: React.Dispatch<React.SetStateAction<boolean>>
  setModalChartFullscreenPie: React.Dispatch<React.SetStateAction<boolean>>
  setModalChartFullscreenArea: React.Dispatch<React.SetStateAction<boolean>>
  setModalChartFullscreenBar: React.Dispatch<React.SetStateAction<boolean>>
}

type ModalContextTypes = {
  children: ReactNode
}

export const ModalContext = createContext({} as PropsModalContext)

function ModalProvider({ children }: ModalContextTypes) {
  const [modalUserADM, setModalUserADM] = useState<boolean>(false)
  const [modalEditClient, setModalEditClient] = useState<boolean>(false)
  const [modalEditSubCategory, setModalEditSubCategory] = useState<boolean>(false)
  const [modalEditModel, setModalEditModel] = useState<boolean>(false)
  const [modalEditMeter, setModalEditMeter] = useState<boolean>(false)
  const [modalManagerEquipEdit, setModalManagerEquipEdit] = useState<boolean>(false)
  const [modalEditEquipment, setModalEditEquipment] = useState<boolean>(false)
  const [modalEditBusinessType, setEditModalBusinessType] = useState<boolean>(false)
  const [modalMeterRegistration, setModalMeterRegistration] = useState<boolean>(false)
  const [modalModelRegistration, setModalModelRegistration] = useState<boolean>(false)
  const [modalEquipmentRegistration, setModalEquipmentRegistration] = useState<boolean>(false)
  const [modalSubCategoryRegistration, setModalSubCategoryRegistration] = useState<boolean>(false)
  const [modalConsumerUnitRegistration, setModalConsumerUnitRegistration] = useState<boolean>(false)
  const [modalBusinessTypeRegistration, setModalBusinessTypeRegistration] = useState<boolean>(false)
  const [modalEditConsumerUnit, setModalEditConsumerUnit] = useState<boolean>(false)
  const [exitModal, setExitModal] = useState<boolean>(false)
  const [modalDelete, setModalDelete] = useState<boolean>(false)
  const [modalDesassociated, setModalDesassociated] = useState<boolean>(false)
  const [connectSmartMeter, setConnectSmartMeter] = useState<boolean>(false)
  const [connectIntrusiveSmartMeter, setConnectIntrusiveSmartMeter] = useState<boolean>(false)
  const [modalChartFullscreenPie, setModalChartFullscreenPie] = useState<boolean>(false)
  const [modalChartFullscreenArea, setModalChartFullscreenArea] = useState<boolean>(false)
  const [modalChartFullscreenBar, setModalChartFullscreenBar] = useState<boolean>(false)

  const objProvider = {
    modalUserADM,
    modalEditClient,
    modalEditSubCategory,
    modalEditModel,
    modalEditMeter,
    modalEditEquipment,
    modalEditConsumerUnit,
    setEditModalBusinessType,
    modalManagerEquipEdit,
    modalMeterRegistration,
    modalModelRegistration,
    modalEquipmentRegistration,
    modalSubCategoryRegistration,
    modalConsumerUnitRegistration,
    modalBusinessTypeRegistration,
    exitModal,
    modalDelete,
    modalDesassociated,
    connectSmartMeter,
    connectIntrusiveSmartMeter,
    modalChartFullscreenPie,
    modalChartFullscreenArea,
    modalChartFullscreenBar,
    setModalUserADM,
    setModalEditClient,
    setModalEditSubCategory,
    setModalEditModel,
    setModalEditMeter,
    setModalEditEquipment,
    setModalEditConsumerUnit,
    modalEditBusinessType,
    setModalManagerEquipEdit,
    setModalMeterRegistration,
    setModalModelRegistration,
    setModalSubCategoryRegistration,
    setModalEquipmentRegistration,
    setModalConsumerUnitRegistration,
    setModalBusinessTypeRegistration,
    setExitModal,
    setModalDelete,
    setModalDesassociated,
    setConnectSmartMeter,
    setConnectIntrusiveSmartMeter,
    setModalChartFullscreenPie,
    setModalChartFullscreenArea,
    setModalChartFullscreenBar,
  }

  return (
    <ModalContext.Provider
      value={objProvider}
    >
      {children}
    </ModalContext.Provider>
  )
}

function useModalContext() {
  const context = useContext(ModalContext)
  return context
}

export {
  ModalProvider,
  useModalContext
}
