import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChartsCard from "../../components/Cards/ChartsCard"
import { useConsumerUnitContext } from "../../providers/consumerUnitContext";
import { useReportContext } from "../../providers/reportContext";
import { useStateContext } from "../../providers/stateContext";
import { getLast24Hours } from "../../util/getLast24hours";
import styles from './styles.module.scss';
import { CircularProgress } from "@mui/material";
import { equip } from '../../util/types'; // Replace './path/to/equipmentFile' with the correct path to the file




import deepLogo from "../../assets/image/deepMonitorLogo.png";
// import Testechart from "../../components/Charts/Testechart";


export default function Breakdown() {

  const navigate = useNavigate();
  const role = localStorage.getItem("role") || 'Unknown';

  const { selectConsumerUnitId, isGetFetching, dateToCallApiForChartPie, dateToCallApiForChartArea } = useStateContext();
  const { consumerUnitList } = useConsumerUnitContext();
  const { getReportConsumerUnitForHours, reportConsumerUnitForHoursList, reportConsumerUnitForHoursArea, reportConsumerUnitForHoursPie } = useReportContext();
  const [selectedConsumerUnit, setSelectedConsumerUnit] = useState({} as any);
  const [areaChartData, setAreaChartData] = useState([] as any);
  const [pieChartData, setPieChartData] = useState([] as any);

  

const [selectedChart, setSelectedChart] = useState("line");

 //CHANGE: Added new state for selected ID
  const [selectedID, setSelectedID] = useState(""); 

  //CHANGE: handler for changing the selected ID
  const handleIDChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedID(event.target.value);
  }




type ChartTypeToComponentType = {
  [key: string]: string;
};

const chartTypeToComponent: ChartTypeToComponentType = {
  "Tensão": "line",
  "Corrente": "line2",
  "Demanda de Potência": "line3",
  "Energia": "line4",
  "Potência": "line5",
  "Fator Potência": "line6",
  "Temperatura": "line7"
};


type ChartOptionsType = {
  [key: string]: string[];
};

const generateChartOptions = (equipments: { mac: { id: string } }[]) => {
  const chartOptions: ChartOptionsType = {};
  equipments.forEach(equipment => {
    if (equipment.mac && equipment.mac.id) {
      chartOptions[equipment.mac.id] = ["Tensão", "Corrente", "Demanda de Potência", "Energia", "Potência", "Fator Potência", "Temperatura"];
      // Adjust the array above as per each equipment's specifics
    }
  });
  return chartOptions;
};



const [chartOptions, setChartOptions] = useState<ChartOptionsType>({});

useEffect(() => {
  setChartOptions(generateChartOptions(selectedConsumerUnit?.equipments || []));
}, [selectedConsumerUnit.equipments]);






const handleChartChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSelectedChart(chartTypeToComponent[event.target.value]);
}

  // debugger;
  const handleConsumerUnit = () => {
    navigate('/dashboard/consumerUnit')
  }

  const handleEquipmentRegister = () => {
    navigate('/dashboard/equipmentManagement')
  }

  useEffect(() => {
    let hours24 = getLast24Hours()
    getReportConsumerUnitForHours(selectConsumerUnitId, hours24.start, hours24.end, 'all')
  }, [selectConsumerUnitId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(dateToCallApiForChartArea).length > 0) {
      getReportConsumerUnitForHours(selectConsumerUnitId, dateToCallApiForChartArea.start, dateToCallApiForChartArea.end, 'area')
    }
  }, [dateToCallApiForChartArea]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (Object.keys(dateToCallApiForChartArea).length > 0) {
      getReportConsumerUnitForHours(selectConsumerUnitId, dateToCallApiForChartPie.start, dateToCallApiForChartPie.end, 'pie')
    }
  }, [dateToCallApiForChartPie]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPieChartData(reportConsumerUnitForHoursPie)
  }, [reportConsumerUnitForHoursPie]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAreaChartData(reportConsumerUnitForHoursArea)
  }, [reportConsumerUnitForHoursArea]) // eslint-disable-line react-hooks/exhaustive-deps
  
  

  useEffect(() => {
    handleConsumerUnitChange()
  }, [consumerUnitList, selectConsumerUnitId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleConsumerUnitChange = () => {
    consumerUnitList.forEach(consumerUnit => {
      if (consumerUnit.id === selectConsumerUnitId) {
        setSelectedConsumerUnit(consumerUnit)
      }
    })
  }

  if (isGetFetching) {
    return (
      <div className={styles.LoadingScreen}>
        <CircularProgress />
      </div>
    )


  } else if (role==='Tecnico'){     {/*(role==='Tecnico' || 'Admin')*/}

    if (selectedConsumerUnit.equipments
      && selectedConsumerUnit.equipments.length > 0
      && Object.keys(reportConsumerUnitForHoursList).length > 0) {
      
       return (
    <div className={styles.containerTech}>
      <div className={styles.contentTech}>
        <main className={styles.boxTech}>
          <div className={styles.flexContainer}>
            <label className={styles.selectLabel}>Medidor:</label>
            <select 
              className={styles.selectMenu} 
              value={selectedID} 
              onChange={handleIDChange} 
            >
              <option value="">Selecione o medidor</option>
              {
                //CHANGE: map through selectedConsumerUnit.equipments and create options with macId and macName
                selectedConsumerUnit.equipments.map((equipment: equip) => {
                  if(equipment.mac && equipment.mac.name){
                    return (
                      <option key={equipment.name} value={equipment.mac.id}>
                        {equipment.mac.name}
                      </option>
                    )
                  } else {
                    console.error(`equipment with id ${equipment.id} does not have mac or mac.name`);
                    return null; 
                  }
                })
              }
            </select>
            <div>
              <label className={styles.selectLabel}>Tipo de Gráfico:</label>
              <select className={styles.selectMenu} onChange={handleChartChange}>
                {
                  //CHANGE: using selectedID instead of hardcoded ID
                  chartOptions[selectedID] ? chartOptions[selectedID].map((option: string) => (
                    <option key={option} value={option}>{option}</option>
                  )) : <option>Medidor Inválido</option>
                }
              </select>
            </div>
          </div>
          <div>
            {selectedChart && (
              <div>
                <ChartsCard
                  charts={selectedChart}
                  title={Object.keys(chartTypeToComponent).find(key => chartTypeToComponent[key] === selectedChart)}
                  stylesCard="largeTecnico"
                  dataChart
                  //CHANGE: passing selectedID and setSelectedID to ChartsCard
                  selectedID={selectedID}
                  setSelectedID={setSelectedID}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  )
}
    else if (selectedConsumerUnit.equipments && selectedConsumerUnit.equipments.length === 0) {
      return (
        
          <div className={styles.containerTech}>
            
            <div className={styles.contentTech}>
              <main className={styles.noUnitConsumerTech}>
                <span>Não foi encontrado nenhum equipamento cadastrado, deseja cadastrar um novo?</span>
                <button onClick={handleEquipmentRegister}>
                  Cadastrar / Equipamento
                </button>
              </main>
            </div>
          </div>
       
      )
    }
    else if (consumerUnitList.length === 0) {
      return (
        <div className={styles.containerTech}>
          <div className={styles.sideContainerTech} />
          <div className={styles.contentTech}>
            <main className={styles.noUnitConsumerTech}>
              <span>Não foi encontrada nenhuma unidade consumidora cadastrada, deseja cadastrar uma nova?</span>
              <button onClick={handleConsumerUnit}>
                Cadastrar / Unidade Consumidora
              </button>
            </main>
          </div>
        </div>
      )
    }
   
  

  } 
if (role === 'Cliente' || role==='Admin') {
  


    if (selectedConsumerUnit.equipments
  && selectedConsumerUnit.equipments.length > 0
  && Object.keys(reportConsumerUnitForHoursList).length > 0) {
  
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <main className={styles.box}>
              <div className={styles.ola}><img src={deepLogo} alt="Deep Monitor" />
              </div>
              <div className={styles.firstContainerChart}>
                
                
                  <ChartsCard  charts="pie" title="Consumo Acumulado" stylesCard="small" dataChart={pieChartData/* .length > 0 ? pieChartData : reportConsumerUnitForHoursList */} setSelectedID selectedID />
                
                
                  <ChartsCard charts="area" title="Consumo em Tempo Real" stylesCard="middle" dataChart={areaChartData/* .length > 0 ? areaChartData : reportConsumerUnitForHoursList */} setSelectedID selectedID />
                
              </div>
              <div className={styles.bar}>
                <ChartsCard charts="bar" title="Média de Consumo" stylesCard="large" dataChart={reportConsumerUnitForHoursList} setSelectedID selectedID />
              </div>
            </main>
          </div>
        </div >
      )
    }
    else if (selectedConsumerUnit.equipments && selectedConsumerUnit.equipments.length === 0) {

      return (
        
          <div className={styles.container}>
            
            <div className={styles.content}>
              <main className={styles.noUnitConsumer}>
                <span>Não foi encontrado nenhum equipamento cadastrado, deseja cadastrar um novo?</span>
                <button onClick={handleEquipmentRegister}>
                  Cadastrar / Equipamento
                </button>
              </main>
            </div>
          </div>
       
      )
    }
    else if (consumerUnitList.length === 0) {

      return (
        <div className={styles.container}>
          <div className={styles.sideContainer} />
          <div className={styles.content}>
            <main className={styles.noUnitConsumer}>
              <span>Não foi encontrada nenhuma unidade consumidora cadastrada, deseja cadastrar uma nova?</span>
              <button onClick={handleConsumerUnit}>
                Cadastrar / Unidade Consumidora
              </button>
            </main>
          </div>
        </div>
      )
    }
  


  }
  return (
    <div className={styles.LoadingScreen}>
      <CircularProgress />
    </div>
  )
}