import { useEffect } from "react";
import React, { useState, useMemo, useRef } from "react";
import styles from "./styles.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LinearProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import ReactToPrint from 'react-to-print';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryArea,
  VictoryBar,
  VictoryLegend,
} from "victory";
import { getReportKhompDataAPI } from "../../../services/restApiReport";

export default function FatorPotenciaChart({ selectedID, setSelectedID }) {

  
  
  const [progress, setProgress] = useState(0);
  
  const [chartWidth, setChartWidth] = useState(1200);
const [chartHeight, setChartHeight] = useState(500);
const [chartPadding, setChartPadding] = useState({ bottom: 120, left: 60, right: 50, top: 70 });
const [legendCoordinates, setLegendCoordinates] = useState({ x: 60, y: 540 });



// Function to adjust chart properties based on screen size
function adjustChartProperties() {
  // Check if the window width is less than or equal to 768px
  const isSmallScreen = window.matchMedia("(max-width: 900px)").matches;

  if (isSmallScreen) {
    // Update chart properties for small screens
    setChartWidth(500);
    setChartHeight(700);
    setChartPadding({ bottom: 80, left: 40, right: 30, top: 90 });
    setLegendCoordinates({ x: 20, y: 650 });
  } else {
    // Update chart properties for large screens
    setChartWidth(1200);
    setChartHeight(700);
    setChartPadding({ bottom: 120, left: 60, right: 50, top: 70 });
    setLegendCoordinates({ x: 40, y: 660 });
  }
}

// Call the function when the page loads
useEffect(() => {
  adjustChartProperties();
}, []);

// Add an event listener to call the function when the window is resized
useEffect(() => {
  window.addEventListener("resize", adjustChartProperties);
  // Remove the event listener when the component is unmounted
  return () => window.removeEventListener("resize", adjustChartProperties);
}, []);








let today = new Date();
let startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
let endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

const [startDate, setStartDate] = useState(startOfDay);
const [endDate, setEndDate] = useState(endOfDay);

const [maxLineValue, setMaxLineValue] = useState(0);
const [minLineValue, setMinLineValue] = useState(Infinity);
// Get the number of days in the date range


 const generateHoursBetween = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setHours(dt.getHours() + 1);
  }
  return arr;
};


const generateDatesBetween = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};


const componentRef = useRef();

 const [khompDataFPR, setkhompDataFPR] = useState([]);
 const [khompDataFPS, setkhompDataFPS] = useState([]);
 const [khompDataFPT, setkhompDataFPT] = useState([]);

function downloadExcelFile() {
  // Combine all data arrays into one
   const allData = [...khompDataFPR, ...khompDataFPS, ...khompDataFPT];
  
  // Generate a worksheet
  const ws = XLSX.utils.json_to_sheet(allData);
  
  // Create a new Workbook
  const wb = XLSX.utils.book_new();
  
  // Append the worksheet to the Workbook
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  
  // Write the Workbook to an Excel file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  
  // Create a blob from the Excel file
  let blob = new Blob([s2ab(wbout)], {type: 'application/octet-stream'});
  
  // Create a link element
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = 'data.xlsx';
  
  // Append the link to the body and simulate a click event to start the download
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// This is needed to convert the string to array buffer
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}





async function mountedArrayForKhomp(variableName) {
    try {
      const serverStartDate = new Date(startDate.getTime() - (3 * 60 * 60 * 1000));
      const serverEndDate = new Date(endDate.getTime() - (3 * 60 * 60 * 1000));

      const khompDataGet = await getReportKhompDataAPI(
        "847127FFFE1CB050",
        serverStartDate.toISOString(),
        serverEndDate.toISOString(),
        variableName
      );

      const chartData = khompDataGet.data.values
        .map((item) => {
            const itemDate = new Date(item.timestamp);
            if (itemDate >= serverStartDate && itemDate <= serverEndDate) {
                let transformedValue = 0;

                if (item.value >= 0) {
                    transformedValue = 1 - item.value;
                } else {
                    transformedValue = - (item.value + 1);
                }
            
                return { x: itemDate, y: transformedValue };
            }
            return null;
        })
        .filter(item => item !== null);

      return chartData;
    } catch (error) {
      console.error("Error fetching Khomp data:", error);
      return [];
    }
}

const [isIdInvalid, setIsIdInvalid] = useState(false); // Add this line

const [isDataInvalid, setIsDataInvalid] = useState(false); // Add this line




useEffect(() => {
  async function fetchData() {
    // Initialize progress to 10
    setProgress(10);

    // Clear the current data
    setkhompDataFPR([]);
    setkhompDataFPS([]);
    setkhompDataFPT([]);
    setIsIdInvalid(false);
    setIsDataInvalid(false);


    if (selectedID === '847127FFFE1CB050') {
      // Fetch the FPR data and update the progress
      const FPRData = await mountedArrayForKhomp("FPR");
      setProgress(20);

      // Fetch the FPS data and update the progress
      const FPSData = await mountedArrayForKhomp("FPS");
      setProgress(40);

      // Fetch the FPT data and update the progress
      const FPTData = await mountedArrayForKhomp("FPT");
      setProgress(60);

      // Find the maximum and minimum y-value in the data for FPR, FPS and FPT
      let maxValue = Math.max(
        FPRData.reduce((max, item) => Math.max(max, item.y), 0),
        FPSData.reduce((max, item) => Math.max(max, item.y), 0),
        FPTData.reduce((max, item) => Math.max(max, item.y), 0)
      );

      let minValue = Math.min(
        FPRData.reduce((min, item) => Math.min(min, item.y), Infinity),
        FPSData.reduce((min, item) => Math.min(min, item.y), Infinity),
        FPTData.reduce((min, item) => Math.min(min, item.y), Infinity)
      );

      // If the maximum value is more than the current maxLineValue, update it
      if(maxValue > maxLineValue) {
        setMaxLineValue(maxValue);
      }

      // If the minimum value is less than the current minLineValue, update it
      if(minValue < minLineValue) {
        setMinLineValue(minValue);
      }

      // Set the data in state
      setkhompDataFPR(FPRData);
      setkhompDataFPS(FPSData);
      setkhompDataFPT(FPTData);
    } else if (selectedID === 'ftteng.ddns.net:502') {
      // Do nothing, no data will be displayed
      setIsDataInvalid(true);
    } else {
      // Handle the case where selectedID is neither "ftteng.ddns.net:502" nor "847127FFFE1CB050"
      console.log(`Unexpected selectedID: ${selectedID}`);
      setIsIdInvalid(true);
    }

    // Add delay
    await new Promise(resolve => setTimeout(resolve, 2000));
    setProgress(80);

    // Simulate rendering progress
    setTimeout(() => {
      setProgress(90);
    }, 1000);

    // Simulate rendering completion
    setTimeout(() => {
      setProgress(100);
    }, 2000);
  }

  fetchData();
}, [startDate, endDate, selectedID]); // Add selectedID to the dependencies







    
  if (progress === 100) {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.datePickerContainer}>
  <label htmlFor="startDate" className={styles.datePickerLabel}>Data Início:</label>
  <DatePicker
    id="startDate"
    selected={startDate}
    onChange={(date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()))}
    className={styles.datePicker}
  />

  <label htmlFor="endDate" className={styles.datePickerLabel}>Data Final:</label>
  <DatePicker
    id="endDate"
    selected={endDate}
    onChange={(date) => setEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))}
    className={styles.datePicker}
  />

  <button onClick={downloadExcelFile} className={`${styles.datePicker} ${styles.buttonSpacing}`}>Baixar Excel</button>
  <ReactToPrint
          trigger={() => <button className={styles.datePicker}>Exportar relatório</button>}
          content={() => componentRef.current}
          
        />
        <div ref={componentRef} >
      </div>
      
        
</div>
{isIdInvalid && <div className={styles.noId}>Selecione um medidor para renderizar o Gráfico</div>}
{isDataInvalid && <div className={styles.noId}>Sem dados para esse Medidor</div>}
      <div className={styles.chartContainer} style={{ zIndex: 1 }}>
        {khompDataFPR.length > 0 ||
        khompDataFPS.length > 0 ||
        khompDataFPT.length > 0 ? (
          <div className={styles.chartWrapper}>
            {/* Add a wrapper div for the chart */}
            <div ref={componentRef} className={styles.outerContainer}>
              <VictoryChart
                width={chartWidth}
                height={chartHeight}
                padding={chartPadding}
              >
  


      <VictoryAxis
      offsetY={100}
  style={{
    axis: { stroke: "grey" },
    ticks: { stroke: "grey" },
    tickLabels: {
      fill: "grey",
       //padding: 30,
       dx: 30,
      angle: 45, // Add this line to make the labels display at a 45 degree angle
      textAnchor: 'start', // To align the text correctly at 45 degrees
      verticalAnchor: 'middle', // To align the text vertically
    },
    grid: { stroke: "grey", strokeWidth: 0.25 },
  }}
  label=""
  tickValues={
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
      ? generateHoursBetween(new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), new Date(endDate.getTime() - (3 * 60 * 60 * 1000)))
      : generateDatesBetween(new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), new Date(endDate.getTime() - (3 * 60 * 60 * 1000)))
  }
  tickFormat={(tick) => 
  {
    // Create a new date object from the tick, and add 3 hours to it
   let newDate = new Date(tick.getTime() + (3 * 60 * 60 * 1000));

    // Then you can format the newDate object as you wish:
    if (startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear())
    {
       return `${('0' + newDate.getHours()).slice(-2)}:00`; // This will format the tick values as hours
    }
    else 
    {
      return `${newDate.getDate()}-${('0'+(newDate.getMonth()+1)).slice(-2)}-${('0'+newDate.getFullYear()).slice(-2)}`; // This will format the tick values as YYYY-MM-DD
    }
  }
}
/>
        <VictoryAxis
  dependentAxis
  label=""
  domain={[-1, 1]}
  style={{
    axis: { stroke: "grey", zIndex: 2 },
    tickLabels: { fill: "grey", fontSize: 12, },
    grid: { stroke: "none"},
    axisLabel: { padding: 35, fontSize: 20 }
  }}
  tickValues={[-1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
  tickFormat={(t) => {
  let transformedTick = 0;
  if (t >= 0) {
    transformedTick = 1 - t;
  } else {
    transformedTick = -(1 - Math.abs(t));
  }
  return `${transformedTick.toFixed(1)} `;
}}
/>

          
           

  <VictoryLine
    style={{ data: { stroke: '#CD5C5C' } }}
    data={khompDataFPR}
  />
  <VictoryLine
    style={{ data: { stroke: '#0E85CA' } }}
    data={khompDataFPS}
  />
  <VictoryLine
    style={{ data: { stroke: 'black' } }}
    data={khompDataFPT}
  />
  {/*<VictoryLine
  style={{
    data: { fill: "yellow", opacity: 0.3, stroke: "none" }, // Change the fill color to yellow for the second area
  }}
  data={areaData2} // Use the second area data
/>

            <VictoryLine
  style={{
    data: { fill: "red", opacity: 0.3, stroke: "none" }, // You can adjust opacity for better visibility
  }}
  data={areaData}
/>*/}

<VictoryLine
  style={{ data: { stroke: '#ff0000' } }} // Red line
  data={[
    { 
      x: new Date(startDate.getTime() - 3 * 60 * 60 * 1000), 
      y: 1 - 0.92 
    }, 
    { 
      x: new Date(endDate.getTime() - 3 * 60 * 60 * 1000), 
      y: 1 - 0.92 
    }
  ]}
/>
<VictoryLine
  style={{ data: { stroke: '#ff0000' } }} // Red line
  data={[
    { 
      x: new Date(startDate.getTime() - 3 * 60 * 60 * 1000), 
      y: -(-0.92+1) 
    }, 
    { 
      x: new Date(endDate.getTime() - 3 * 60 * 60 * 1000), 
      y: -(-0.92+1) 
    }
  ]}
/>
  <VictoryLegend
        x={legendCoordinates.x}
  y={legendCoordinates.y} // Adjust these values to position the legend
    centerTitle
    orientation="horizontal"
    gutter={10}
    style={{
      title: { fontSize: 20 }, 
      labels: { fontSize: 20 } 
    }}
    data={[
                  { name: "Fator Potência R\u00A0\u00A0\u00A0", symbol: { fill: "#CD5C5C", type: "circle", size: 10 } },
                  { name: "Fator Potência S\u00A0\u00A0\u00A0", symbol: { fill: "#0E85CA", type: "circle", size: 10 } },
            { name: "Fator Potência T\u00A0\u00A0\u00A0", symbol: { fill: "black", type: "circle", size: 10 } },
                  
                ]}
        />
</VictoryChart>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
} else {
  return (
    <div className={styles.outerContainer}>
      <h2>
  {progress < 40
    ? 'Carregando Dados'
    : progress < 60
    ? 'Gerando Gráfico'
    : progress < 80
    ? 'Quase pronto!'
    : '99%'}
</h2>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}}