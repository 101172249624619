import { useEffect } from "react";
import React, { useState, useMemo, useRef } from "react";
import styles from "./styles.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
import ReactToPrint from 'react-to-print';
import { LinearProgress } from '@mui/material';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryArea,
  VictoryLegend,
} from "victory";
import { getReportModbusDataAPI, getReportKhompDataAPI } from "../../../services/restApiReport";
import { VictoryVoronoiContainer, VictoryTooltip } from "victory";

export default function TensaoChart({ selectedID, setSelectedID }) {
  
  
const [progress, setProgress] = useState(0);

const [chartWidth, setChartWidth] = useState(1200);
const [chartHeight, setChartHeight] = useState(500);
const [chartPadding, setChartPadding] = useState({ bottom: 120, left: 60, right: 50, top: 70 });
const [legendCoordinates, setLegendCoordinates] = useState({ x: 60, y: 540 });



// Function to adjust chart properties based on screen size
function adjustChartProperties() {
  // Check if the window width is less than or equal to 768px
  const isSmallScreen = window.matchMedia("(max-width: 900px)").matches;

  if (isSmallScreen) {
    // Update chart properties for small screens
    setChartWidth(500);
    setChartHeight(700);
    setChartPadding({ bottom: 80, left: 40, right: 30, top: 90 });
    setLegendCoordinates({ x: 20, y: 650 });
  } else {
    // Update chart properties for large screens
    setChartWidth(1200);
    setChartHeight(700);
    setChartPadding({ bottom: 120, left: 60, right: 50, top: 70 });
    setLegendCoordinates({ x: 40, y: 660 });
  }
}

// Call the function when the page loads
useEffect(() => {
  adjustChartProperties();
}, []);

// Add an event listener to call the function when the window is resized
useEffect(() => {
  window.addEventListener("resize", adjustChartProperties);
  // Remove the event listener when the component is unmounted
  return () => window.removeEventListener("resize", adjustChartProperties);
}, []);





let today = new Date();
let startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
let endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

const [startDate, setStartDate] = useState(startOfDay);
const [endDate, setEndDate] = useState(endOfDay);


const generateHoursBetween = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setHours(dt.getHours() + 1);
  }
  return arr;
};


const generateDatesBetween = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};



const componentRef = useRef();
  
const [modbusDataVL1N, setModbusDataVL1N] = useState([]);
const [modbusDataVL2N, setModbusDataVL2N] = useState([]);
const [modbusDataVL3N, setModbusDataVL3N] = useState([]);

const [khompDataVR, setKhompDataVR] = useState([]);
const [khompDataVS, setKhompDataVS] = useState([]);
const [khompDataVT, setKhompDataVT] = useState([]);







  function downloadExcelFile() {
  // Combine all data arrays into one
  const allData = [...modbusDataVL1N, ...modbusDataVL2N, ...modbusDataVL3N, ...khompDataVR, ...khompDataVS, ...khompDataVT];
  
  // Generate a worksheet
  const ws = XLSX.utils.json_to_sheet(allData);
  
  // Create a new Workbook
  const wb = XLSX.utils.book_new();
  
  // Append the worksheet to the Workbook
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  
  // Write the Workbook to an Excel file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  
  // Create a blob from the Excel file
  let blob = new Blob([s2ab(wbout)], {type: 'application/octet-stream'});
  
  // Create a link element
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = 'data.xlsx';
  
  // Append the link to the body and simulate a click event to start the download
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// This is needed to convert the string to array buffer
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}


  async function mountedarrayformodbus(variableName) {
    try {
      // Get the date in the server's timezone
      const serverStartDate = new Date(startDate.getTime() - (3 * 60 * 60 * 1000));
      const serverEndDate = new Date(endDate.getTime() - (3 * 60 * 60 * 1000));

      const modbusDataget = await getReportModbusDataAPI(
        "ftteng.ddns.net:502",
        serverStartDate.toISOString(),
        serverEndDate.toISOString(),
        variableName
      );
      
      const chartData = modbusDataget.data.values        
        .map((item) => ({ x: new Date(item.timestamp), y: (item.value * 220) / 16384 }));

      return chartData;
    } catch (error) {
      console.error("Error fetching modbus data:", error);
      return [];
    }
  }

  async function mountedArrayForKhomp(variableName) {
    try {
      // Get the date in the server's timezone
      const serverStartDate = new Date(startDate.getTime() - (3 * 60 * 60 * 1000));
      const serverEndDate = new Date(endDate.getTime() - (3 * 60 * 60 * 1000));

      const khompDataGet = await getReportKhompDataAPI(
        "847127FFFE1CB050",
        serverStartDate.toISOString(),
        serverEndDate.toISOString(),
        variableName
      );

      const chartData = khompDataGet.data.values
        .map((item) => ({ x: new Date(item.timestamp), y: (item.value )  }));

      return chartData;
    } catch (error) {
      console.error("Error fetching Khomp data:", error);
      return [];
    }
  }

  const [isIdInvalid, setIsIdInvalid] = useState(false); // Add this line
 
  useEffect(() => {
  async function fetchData() {
    // Initialize progress to 10
    setProgress(10);

    if (selectedID === "ftteng.ddns.net:502") {
      // Fetch the data and update the progress for Modbus
      const VL1NData = await mountedarrayformodbus("VL1N");
      setProgress(30);
      const VL2NData = await mountedarrayformodbus("VL2N");
      setProgress(50);
      const VL3NData = await mountedarrayformodbus("VL3N");
      setProgress(70);

      // Set the data in state
      setModbusDataVL1N(VL1NData);
      setModbusDataVL2N(VL2NData);
      setModbusDataVL3N(VL3NData);

      // Reset Khomp data
      setKhompDataVR([]);
      setKhompDataVS([]);
      setKhompDataVT([]);
       setIsIdInvalid(false); // Add this line
    } else if (selectedID === "847127FFFE1CB050") {
      // Fetch the data and update the progress for Khomp
      const VRData = await mountedArrayForKhomp("VR");
      setProgress(30);
      const VSData = await mountedArrayForKhomp("VS");
      setProgress(50);
      const VTData = await mountedArrayForKhomp("VT");
      setProgress(70);

      // Set the data in state
      setKhompDataVR(VRData);
      setKhompDataVS(VSData);
      setKhompDataVT(VTData);

      // Reset Modbus data
      setModbusDataVL1N([]);
      setModbusDataVL2N([]);
      setModbusDataVL3N([]);
       setIsIdInvalid(false); // Add this line
    }
     else {// Handle the case where selectedID is neither "ftteng.ddns.net:502" nor "847127FFFE1CB050"
      console.log(`Unexpected selectedID: ${selectedID}`);
       setIsIdInvalid(true); // Add this line
      
     }
    // Simulate rendering completion
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }
  

  fetchData();
}, [startDate, endDate, selectedID]); 




  // Function to handle ID selection change
  const handleIDChange = (event) => {
    setSelectedID(event.target.value);
  }

  
 
  

 


  const [yTickValues, setYTickValues] = useState([]);
const [yDomain, setYDomain] = useState([90, 150]);
const [areaDomains, setAreaDomains] = useState([
  {start: 135, end: 150},
  {start: 133, end: 135},
  {start: 117, end: 133},
  {start: 110, end: 117},
  {start: 90, end: 110},
]);

// useEffect that adjusts yDomain, yTickValues, and areaDomains
useEffect(() => {
  if (selectedID === "847127FFFE1CB050") {
    setYDomain([150, 250]);
    const ticks = [];
    for (let i = 150; i <= 250; i += 10) {
      ticks.push(i);
    }
    setYTickValues(ticks);
    setAreaDomains([
      {start: 233, end: 250},
      {start: 231, end: 233},
      {start: 202, end: 231},
      {start: 191, end: 202},
      {start: 150, end: 191},
    ]);
  } else {
    setYDomain([90, 150]);
    const ticks = [];
    for (let i = 90; i <= 150; i += 10) {
      ticks.push(i);
    }
    setYTickValues(ticks);
    setAreaDomains([
      {start: 135, end: 150},
      {start: 133, end: 135},
      {start: 117, end: 133},
      {start: 110, end: 117},
      {start: 90, end: 110},
    ]);
  }
}, [selectedID]);

const colors = ["#d3a98a", "#fffea1", "#cfeeb0", "#fffea1", "#d3a98a"];

  if (progress === 100) {
  return (
    
    <div className={styles.outerContainer}>
      
      <div className={styles.datePickerContainer}>
  <label htmlFor="startDate" className={styles.datePickerLabel}>Data Início:</label>
  <DatePicker
    id="startDate"
    selected={startDate}
    onChange={(date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()))}
    className={styles.datePicker}
  />

  <label htmlFor="endDate" className={styles.datePickerLabel}>Data Final:</label>
  <DatePicker
    id="endDate"
    selected={endDate}
    onChange={(date) => setEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))}
    className={styles.datePicker}
  />
<button onClick={downloadExcelFile} className={`${styles.datePicker} ${styles.buttonSpacing}`}>Baixar Excel</button>
  
  <ReactToPrint
          trigger={() => <button className={styles.datePicker}>Exportar relatório</button>}
          content={() => componentRef.current}
          
        />
        <div ref={componentRef} >
      </div>
      
        
</div>
      
      {isIdInvalid && <div className={styles.noId}>Selecione um medidor para renderizar o Gráfico</div>}
      
      <div className={styles.chartContainer} style={{ zIndex: 1 }}>
        {(khompDataVR.length > 0 ||
  khompDataVS.length > 0 ||
  khompDataVT.length > 0 ||
  modbusDataVL1N.length > 0 ||
  modbusDataVL2N.length > 0 ||
  modbusDataVL3N.length > 0) ? (
    
            <div className={styles.chartWrapper}> {/* Add a wrapper div for the chart */}
            <div ref={componentRef} className={styles.outerContainer}>
              
          <VictoryChart 
  //containerComponent={<VictoryVoronoiContainer />}
  width={chartWidth}
  height={chartHeight}
  domain={{ y: yDomain }}
  padding={chartPadding}
>
         {areaDomains.map((domain, index) => (
  <VictoryArea
    style={{ data: { fill: colors[index % colors.length] } }}
    data={[
      { x: new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), y: domain.start },
      { x: new Date(endDate.getTime() - (3 * 60 * 60 * 1000)), y: domain.end },
      { x: new Date(endDate.getTime() - (3 * 60 * 60 * 1000)), y: domain.start },
      { x: new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), y: domain.end },
    ]}
  />
))}

            <VictoryAxis
  style={{
    axis: { stroke: "grey" },
    ticks: { stroke: "grey" },
    tickLabels: {
      fill: "grey",
      angle: 45, // Add this line to make the labels display at a 45 degree angle
      textAnchor: 'start', // To align the text correctly at 45 degrees
      verticalAnchor: 'middle', // To align the text vertically
    },
    grid: { stroke: "grey", strokeWidth: 0.25 },
  }}
  label=""
  tickValues={
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
      ? generateHoursBetween(new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), new Date(endDate.getTime() - (3 * 60 * 60 * 1000)))
      : generateDatesBetween(new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), new Date(endDate.getTime() - (3 * 60 * 60 * 1000)))
  }
  tickFormat={(tick) => 
  {
    // Create a new date object from the tick, and add 3 hours to it
    let newDate = new Date(tick.getTime() + (3 * 60 * 60 * 1000));

    // Then you can format the newDate object as you wish:
    if (startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear())
    {
       return `${('0' + newDate.getHours()).slice(-2)}:00`; // This will format the tick values as hours
    }
    else 
    {
      return `${newDate.getDate()}-${('0'+(newDate.getMonth()+1)).slice(-2)}-${('0'+newDate.getFullYear()).slice(-2)}`; // This will format the tick values as YYYY-MM-DD
    }
  }
}
/>
            <VictoryAxis
  dependentAxis
  label="V"
  domain={{ y: yDomain }}
  tickValues={yTickValues}
  style={{
    axis: { stroke: "grey", zIndex: 2 },
    //ticks: { stroke: "grey", zIndex: 2 },
    tickLabels: { fill: "grey", fontSize: 12 },
    grid: { stroke: "none"},
    axisLabel: { padding: 35, fontSize: 20 } // Adjust the padding to position the label correctly.
  }}
  tickFormat={(t) => `${t} `} // Add the unit "V" to each tick value
/>
            <VictoryLine
    //labels={({ datum }) => `VL1N: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: "#CD5C5C" },
    }}
    data={modbusDataVL1N}
  />
  <VictoryLine
    //labels={({ datum }) => `VL2N: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: '#0E85CA' }
    }}
    data={modbusDataVL2N}
  />
  <VictoryLine
    //labels={({ datum }) => `VL3N: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: 'black' }
    }}
    data={modbusDataVL3N}
  />


  <VictoryLine
    //labels={({ datum }) => `VL1N: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: "#CD5C5C" },
    }}
    data={khompDataVR}
  />
  <VictoryLine
    //labels={({ datum }) => `VL2N: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: '#0E85CA' }
    }}
    data={khompDataVS}
  />
  <VictoryLine
    //labels={({ datum }) => `VL3N: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: 'black' }
    }}
    data={khompDataVT}
  />
         
  <VictoryLegend
 x={legendCoordinates.x}
  y={legendCoordinates.y}
    centerTitle
    orientation="horizontal"
    gutter={10}
    style={{
      title: { fontSize: 20 }, 
      labels: { fontSize: 20 } 
    }}
    data={[
    { name: "Tensão R\u00A0\u00A0\u00A0", symbol: { fill: "#CD5C5C", type: "circle", size: 10 } },
    { name: "Tensão S\u00A0\u00A0\u00A0", symbol: { fill: "#0E85CA", type: "circle", size: 10 } },
    { name: "Tensão T\u00A0\u00A0\u00A0", symbol: { fill: "black", type: "circle", size: 10 } },
  ]}
  />

        </VictoryChart>
        </div>
          </div>
        ) : null}
      </div>
    </div>
  );
} else {
  return (
    <div className={styles.outerContainer}>
      <h2>
        {progress < 40
          ? 'Carregando Dados'
          : progress < 60
          ? 'Gerando Gráfico'
          : progress < 80
          ? 'Quase pronto!'
          :"99%"}
      </h2>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}}