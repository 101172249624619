import api from '../../services/api';

const useRefreshToken = () => {
  const accessToken = localStorage.getItem('accessToken') || null;
  const refreshToken = localStorage.getItem('refreshToken') || null;
  const refresh = async () => {
    let bodyRefresh = {
      refreshToken: refreshToken,
      accessToken: accessToken
    }

    const resp = await api.post('/api/Auth/refresh', bodyRefresh);
    localStorage.setItem('accessToken', resp.data.accessToken);
    localStorage.setItem('refreshToken', resp.data.refreshToken);

    return resp.data.accessToken
  }

  return refresh
}

export default useRefreshToken