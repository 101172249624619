import api from './api'

export function getAllEquipment() {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get('/api/Equipment', config)
}

export function getEquipmentById(id: string | number) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/Equipment/${id}`, config)
}

export function getEquipmentByConsumerUnitId(id: number | string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/Equipment?consumerUnitId=${id}`, config)
}

export function postEquipment(equip: any) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.post('/api/Equipment', equip, config)
}

export function putEquipment(equip: any, id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.put(`/api/Equipment/${parseInt(id)}`, equip, config)
}

export function delEquipment(id: number | string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.delete(`/api/Equipment/${id}`, config)
}

export function connectEquipmentWithSmartMeter(id: any, mac: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.put(`/api/Equipment/${id}/mac/testetest2test`, config)
}

// smart meter
export function getAllSmartMeter() {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get('/api/SmartMeter', config)
}

export function getSmartMeterById(id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/SmartMeter/${id}`, config)
}

export function getSmartMeterByConsumerUnitId(id: number) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.get(`/api/SmartMeter?consumerUnitId=${id}`, config)
}

export function postSmartMeter(model: any) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.post('/api/SmartMeter', model, config)
}

export function putSmartMeter(model: any, id: string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.put(`/api/SmartMeter/${parseInt(id)}`, model, config)
}

export function delSmartMeter(id: number | string) {
  const accessTokenValue = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenValue}`
    }
  }

  return api.delete(`/api/SmartMeter/${id}`, config)
}