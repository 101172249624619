import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { Pagination } from "@material-ui/lab";

import { ModalEditMeter } from "../../../Modal/EditMeter";
import { useModalContext } from "../../../../providers/modalContext";
import styles from "./styles.module.scss";
import { usePagination } from "../../../../util/hooks/pagination";
import { DeleteItemModal } from "../../../Modal/DeleteItem";
import { useMeterContext } from "../../../../providers/meterContext";

export function IntrusiveMeterTab({ intrusiveMeter, associated }: any) {
  const { setModalEditMeter, setModalDelete } = useModalContext();
  const { deleteMeter } = useMeterContext()
  let [page, setPage] = useState(1);
  const [meterEdit, setMeterEdit] = useState();
  const [meterId, setMeterId] = useState(0);
  const [meterName, setMeterName] = useState();

  const PER_PAGE = 8;

  const count = Math.ceil(intrusiveMeter.length / PER_PAGE);
  const _DATA = usePagination(intrusiveMeter, PER_PAGE);

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleEditMeter = (meter: any) => {
    setMeterEdit(meter)
    setModalEditMeter(true);
  };

  const handleDeleteMeter = (meter: any) => {
    setMeterName(meter.name)
    setMeterId(meter.id)
    setModalDelete(true)
  }

  return (
    <main className={styles.mainContainer}>
      <section className={styles.section}>
        <div>
          <div className={styles.itemContainer}>
            <div className={styles.titletable}>
              <div className={styles.contentHeader}>
                <h5>Nome</h5>
              </div>
              <div className={styles.contentHeader}>
                <h5>Identificadoraa</h5>
              </div>
              <div className={styles.contentHeader}>
                <h5>Canais</h5>
              </div>
              <div />
            </div>
            {
              intrusiveMeter &&
              _DATA.currentData().map((meter: any, index: any) => {
                return (
                  <div key={index}>
                    <div className={styles.mainContent}>
                      <div className={styles.contentBox}>
                        <div className={styles.contentText}>
                          <h4>{meter.name}</h4>
                        </div>
                      </div>
                      <div className={styles.contentBox2}>
                        <div className={styles.contentText}>
                          <h4>{meter.id}</h4>
                        </div>
                      </div>
                      <div className={styles.canais}>
                        <div className={styles.canaisContext}>
                          <div className={styles.channelsContainer}>
                            <span className={styles.tooltip}>
                              <div className={meter.equipmentR !== null ? styles.occupation : styles.available} />
                              R
                              {meter.equipmentR !== null ?
                                <div className={styles.TooltipTextOccupation}>Indisponível</div> :
                                <div className={styles.TooltipTextAvailable}>Disponível</div>
                              }
                            </span>
                          </div>
                          <div className={styles.channelsContainer}>
                            <span className={styles.tooltip}>
                              <div className={meter.equipmentS !== null ? styles.occupation : styles.available} />
                              S
                              {meter.equipmentS !== null ?
                                <span className={styles.TooltipTextOccupation}>Indisponível</span> :
                                <span className={styles.TooltipTextAvailable}>Disponível</span>
                              }
                            </span>
                          </div>
                          <div className={styles.channelsContainer}>
                            <span className={styles.tooltip}>
                              <div className={meter.equipmentT !== null ? styles.occupation : styles.available} />
                              T
                              {meter.equipmentT !== null ?
                                <span className={styles.TooltipTextOccupation}>Indisponível</span> :
                                <span className={styles.TooltipTextAvailable}>Disponível</span>
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.contentBox3}>
                        <div className={styles.contentItem}>
                          <button onClick={() => handleEditMeter(meter)}>
                            <EditIcon />
                          </button>
                          <button onClick={() => handleDeleteMeter(meter)}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*      <div className={styles.containerButtons}>
                      <button
                        onClick={() => navigate("/dashboard")}
                      >
                        Ver Monitoramento
                      </button>
                    </div> */}
                    <div className={styles.dividerHorizontal} />
                  </div>
                )
              })
            }
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mb: "1.25rem !important",
              ul: {
                display: "flex",
                flexDirection: "row",
              }
            }}
          >
            {/*<Pagination
              count={count}
              size="large"
              //page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
        />*/}
          </Box>
        </div>
      </section>
      {meterEdit ? (
        <ModalEditMeter meterEdit={meterEdit} title="Setor/Aparelho" />
      ) : (
        <div />
      )}
      <DeleteItemModal id={meterId} name={meterName} functionDelete={() => deleteMeter(meterId)} />
    </main>
  );
}
