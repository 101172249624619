import styles from './styles.module.scss'

export default function CustomButton({ onClick, type, className, title }: React.ComponentProps<'button'>) {
  function checkStyleButton(styleButton: string | undefined) {
    switch (styleButton) {
      case 'smaller':
        return styles.smallerButton
      case 'small':
        return styles.smallButton
      case 'middle':
        return styles.middleButton
      case 'large':
        return styles.largeButton
      case 'smallOutline':
        return styles.smallOutlineButton
      case 'middleOutline':
        return styles.middleOutlineButton
      case 'largeOutline':
        return styles.largeOutlineButton
      case 'middleOutLineRed':
        return styles.middleOutlineButtonRed
      case 'delete':
        return styles.deleteButton
    }
  }

  return (
    <button
      type={type}
      className={checkStyleButton(className)}
      onClick={onClick}
    >
      {title}
    </button>
  )
}
