import { useNavigate, Link } from "react-router-dom";

import lampada from "../../assets/image/lampada.jpg";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import PowerIcon from "@mui/icons-material/Power";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Logo from "../../assets/image/deep-ambiente-logo.svg";
import styles from "./styles.module.scss";
import { useStateContext } from "../../providers/stateContext";
import { useAuth } from "../../providers/authContext";
import { useEffect, useState } from "react";
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

let drawerWidth = "20rem !important";
window.innerWidth < 1550 ? drawerWidth="25rem !important" :  drawerWidth="20vw !important"
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px) !important`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px) !important`,
  },
});


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex !important",
  alignItems: "center !important",
  justifyContent: 'flex-end !important',
  padding: theme.spacing(0, 1),  
  ...theme.mixins.toolbar,  
}));

interface Props {
  windows?: () => Window;
  searchConsumerUnit?: boolean;
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function SideMenu(props: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(true); 
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = useState(true);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { setShowSideMenu } = useStateContext();
  const [role, setRole] = useState("");

  useEffect(() => {
    window.innerWidth < 1550 ? setOpen(false): setOpen(true);
    async function getRoleLocalStorage() {
      const getRole = await localStorage.getItem("role");
      if (getRole) setRole(getRole);
    }
    getRoleLocalStorage();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
     window.innerWidth < 1550 ? setOpen(false): setOpen(true);
    
    
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExit = async () => {
    setShowSideMenu(false);
    logout();
    navigate("/");
  };
  
  const container =
    windows !== undefined ? () => windows().document.body : undefined;


  return (
    <Box sx={{ display: "flex", maxWidth: "20vw" }}>
      <CssBaseline />      
      <Drawer         
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "50vw " : "20vw !important",            
            
            backgroundSize: '3600px',
            backgroundAttachment:'fixed',
            backgroundColor: '#D1DCE1',
          },
        }}
        container={container}        
        className={styles.drawerContainer}
        variant="permanent"
        anchor="left"
        open={open}
        // onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        
        <DrawerHeader
          style={{
            display: "flex !important",
            padding: "0rem !important",
            flexDirection: "column",
            justifyContent: "center !important",
            //marginTop: "10px !important",
            
          }}
        >
          <IconButton
          sx={{
            color: "#30313D !important",
            marginTop:"2rem !important",
            marginLeft: "auto",
            marginRight:"auto",
            //marginBottom:"0rem",
            //backgroundColor: "red",
            

            
          }}
          onClick={open? handleDrawerClose:handleDrawerOpen}          
          // color='success !important'
          aria-label="open drawer"          
          edge="start"          
          >
          {window.innerWidth < 1550 ? <MenuIcon /> : null }  
               
          </IconButton>
          
          {open ? <IconButton sx={{
            color: "red !important",
            marginTop:"6rem !important",
            marginLeft: "auto",
            marginRight:"auto",
            //marginBottom:"5rem !important ",
            //backgroundColor: "red",
            
            

            
          }}><img className={styles.imgG} src={Logo} alt="Logo Deep Ambiente" width={200} height={200}  /> </IconButton> : <img className={styles.imgPq} src={Logo} alt="Logo Deep Ambiente" width={70} height={70} />}                 
        </DrawerHeader>
        <Box
          sx={{
            height: "50%",
            //display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            
          }}
        >
          <List sx={{margin:" 0 auto "}}>
            <Link to="/dashboard" onClick={handleDrawerClose}>
              <ListItem 
                button
                sx={{
                  paddingBottom: "8px !important",
                  paddingLeft: "20px !important",
                  paddingRight: "16px !important",
                  width: "100% !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: open ? 'initial !important' : 'center !important',
                  color: "#30313D !important",
                  fontSize: {  md: 132 }
                  
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto !important',
                    justifyContent: 'center !important',
                    
                  }}>
                  <Avatar style={{ color: "#96B63B", background: "#0f85ca" }}>
                    <HomeIcon className={styles.sideIcon}/>
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    span: {
                      opacity: open ? 1 : 0,
                      fontSize: "0.94rem",
                      '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                    },
                  }}
                  primary="Gráficos"
                />
              </ListItem>
            </Link>

            <Link to="/dashboard/myProfile" onClick={handleDrawerClose}>
              <ListItem
                button
                sx={{
                  paddingTop: "8px !important",
                  paddingBottom: "8px !important",
                  paddingLeft: "20px !important",
                  paddingRight: "16px !important",
                  width: "100% !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: open ? 'initial !important' : 'center !important',
                  color: "#30313D !important"
                }}
              >
                <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto !important',
                  justifyContent: 'center !important',
                }}>
                  <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                    <PersonIcon className={styles.sideIcon} />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    opacity: open ? 1 : 0,
                    span: {
                      fontSize: "0.94rem",
                      '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                    },
                  }}
                  primary="Meu Perfil"
                />
              </ListItem>
            </Link>

            <Link to="/dashboard/consumerUnit" onClick={handleDrawerClose}>
              <ListItem
                button
                sx={{
                  paddingTop: "8px !important",
                  paddingBottom: "8px !important",
                  paddingLeft: "20px !important",
                  paddingRight: "16px !important",
                  width: "100% !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: open ? 'initial !important' : 'center !important',
                  color: "#30313D !important"
                }}
              >
                <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto !important',
                  justifyContent: 'center !important',
                }}>
                  <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                    <PowerIcon className={styles.sideIcon} />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    opacity: open ? 1 : 0,
                    span: {
                      fontSize: "0.94rem",
                      '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                    },
                  }}
                  primary="Unidades Consumidoras"
                />
              </ListItem>
            </Link>

            {role === "Admin" ? (
              <Link to="/dashboard/userManagement" onClick={handleDrawerClose}>
                <ListItem
                  button
                  sx={{
                    paddingTop: "8px !important",
                    paddingBottom: "8px !important",
                    paddingLeft: "20px !important",
                    paddingRight: "16px !important",
                    width: "100% !important",
                    display: "flex !important",
                    alignItems: "center !important",
                    justifyContent: open ? 'initial !important' : 'center !important',
                    color: "#30313D !important"
                    
                  }}
                >
                  <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto !important',
                    justifyContent: 'center !important',
                  }}>
                    <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                      <ManageAccountsIcon className={styles.sideIcon} />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      span: {
                        fontSize: "0.94rem",
                        '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                      },
                    }}
                    primary="Gerenciamento de Usuários"
                  />
                </ListItem>
              </Link>
            ) : (
              <div />
            )}

            <Link to="/dashboard/equipmentManagement" onClick={handleDrawerClose}>
              <ListItem
                button
                sx={{
                  paddingTop: "8px !important",
                  paddingBottom: "8px !important",
                  paddingLeft: "20px !important",
                  paddingRight: "16px !important",
                  width: "100% !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: open ? 'initial !important' : 'center !important',
                  color: "#30313D !important"
                }}
              >
                <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto !important',
                  justifyContent: 'center !important',
                }}>
                  <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                    <MiscellaneousServicesIcon className={styles.sideIcon}/>
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    opacity: open ? 1 : 0,
                    span: {
                      fontSize: "0.94rem",
                      '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                    },
                  }}
                  primary="Gerenciamento de Equipamentos"
                />
              </ListItem>
            </Link>

            {role !== "Cliente" ? (
              <Link to="/dashboard/smartMeterManagement" onClick={handleDrawerClose}>
                <ListItem
                  button
                  sx={{
                    paddingTop: "8px !important",
                    paddingBottom: "8px !important",
                    paddingLeft: "20px !important",
                    paddingRight: "16px !important",
                    width: "100% !important",
                    display: "flex !important",
                    alignItems: "center !important",
                    justifyContent: open ? 'initial !important' : 'center !important',
                    color: "#30313D !important"
                  }}
                >
                  <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto !important',
                    justifyContent: 'center !important',
                  }}>
                    <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                      <SettingsIcon className={styles.sideIcon} />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      span: {
                        fontSize: "0.94rem",
                        '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                      },
                    }}
                    primary="Gerenciamento de Medidores"
                  />
                </ListItem>
                
              </Link>
            ) : (
              <div />
            )}
            
            {role === "Admin" ? (
              <Link to="/dashboard/modelManagement" onClick={handleDrawerClose}>
                <ListItem
                  button
                  sx={{
                    paddingTop: "8px !important",
                    paddingBottom: "8px !important",
                    paddingLeft: "20px !important",
                    paddingRight: "16px !important",
                    width: "100% !important",
                    display: "flex !important",
                    alignItems: "center !important",
                    justifyContent: open ? 'initial !important' : 'center !important',
                    color: "#30313D !important"
                  }}
                >
                  <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto !important',
                    justifyContent: 'center !important',
                  }}>
                    <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                      <SettingsIcon className={styles.sideIcon}/>
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      span: {
                        fontSize: "0.94rem",
                        '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                      },
                    }}
                    primary="Gerenciamento de Modelos"
                  />
                </ListItem>
              </Link>
            ) : (
              <div />
            )}
            <a href="http://www.deepambiente.com.br/contact/" target= "_blank" rel="noreferrer" onClick={handleDrawerClose}>
              <ListItem
                button
                sx={{
                  paddingTop: "8px !important",
                  paddingBottom: "8px !important",
                  paddingLeft: "20px !important",
                  paddingRight: "16px !important",
                  width: "100% !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: open ? 'initial !important' : 'center !important',
                  color: "#30313D !important"
                }}
              >
                <ListItemIcon
                 sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto !important',
                  justifyContent: 'center !important',
                }}>
                  <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                    <MapsUgcIcon className={styles.sideIcon}/>
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    opacity: open ? 1 : 0,
                    span: {
                      fontSize: "0.94rem",
                      '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                    },
                  }}
                  primary="Dúvidas e Sugestões"
                />
              </ListItem>              
            </a>
          </List>
          <List sx={{ cursor: "pointer" }} onClick={handleExit}>
            <Divider />
            <ListItem
              button
              sx={{
                paddingLeft: "20px !important",
                paddingRight: "16px !important",
                width: "100% !important",
                display: "flex !important",
                alignItems: "center !important",
                justifyContent: open ? 'initial !important' : 'center !important',
                color: "#30313D !important"
              }}
            >
              <ListItemIcon              
              sx={{
                minWidth: 0,
                mr: open ? 2 : 'auto !important',
                justifyContent: 'center !important',
              }}>
                <Avatar style={{ color: "#0f85ca", background: "#96B63B" }}>
                  <ExitToAppIcon className={styles.sideIcon}/>
                </Avatar>
              </ListItemIcon>
              <ListItemText
              sx={{
                opacity: open ? 1 : 0,
                span: {
                  fontSize: "1rem !important",
                  '@media (min-width:2201px) and (max-width:4000px)': {
                fontSize: '1.45rem !important',
            },
                },
              }} 
              primary="Sair" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}