import React, { createContext, ReactNode, useContext, useState } from "react";
import {
  delConsumerUnit,
  getConsumerUnit,
  getConsumerUnitById,
  getConsumerUnitByUserId,
  postConsumerUnit,
  putConsumerUnit,
  getPowerDistributors,
} from "../services/restApiConsumerUnit";
import {
  ConsumerUnitType,
  PlaceData,
  ConsumerUnitResponse,
  PowerDistributors,
} from "../util/types";
import { useStateContext } from "./stateContext";

type PropsConsumerUnitContext = {
  consumerUnitData: any;
  pickerData: any;
  consumerUnitList: Array<ConsumerUnitResponse>;

  powerDistributorsList: Array<PowerDistributors>;
  setPowerDistributorsList: React.Dispatch<
    React.SetStateAction<Array<PowerDistributors>>
  >;
  getPowerDistributorsList: () => Promise<void>;

  setConsumerUnitData: React.Dispatch<React.SetStateAction<any>>;
  setConsumerUnitList: React.Dispatch<
    React.SetStateAction<Array<ConsumerUnitResponse>>
  >;
  consumerUnitDataResponse: ConsumerUnitType;
  setConsumerUnitDataResponse: React.Dispatch<
    React.SetStateAction<ConsumerUnitType>
  >;
  getConsumerUnitList: (id: string | number, privilege: boolean) => void;
  getConsumerUnitByIdContext: (id: string | number) => void;
  getConsumerUnitByUserIdContext: (id: string | number) => void;
  consumerUnitPostApi: (consumerUnitData: PlaceData) => Promise<boolean>;
  editConsumerUnit: (consumerUnitData: any, id: any) => Promise<boolean>;
  deleteConsumer: (id: string | number) => Promise<Boolean>;
  mountPickerData: (data: any) => void;
};

type ConsumerUnitContextTypes = {
  children: ReactNode;
};

export const ConsumerUnitContext = createContext(
  {} as PropsConsumerUnitContext
);

function ConsumerUnitContextProvider(props: ConsumerUnitContextTypes) {
  const [consumerUnitData, setConsumerUnitData] = useState<ConsumerUnitType>(
    {} as ConsumerUnitType
  );
  const [consumerUnitList, setConsumerUnitList] = useState<
    Array<ConsumerUnitResponse>
  >([] as Array<ConsumerUnitResponse>);
  const [consumerUnitDataResponse, setConsumerUnitDataResponse] =
    useState<ConsumerUnitType>({} as ConsumerUnitType);
  const [pickerData, setPickerData] = useState<any>({} as any);

  const [powerDistributorsList, setPowerDistributorsList] = useState<
    Array<PowerDistributors>
  >([] as Array<PowerDistributors>);

  const { setSnackBarTextResponse, setIsGetFetching } = useStateContext();

  const getConsumerUnitList = async (id: string | number, privilege: boolean) => {
    if (privilege) {
      setConsumerUnitList([]);
      setIsGetFetching(true);
      const { data, status } = await getConsumerUnit();
      if (status === 200) {
        setConsumerUnitList(data);
        setIsGetFetching(false);
      } else {
        setIsGetFetching(false);
        setSnackBarTextResponse(`Error ${status}`);
      }
    } else {
      setConsumerUnitList([]);
      setIsGetFetching(true);
      const { data, status } = await getConsumerUnitByUserId(id);
      if (status === 200) {
        setConsumerUnitList(data);
        setIsGetFetching(false);
      } else {
        setIsGetFetching(false);
        setSnackBarTextResponse(`Error ${status}`);
      }
    }
  };

  const getPowerDistributorsList = async () => {
    const { data, status } = await getPowerDistributors();
    if (status === 200) {
      setPowerDistributorsList(data);
    } else {
      console.log("status erro: ", status);
    }
  };

  const getConsumerUnitByIdContext = async (id: string | number) => {
    setConsumerUnitList([]);
    setIsGetFetching(true);
    const { data, status } = await getConsumerUnitById(id);
    if (status === 200) {
      setConsumerUnitList(data);
      setIsGetFetching(false);
    } else {
      setIsGetFetching(false);
      setSnackBarTextResponse(`Error ${status}`);
    }
  };

  const getConsumerUnitByUserIdContext = async (id: string | number) => {
    setConsumerUnitList([]);
    setIsGetFetching(true);
    const { data, status } = await getConsumerUnitByUserId(id);
    if (status === 200) {
      setConsumerUnitList(data);
      setIsGetFetching(false);
    } else {
      setIsGetFetching(false);
      setSnackBarTextResponse(`Error ${status}`);
    }
  };

  const consumerUnitPostApi = async (consumerUnitData: PlaceData) => {
    let hasPostConsumerUnit = false;
    const role = localStorage.getItem("role");
    const userIdValue = localStorage.getItem("userIdValue");
    let privilege = false
    privilege = role === "Admin" || role === "tecnico"

    try {
      const { status } = await postConsumerUnit(consumerUnitData);
      hasPostConsumerUnit = status === 200;
      if (hasPostConsumerUnit && userIdValue) {
        getConsumerUnitList(userIdValue, privilege);
        setSnackBarTextResponse("Unidade consumidora cadastrada com sucesso!");
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }
    return hasPostConsumerUnit;
  };

  const editConsumerUnit = async (consumerUnitPut: any, id: any) => {
    let hasEditConsumerUnit = false;
    const role = localStorage.getItem("role");
    const userIdValue = localStorage.getItem("userIdValue");
    let privilege = false
    privilege = role === "Admin" || role === "tecnico"

    try {
      const { status } = await putConsumerUnit(consumerUnitPut, id);
      hasEditConsumerUnit = status === 200;
      if (hasEditConsumerUnit && userIdValue) {
        getConsumerUnitList(userIdValue, privilege);
        setSnackBarTextResponse("Unidade consumidora editada com sucesso!");
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }

    return hasEditConsumerUnit;
  };

  const deleteConsumer = async (id: string | number) => {
    let hasDeleted = false;
    const role = localStorage.getItem("role");
    const userIdValue = localStorage.getItem("userIdValue");

    let privilege = false
    privilege = role === "Admin" || role === "tecnico"

    try {
      const { status } = await delConsumerUnit(id);
      hasDeleted = status === 200;
      if (hasDeleted && userIdValue) {
        getConsumerUnitList(userIdValue, privilege);
        setSnackBarTextResponse("Unidade consumidora deletada com sucesso!");
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error;
      setSnackBarTextResponse(errorMessage);
    }

    return hasDeleted;
  };

  const mountPickerData = (data: any) => {
    setPickerData([{}]);
    let auxArray: { value: any; label: any }[] = [];
    data.map((item: { placeName: any; id: any }) =>
      auxArray.push({
        value: item.id,
        label: item.placeName,
      })
    );
    setPickerData(auxArray);
  };

  const objProvider = {
    consumerUnitData,
    consumerUnitList,
    pickerData,
    consumerUnitDataResponse,
    powerDistributorsList,
    setPowerDistributorsList,
    setConsumerUnitList,
    setConsumerUnitData,
    setConsumerUnitDataResponse,
    getConsumerUnitList,
    getConsumerUnitByIdContext,
    getConsumerUnitByUserIdContext,
    getPowerDistributorsList,
    consumerUnitPostApi,
    editConsumerUnit,
    deleteConsumer,
    mountPickerData,
  };

  return (
    <ConsumerUnitContext.Provider value={objProvider}>
      {props.children}
    </ConsumerUnitContext.Provider>
  );
}

function useConsumerUnitContext() {
  const context = useContext(ConsumerUnitContext);
  return context;
}

export { ConsumerUnitContextProvider, useConsumerUnitContext };
