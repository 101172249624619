import { useEffect } from "react";
import React, { useState, useMemo, useRef } from "react";
import styles from "./styles.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
import ReactToPrint from 'react-to-print';

import { LinearProgress } from '@mui/material';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryArea,
  VictoryLegend,
} from "victory";
import { getReportModbusDataAPI, getReportKhompDataAPI } from "../../../services/restApiReport";

export default function DemandaChart({ selectedID, setSelectedID }) {

const [progress, setProgress] = useState(0);

const [chartWidth, setChartWidth] = useState(1200);
const [chartHeight, setChartHeight] = useState(500);
const [chartPadding, setChartPadding] = useState({ bottom: 120, left: 60, right: 50, top: 70 });
const [legendCoordinates, setLegendCoordinates] = useState({ x: 40, y: 540 });



// Function to adjust chart properties based on screen size
function adjustChartProperties() {
  // Check if the window width is less than or equal to 768px
  const isSmallScreen = window.matchMedia("(max-width: 00px)").matches;

  if (isSmallScreen) {
    // Update chart properties for small screens
    setChartWidth(500);
    setChartHeight(700);
    setChartPadding({ bottom: 80, left: 40, right: 30, top: 0 });
    setLegendCoordinates({ x: 20, y: 650 });
  } else {
    // Update chart properties for large screens
    setChartWidth(1200);
    setChartHeight(700);
    setChartPadding({ bottom: 120, left: 60, right: 50, top: 70 });
    setLegendCoordinates({ x: 40, y: 660 });
  }
}

// Call the function when the page loads
useEffect(() => {
  adjustChartProperties();
}, []);

// Add an event listener to call the function when the window is resized
useEffect(() => {
  window.addEventListener("resize", adjustChartProperties);
  // Remove the event listener when the component is unmounted
  return () => window.removeEventListener("resize", adjustChartProperties);
}, []);



let today = new Date();
let startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
let endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);



const [startDate, setStartDate] = useState(startOfDay);
const [endDate, setEndDate] = useState(endOfDay);


let start = new Date(startDate);
  let end = new Date(endDate);
  let dayCount = Math.round((end - start) / (1000 * 60 * 60 * 24));

  const [maxLineValue, setMaxLineValue] = useState(0);

const areaData = useMemo(() => {
  const data = [];
  for(let i=0; i < dayCount; i++) {
    let day = new Date(start);
    day.setDate(start.getDate() + i);

    if(day.getDay() >= 1 && day.getDay() <= 5) {
      day.setHours(18, 30, 0, 0);
      let areaStart = new Date(day.getTime() - (3 * 60 * 60 * 1000)); // Subtract 3 hours
      
      data.push({x: areaStart, y: 0});
      data.push({x: areaStart, y: maxLineValue});

      day.setHours(20, 30, 0, 0);
      let areaEnd = new Date(day.getTime() - (3 * 60 * 60 * 1000)); // Subtract 3 hours

      data.push({x: areaEnd, y: maxLineValue});
      data.push({x: areaEnd, y: 0});
    }
  }
  return data;
}, [dayCount, start, maxLineValue]);

const areaData2 = useMemo(() => {
  const data = [];
  for(let i=0; i < dayCount; i++) {
    let day = new Date(start);
    day.setDate(start.getDate() + i);

    if(day.getDay() >= 1 && day.getDay() <= 5) {
      day.setHours(17, 30, 0, 0);
      let areaStart2 = new Date(day.getTime() - (3 * 60 * 60 * 1000)); // Subtract 3 hours
      
      data.push({x: areaStart2, y: 0});
      data.push({x: areaStart2, y: maxLineValue});

      day.setHours(21, 30, 0, 0);
      let areaEnd2 = new Date(day.getTime() - (3 * 60 * 60 * 1000)); // Subtract 3 hours

      data.push({x: areaEnd2, y: maxLineValue});
      data.push({x: areaEnd2, y: 0});
    }
  }
  return data;
}, [dayCount, start, maxLineValue]);





const generateHoursBetween = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setHours(dt.getHours() + 1);
  }
  return arr;
};


const generateDatesBetween = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;

  
};



function downloadExcelFile() {
  // Combine all data arrays into one
  const allData = [...modbusDataDI1, ...modbusDataDI2, ...modbusDataDI3];
  
  // Generate a worksheet
  const ws = XLSX.utils.json_to_sheet(allData);
  
  // Create a new Workbook
  const wb = XLSX.utils.book_new();
  
  // Append the worksheet to the Workbook
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  
  // Write the Workbook to an Excel file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  
  // Create a blob from the Excel file
  let blob = new Blob([s2ab(wbout)], {type: 'application/octet-stream'});
  
  // Create a link element
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = 'data.xlsx';
  
  // Append the link to the body and simulate a click event to start the download
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// This is needed to convert the string to array buffer
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}


const componentRef = useRef();

 // Modbus data
const [modbusDataDI1, setModbusDataDI1] = useState([]);
const [modbusDataDI2, setModbusDataDI2] = useState([]);
const [modbusDataDI3, setModbusDataDI3] = useState([]);



async function mountedarrayformodbus(variableName) {
    try {
      // Get the date in the server's timezone
      const serverStartDate = new Date(startDate.getTime() - (3 * 60 * 60 * 1000));
      const serverEndDate = new Date(endDate.getTime() - (3 * 60 * 60 * 1000));

      const modbusDataget = await getReportModbusDataAPI(
        "ftteng.ddns.net:502",
        serverStartDate.toISOString(),
        serverEndDate.toISOString(),
        variableName
      );
      
      const chartData = modbusDataget.data.values        
        .map((item) => ({ x: new Date(item.timestamp), y: (item.value * 3 * 50) / 16384 }));

      return chartData;
    } catch (error) {
      console.error("Error fetching modbus data:", error);
      return [];
    }
  }

const [isIdInvalid, setIsIdInvalid] = useState(false); // Add this line

const [isDataInvalid, setIsDataInvalid] = useState(false); // Add this line

 useEffect(() => {
  async function fetchData() {
    setProgress(10);
    
    // Clear the current data
    setModbusDataDI1([]);
    setModbusDataDI2([]);
    setModbusDataDI3([]);
    setIsIdInvalid(false);
    setIsDataInvalid(false);

    if (selectedID === 'ftteng.ddns.net:502') {
      const [DI1Data, DI2Data, DI3Data] = await Promise.all([
        mountedarrayformodbus("DI1"),
        mountedarrayformodbus("DI2"),
        mountedarrayformodbus("DI3")
      ]);

      // Combine all data arrays into one
      const allData = [...DI1Data, ...DI2Data, ...DI3Data];

      // Find the maximum y-value in the data
      let maxValue = allData.reduce((max, item) => Math.max(max, item.y), 0);
      // If the maximum value is more than the current maxLineValue, update it
      if(maxValue > maxLineValue) {
        setMaxLineValue(maxValue);
      }
  
      setModbusDataDI1(DI1Data);
      setModbusDataDI2(DI2Data);
      setModbusDataDI3(DI3Data);
    } else if (selectedID === '847127FFFE1CB050') {
      setIsDataInvalid(true);
      // Do nothing, no data will be displayed
    } else {
      // Handle the case where selectedID is neither "ftteng.ddns.net:502" nor "847127FFFE1CB050"
      console.log(`Unexpected selectedID: ${selectedID}`);
      setIsIdInvalid(true);
    }

    // Add delay
    await new Promise(resolve => setTimeout(resolve, 2000));
    setProgress(60);

    setTimeout(() => {
      setProgress(99);
    }, 1000);

    // Simulate rendering completion
    setTimeout(() => {
      setProgress(100);
    }, 2000);
  }

  fetchData();
}, [startDate, endDate, selectedID]); // Add selectedID to the dependencies



console.log("Progress:", progress);


const yTickValues = useMemo(() => {
    const ticks = [];
    for (let i = 0; i <= maxLineValue; i += 5) {
      ticks.push(parseFloat(i.toFixed(2)));
    }
    return ticks;
  }, [maxLineValue]);


  




 

 




if (progress === 100) {
  return (
    
    <div className={styles.outerContainer}>
      <div className={styles.datePickerContainer}>
        
  <label htmlFor="startDate" className={styles.datePickerLabel}>Data Início:</label>
  <DatePicker
    id="startDate"
    selected={startDate}
    onChange={(date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()))}
    className={styles.datePicker}
  />

  <label htmlFor="endDate" className={styles.datePickerLabel}>Data Final:</label>
  <DatePicker
    id="endDate"
    selected={endDate}
    onChange={(date) => setEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))}
    className={styles.datePicker}
  />

  <button onClick={downloadExcelFile} className={`${styles.datePicker} ${styles.buttonSpacing}`}>Baixar Excel</button>
  <ReactToPrint
          trigger={() => <button className={styles.datePicker}>Exportar relatório</button>}
          content={() => componentRef.current}
          
        />
        <div ref={componentRef} >
      </div>
      
        
</div>
{isIdInvalid && <div className={styles.noId}>Selecione um medidor para renderizar o Gráfico</div>}
{isDataInvalid && <div className={styles.noId}>Sem dados para esse Medidor</div>}
        
      <div className={styles.chartContainer} style={{ zIndex: 1 }}>
        {(
  modbusDataDI1.length > 0 ||
  modbusDataDI2.length > 0 ||
  modbusDataDI3.length > 0) ? (
            <div className={styles.chartWrapper}> {/* Add a wrapper div for the chart */}
            <div ref={componentRef} className={styles.outerContainer}>
           <VictoryChart 
  //containerComponent={<VictoryVoronoiContainer />}
  width={chartWidth}
  height={chartHeight}
  domain={{ y: 0 }}
  padding={chartPadding}
>
  

  <VictoryAxis
  style={{
    axis: { stroke: "grey" },
    ticks: { stroke: "grey" },
    tickLabels: {
      fill: "grey",
      angle: 45, // Add this line to make the labels display at a 45 degree angle
      textAnchor: 'start', // To align the text correctly at 45 degrees
      verticalAnchor: 'middle', // To align the text vertically
    },
    grid: { stroke: "grey", strokeWidth: 0.25 },
  }}
  label=""
  tickValues={
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
      ? generateHoursBetween(new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), new Date(endDate.getTime() - (3 * 60 * 60 * 1000)))
      : generateDatesBetween(new Date(startDate.getTime() - (3 * 60 * 60 * 1000)), new Date(endDate.getTime() - (3 * 60 * 60 * 1000)))
  }
  tickFormat={(tick) => 
  {
    // Create a new date object from the tick, and add 3 hours to it
    let newDate = new Date(tick.getTime() + (3 * 60 * 60 * 1000));

    // Then you can format the newDate object as you wish:
    if (startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear())
    {
       return `${('0' + newDate.getHours()).slice(-2)}:00`; // This will format the tick values as hours
    }
    else 
    {
      return `${newDate.getDate()}-${('0'+(newDate.getMonth()+1)).slice(-2)}-${('0'+newDate.getFullYear()).slice(-2)}`; // This will format the tick values as YYYY-MM-DD
    }
  }
}
/>




 <VictoryAxis
  dependentAxis
  label="W"
  domain={[0, maxLineValue]}
                  tickValues={yTickValues}
  style={{
    axis: { stroke: "grey", zIndex: 2 },
    //ticks: { stroke: "grey", zIndex: 2 },
    tickLabels: { fill: "grey", fontSize: 12 },
    grid: { stroke: "none"},
    axisLabel: { padding: 35, fontSize: 20 } // Adjust the padding to position the label correctly.
  }}
  tickFormat={(t) => `${t} `} // Add the unit "V" to each tick value
/>
  
  <VictoryArea
                    style={{
                      data: { fill: "yellow", opacity: 0.3 },
                    }}
                    data={areaData}
                  />
                  <VictoryArea
                    style={{
                      data: { fill: "red", opacity: 0.3 },
                    }}
                    data={areaData2}
                  />
                  
   <VictoryLine
    //labels={({ datum }) => `DI1: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: "#CD5C5C" },
    }}
    data={modbusDataDI1}
  />
  <VictoryLine
    //labels={({ datum }) => `DI2: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: '#0E85CA' }
    }}
    data={modbusDataDI2}
  />
  <VictoryLine
    //labels={({ datum }) => `DI3: ${datum.y}`}
    //labelComponent={<VictoryTooltip />}
    style={{
      data: { stroke: 'black' }
    }}
    data={modbusDataDI3}
  />


  
  <VictoryLegend
  x={legendCoordinates.x}
  y={legendCoordinates.y} // Adjust these values to position the legend
    centerTitle
    orientation="horizontal"
    gutter={10}
    style={{
      title: { fontSize: 20 }, 
      labels: { fontSize: 20 } 
    }}
          data={[
            { name: "Demanda de Potência Ativa R\u00A0\u00A0\u00A0",symbol: { fill: "#CD5C5C", type: "circle", size: 10 } },
            { name: "Demanda de Potência Ativa S\u00A0\u00A0\u00A0", symbol: { fill: "#0E85CA", type: "circle", size: 10 } },
            { name: "Demanda de Potência Ativa T\u00A0\u00A0\u00A0", symbol: { fill: "black", type: "circle", size: 10 } },
          ]}
        />
      
</VictoryChart>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
} else {
  return (
    <div className={styles.outerContainer}>
      <h2>
        {progress < 40
          ? 'Carregando Dados'
          : progress < 60
          ? 'Gerando Gráfico'
          : progress < 80
          ? 'Quase pronto!'
          :"99%"}
      </h2>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}}