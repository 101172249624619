import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { Pagination } from "@material-ui/lab";

import { ModalEditEquipment } from "../../../Modal/EditEquipment";
import { useModalContext } from "../../../../providers/modalContext";
import styles from "./styles.module.scss";
import CustomButton from "../../../Form/button";
import { ConnectIntrusiveSmartMeter } from "../../../Modal/ConnectIntrusiveSmartMeter";
import { useEquipmentContext } from "../../../../providers/equipmentContext";
import { usePagination } from "../../../../util/hooks/pagination";
import { useStateContext } from "../../../../providers/stateContext";
import { DeleteItemModal } from "../../../Modal/DeleteItem";
import { DesassociatedItemModal } from "../../../Modal/DisconnectMeter";
import { useMeterContext } from "../../../../providers/meterContext";

export function Equipments() {
  const role = localStorage.getItem("role");
  const [equipmentEdit, setEquipmentEdit] = useState();
  const {
    setModalEditEquipment,
    setConnectIntrusiveSmartMeter,
    setModalDesassociated,
    setModalDelete,
  } = useModalContext();
  const { desassociateIntrusiveMeter } = useMeterContext();
  const { deleteEquipment, stateEquipmentsList } = useEquipmentContext();
  const { selectConsumerUnitId, searchItem } = useStateContext();
  const [equipmentId, setEquipmentId] = useState<number>(0);
  const [equipmentName, setEquipmentName] = useState<string>("");
  const [equipmentFilter, setEquipmentFilter] = useState<Array<any>>([] as any);
  let [page, setPage] = useState(1);

  const PER_PAGE = 8;

  useEffect(() => {
    if (equipmentFilter.length <= 0 && stateEquipmentsList.length > 0) {
      setEquipmentFilter(stateEquipmentsList);
    }
  }, [equipmentFilter, stateEquipmentsList]);

  const count = Math.ceil(equipmentFilter.length / PER_PAGE);
  const _DATA = usePagination(equipmentFilter, PER_PAGE);

  useEffect(() => {
    if (searchItem !== "") {
      if (equipmentFilter.length > 0) {
        const filter = equipmentFilter.filter(
          (item: any) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase()) ||
            item.equipmentModel.brand
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase())
        );
        setEquipmentFilter(filter);
      }
    } else {
      setEquipmentFilter(stateEquipmentsList);
    }
  }, [searchItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleDeleteItem = (id: number, name: string) => {
    setEquipmentId(id);
    setEquipmentName(name);
    setModalDelete(true);
  };

  const handleEditEquipment = (equipEdit: any) => {
  console.log(equipEdit); // This will log the equipEdit object in your console
  setEquipmentEdit(equipEdit);
  setModalEditEquipment(true);
};

  const handleAssociateIntrusiveSmartMeter = (id: any) => {
    setEquipmentId(id);
    setConnectIntrusiveSmartMeter(true);
  };

  const handleDesassociateIntrusiveSmartMeter = (id: any, name: string) => {
    setEquipmentId(id);
    setEquipmentName(name);
    setModalDesassociated(true);
  };

  return (
    <main className={styles.mainContainer}>
      <section className={styles.section}>
        {stateEquipmentsList.length > 0 ? (
          <div>
            <div className={styles.itemContainer}>
              <div className={styles.titletable}>
                <div className={styles.contentHeader}>
                  <h5>Nome</h5>
                </div>
                <div className={styles.contentHeader}>
                  <h5>Marca</h5>
                </div>
                <div className={styles.contentHeader}>
                  <h5>Medidor</h5>
                </div>
                <div className={styles.contentHeader}>
                  <h5>Canal</h5>
                </div>
                <div />
              </div>
              {_DATA.currentData().map((equip: any, index: any) => {
                if (equip.consumerUnitId === selectConsumerUnitId) {
                  return (
                    <div key={index}>
                      <div className={styles.mainContent}>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4>{equip.name}</h4>
                          </div>
                        </div>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4 className={styles.brand}>{equip.equipmentModel?.brand || ""}</h4>
                          </div>
                        </div>
                        <div className={styles.contentBox}>
                          <div className={styles.contentText}>
                            <h4 className={styles.equi}>
                              {equip.mac !== null
                                ? equip.mac.name
                                : "Não associado"}
                            </h4>
                          </div>
                        </div>
                        <div className={styles.canais}>
                          <div className={styles.canaisContext}>
                            {equip.channelR ? (
                              <span>
                                <div className={styles.occupation} />
                                {"R "}
                              </span>
                            ) : null}
                            {equip.channelS ? (
                              <span>
                                <div className={styles.occupation} />
                                {"S "}
                              </span>
                            ) : null}
                            {equip.channelT ? (
                              <span>
                                <div className={styles.occupation} />
                                {"T "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className={styles.contentBox3}>
                          <div className={styles.contentItem}>
                            <button onClick={() => handleEditEquipment(equip)}>
                              <EditIcon />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteItem(equip.id, equip.name)
                              }
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                      {
                        role && role !== "Cliente" ?
                          <div className={styles.containerButtons}>
                            {equip.mac ? (
                              <CustomButton
                                type="button"
                                title="Desassociar Setor/Aparelho"
                                className="middleOutLineRed"
                                onClick={() =>
                                  handleDesassociateIntrusiveSmartMeter(
                                    equip.id,
                                    equip.name
                                  )
                                }
                              />
                            ) : (
                              <CustomButton
                                type="button"
                                title="Associar Setor/Aparelho"
                                className="middleOutline"
                                onClick={() =>
                                  handleAssociateIntrusiveSmartMeter(equip.id)
                                }
                              />
                            )}
                          </div>
                          : <div />
                      }
                      <div className={styles.dividerHorizontal} />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mb: "1.25rem !important",
                ul: {
                  display: "flex",
                  flexDirection: "row",
                },
              }}
            >
              {/*<Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />*/}
            </Box>
          </div>
        ) : (
          <div className={styles.noConsumerUnit}>
            <span>
              Não foi encontrado nenhuma equipamento cadastrado, você pode
              cadastrar um novo no botão acima!
            </span>
          </div>
        )}
      </section>
      {equipmentEdit ? (
        <ModalEditEquipment equipmentEdit={equipmentEdit} list={true} />
      ) : (
        <div />
      )}
      <ConnectIntrusiveSmartMeter equipmentId={equipmentId} />
      <DeleteItemModal
        id={equipmentId}
        name={equipmentName}
        functionDelete={() => deleteEquipment(equipmentId)}
      />
      <DesassociatedItemModal
        id={equipmentId}
        title={`Desassociar medidor de ${equipmentName}`}
        functionDesassociated={() => desassociateIntrusiveMeter(equipmentId)}
      />
    </main>
  );
}
