import React, { createContext, ReactNode, useContext, useState } from "react"
import { Last24hours } from "../util/types"

type PropsStateContext = {
  isLoading: boolean,
  isFetching: boolean,
  isGetFetching: boolean,
  confirmLoading: boolean,
  showSideMenu: boolean,
  showSelectAppBar: boolean,
  selectConsumerUnitId: number,
  equipmentConsumerMoch: any,
  searchItem: string,
  openSnackBar: boolean,
  openStatusErrorSnackBar: boolean,
  snackBarTextResponse: string,
  dateToCallApiForChartPie: Last24hours,
  dateToCallApiForChartArea: Last24hours,
  dateToCallApiForChartArea1: Last24hours,
  dateToCallApiForChartArea2: Last24hours,
  dateToCallApiForChartBar: Last24hours,
  dateToCallApiForChartPieRange: Last24hours,
  dateToCallApiForChartAreaRange: Last24hours,

  dateToCallApiForChartBarRange: Last24hours,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>,
  setIsGetFetching: React.Dispatch<React.SetStateAction<boolean>>,
  setConfirmLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>,
  setShowSelectAppBar: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectConsumerUnitId: React.Dispatch<React.SetStateAction<number>>,
  setEquipmentConsumerMoch: React.Dispatch<React.SetStateAction<any>>,
  setSearchItem: React.Dispatch<React.SetStateAction<string>>,
  setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setStatusErrorOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setSnackBarTextResponse: React.Dispatch<React.SetStateAction<string>>
  setDateToCallApiForChartPie: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartArea: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartArea1: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartArea2: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartBar: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartPieRange: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartAreaRange: React.Dispatch<React.SetStateAction<Last24hours>>
  setDateToCallApiForChartBarRange: React.Dispatch<React.SetStateAction<Last24hours>>
}

type StateContextTypes = {
  children: ReactNode
}

export const StateContext = createContext({} as PropsStateContext)

function StateContextProvider(props: StateContextTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isGetFetching, setIsGetFetching] = useState<boolean>(true)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectConsumerUnitId, setSelectConsumerUnitId] = useState<number>(0)
  const [showSideMenu, setShowSideMenu] = useState<boolean>(false)
  const [showSelectAppBar, setShowSelectAppBar] = useState<boolean>(true)
  const [equipmentConsumerMoch, setEquipmentConsumerMoch] = useState<any>()
  const [searchItem, setSearchItem] = useState<string>('')
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [openStatusErrorSnackBar, setStatusErrorOpenSnackBar] = useState<boolean>(false);
  const [snackBarTextResponse, setSnackBarTextResponse] = useState<string>('');
  const [dateToCallApiForChartPie, setDateToCallApiForChartPie] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartArea, setDateToCallApiForChartArea] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartArea1, setDateToCallApiForChartArea1] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartArea2, setDateToCallApiForChartArea2] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartBar, setDateToCallApiForChartBar] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartPieRange, setDateToCallApiForChartPieRange] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartAreaRange, setDateToCallApiForChartAreaRange] = useState<Last24hours>({} as Last24hours);
  const [dateToCallApiForChartBarRange, setDateToCallApiForChartBarRange] = useState<Last24hours>({} as Last24hours);

  const objProvider = {
    isLoading,
    isFetching,
    isGetFetching,
    confirmLoading,
    showSideMenu,
    showSelectAppBar,
    selectConsumerUnitId,
    equipmentConsumerMoch,
    searchItem,
    openSnackBar,
    openStatusErrorSnackBar,
    snackBarTextResponse,
    dateToCallApiForChartPie,
    dateToCallApiForChartArea,
    dateToCallApiForChartArea1,
    dateToCallApiForChartArea2,

    dateToCallApiForChartBar,
    dateToCallApiForChartPieRange,
    dateToCallApiForChartAreaRange,
    
    dateToCallApiForChartBarRange,
    setIsLoading,
    setIsFetching,
    setIsGetFetching,
    setConfirmLoading,
    setShowSideMenu,
    setShowSelectAppBar,
    setSelectConsumerUnitId,
    setEquipmentConsumerMoch,
    setSearchItem,
    setOpenSnackBar,
    setStatusErrorOpenSnackBar,
    setSnackBarTextResponse,
    setDateToCallApiForChartPie,
    setDateToCallApiForChartArea,
    setDateToCallApiForChartArea1,
    setDateToCallApiForChartArea2,
    
    setDateToCallApiForChartBar,
    setDateToCallApiForChartPieRange,
    setDateToCallApiForChartAreaRange,
    
    setDateToCallApiForChartBarRange
  }

  return (
    <StateContext.Provider
      value={objProvider}
    >
      {props.children}
    </StateContext.Provider>
  )
}

function useStateContext() {
  const context = useContext(StateContext)
  return context
}

export {
  StateContextProvider,
  useStateContext
}
