import { PlaceData } from "../util/types";
import api from "./api";

//Consumer unit rest
export function getConsumerUnit() {
  return api.get("/api/ConsumerUnit");
}

export function getPowerDistributors() {
  return api.get(`/api/ConsumerUnit/power-distributors`);
}

export function getConsumerUnitById(id: number | string) {
  return api.get(`/api/ConsumerUnit/${id}`);
}

export function getConsumerUnitByUserId(id: number | string) {
  return api.get(`/api/ConsumerUnit/user/${id}`);
}

export function postConsumerUnit(consumerUnit: PlaceData) {
  return api.post("/api/ConsumerUnit", consumerUnit);
}

export function putConsumerUnit(consumerUnit: any, id: string) {
  return api.put(`/api/ConsumerUnit/${parseInt(id)}`, consumerUnit);
}

export function delConsumerUnit(id: number | string) {
  return api.delete(`/api/ConsumerUnit/${id}`);
}
