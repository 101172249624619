import { useState, useEffect } from "react";   
import { useNavigate } from "react-router-dom";
import { getContract, putSign } from "../../services/restApiContract";
import styles from "./styles.module.scss";
import Logo from '../../assets/image/deep-ambiente-logo.svg'
import { Interweave } from 'interweave';

export default function Contract(){    
    const [sign, setSign] = useState(false);
    const navigate = useNavigate();
    const [userContract, setUserContract] = useState({id:0, userId:"", contract:""});
    
    useEffect(() => {      
        let recoveredUserId = localStorage.getItem("userIdValue");
        let accessToken = localStorage.getItem("accessToken") !==null;
        if(recoveredUserId && accessToken){            
            handleContract(recoveredUserId); 
            return () =>{
                setUserContract(userContract);                
            }                      
        }else{
            navigate("/");
        }
      }, []); // eslint-disable-line

    
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (sign){
            let recoveredUserId = localStorage.getItem("userIdValue");
            if(recoveredUserId){
                await putSign(recoveredUserId);
            };
            await localStorage.removeItem('hasnosign');                            
            navigate('/dashboard');
        }
    } ;
    const handleContract = async (recoveredUserId:string)=>{
        const contractdata = await getContract(recoveredUserId);
        setUserContract(contractdata.data);            
    };
    
    const handleprint = () =>{        
        const openscreen = window.open();
        openscreen?.document.write(userContract.contract);
        openscreen?.window.print();
        openscreen?.window.close();
    }


    return (
        
    <div className={styles.page}>        
        <div className={styles.box}>
            <div className={styles.logo}>
                <img src={Logo} alt="Logo Deep Ambiente" width={140} height={90} />        
            </div>
            <div className={styles.title}>
                <h2>CONTRATO</h2>
            </div>                               
            <Interweave className={styles.contractTex} content={userContract.contract}/>            
            <form className={styles.form} onSubmit={handleSubmit}>               
                <div className={styles.acceptbox}>
                    <input type="checkbox" id="acceptbox" name="acceptbox" onChange={(e)=> setSign(e.target.checked)}/>
                    <label htmlFor="acceptbox">Li e concordo com os termos do contrato.</label>
                </div>
                <div className={styles.accept}>
                    <button type="submit">Aceitar</button>
                </div>                
            </form>
            <form className={styles.formprint} onSubmit={handleprint}>
                <div className={styles.print}>
                    <button type="submit">Imprimir</button>
                </div>
            </form>
        </div>        
    </div>    
    );
}