import api from './api'

export async function  getContract(id: string){
    return api.get(`/api/Contract/${id}`)
  }
  
export async function  postContract(id: string){
    return api.post(`/api/Contract/${id}`)
  }

export async function putSign(id:string) {
    return api.put(`/api/Contract/Sign/${id}`)
}