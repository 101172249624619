import { axiosPrivate } from "../../services/api";
import { useEffect } from "react";
import useRefreshToken from "../../util/hooks/useRefreshToken";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      config => {
        const token = localStorage.getItem("accessToken") || null;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }, error => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    }
  }, [refresh])

  return axiosPrivate;
}

export default useAxiosPrivate;