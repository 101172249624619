import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

import { useFormik } from 'formik';
import * as yup from 'yup';
import Logo from '../../assets/image/deep-ambiente-logo.svg'

import styles from './styles.module.scss'
import { Typography } from '@mui/material';

import { Box } from '@mui/system';
import { useStateContext } from '../../providers/stateContext';
import CustomInput from '../../components/CustomInput';
import CustomButtonLoading from '../../components/Form/buttonLoading';
import CustomButton from '../../components/Form/button';
import { useAuth } from '../../providers/authContext';
import { CustomSnackBar } from '../../components/Snackbar';

export default function ForgotPassword() {
  const navigate = useNavigate()
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext()
  const { forgotPassword, statusResponse, generateConfirmEmail } = useAuth()
  const [loginName, setLoginName] = useState('')

  const validationSchema = yup.object({
    email: yup.string().required('para recuperar sua senha é necessário informar o email'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      setLoginName(values.email)

      const hasForgotPassword = await forgotPassword(values.email)

      if (hasForgotPassword) {
        setStatusErrorOpenSnackBar(false)
        setOpenSnackBar(true)
        setIsLoading(false)
      } else {
        setStatusErrorOpenSnackBar(true)
        setOpenSnackBar(true)
        setIsLoading(false)
      }
    },
  });

  const handleGenerateConfirmEmail = async () => {
    setOpenSnackBar(false)
    let hasConfirmEmail = false
    hasConfirmEmail = await generateConfirmEmail(loginName)
    if (hasConfirmEmail) {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(false)
    } else {
      setOpenSnackBar(true)
      setStatusErrorOpenSnackBar(true)
    }
  }

  return (
    <main className={styles.container}>
      <form onSubmit={formik.handleSubmit} >
        <div className={styles.ContentBox}>
          <div className={styles.header}>
            <div className={styles.contentHeader}>
              <div className={styles.containerImage}>
                <img
                  src={Logo}
                  alt="Logo Deep Ambiente"
                  width={140}
                  height={90}
                />
              </div>
              <h3 className={styles.titleHeader}>Redefinir senha</h3>
              <div />
            </div>
            <div className={styles.dividerHorizontal} />
          </div>
          <div className={styles.Content}>
            <div className={styles.containerTitle}>
              <h3 className={styles.title}>Esqueceu a Senha?</h3>
              <p className={styles.infoText}>Enviaremos um e-mail para você com as instruções para redefinir sua senha.</p>
            </div>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              mb: '1rem'
            }}>
              <Typography component={'span'} variant={'body2'} sx={{ mb: '1rem' }}>E-mail para confirmação</Typography>
              <CustomInput
                size="small"
                variant="outlined"
                id="email"
                name="email"
                label="E-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
          </div>
          <div className={styles.dividerHorizontal} />
          <div className={styles.positionButton}>
            <CustomButton title="voltar para login" className="middleOutline" onClick={() => navigate('/')} />
            <CustomButtonLoading type="submit" title="Redefinir" className="middle" />
          </div>
        </div>
      </form>
      <CustomSnackBar action={
        statusResponse === 400 ?
          <>
            <br />
            <span onClick={() => handleGenerateConfirmEmail()}>Deseja reenviar email de confirmação?</span>
          </>
          : null
      } />
    </main>
  )
}
